import { serverRoutes } from "client-server-shared/config/routes";
import { useUser } from "hooks/use-user";
import { NextLink } from "components/next-link";
import { Button, IconButton, IconButtonProps } from "components/ui/button";
import Link from "next/link";
import { InlineSpan } from "components/as-inline-span";
import { Avatar } from "components/ui/avatar";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { colors } from "src/constants";

export type PrimaryCallToActionProps = Exclude<
  CallToActionProps,
  "asBlank" | "variant"
>;

export const HighlightedCallToAction = ({
  children,
  rootProps = {},
  route,
}: {
  route?: string;
  children: React.ReactNode;
  rootProps?: PrimaryCallToActionProps;
}) => {
  return (
    <PrimaryCallToAction
      to={serverRoutes.register}
      route={route}
      {...rootProps}
      sx={{
        maxWidth: "initial",
        minWidth: "initial",
        fontSize: "16px",
        boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
        background: colors.red1,
        border: 0,
        columnGap: "12px",
        fontWeight: 500,
        "&:hover": {
          background: colors.red1,
        },
        ...(rootProps.sx || {}),
      }}
    >
      {children}
    </PrimaryCallToAction>
  );
};

export const PrimaryCallToAction = ({
  children,
  to,
  route,
  sx = {},
  ...rest
}: Exclude<CallToActionProps, "asBlank" | "variant">) => {
  const user = useUser();
  return (
    <CallToAction
      to={route ? route : user ? to : serverRoutes.register}
      sx={sx}
      {...rest}
    >
      {children}
    </CallToAction>
  );
};

export const SolidCallToAction = ({
  children,
  to,
  sx = {},
  onClick = () => {},
}: Exclude<CallToActionProps, "asBlank" | "variant">) => {
  return (
    <Button
      variant="outlined"
      component={Link}
      href={to}
      onClick={onClick}
      capitalize
      sx={{
        color: "text.white",
        background: "#5B2D75",
        padding: "12px 24px",
        borderRadius: "22px",
        whiteSpace: "nowrap",
        border: 0,
        "&:hover": {
          background: "#5B2D75",
          border: 0,
          opacity: 0.8,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export type CallToActionProps = {
  children: React.ReactNode;
  variant: "primary" | "secondary";
  to: string;
  asBlank?: boolean;
  onClick?: () => void;
} & IconButtonProps;

export const CallToAction = ({
  children,
  variant = "primary",
  to,
  sx = {},
  asBlank = false,
  onClick = () => {},
  rel,
  ...rest
}: CallToActionProps) => {
  const props = {};
  if (asBlank) {
    props["target"] = "__blank";
  }
  const bg = colors.red1;
  return (
    <IconButton
      component={NextLink}
      href={to}
      rel={rel}
      sx={{
        display: "flex",
        color: "text.white",
        margin: "48px auto 124px auto",
        borderRadius: "50px",
        maxWidth: "300px",
        width: "100%",
        fontSize: "16px",
        background: bg,
        "&:hover": {
          background: bg,
          opacity: 0.8,
        },
        ...sx,
      }}
      {...props}
      {...rest}
    >
      {children}
    </IconButton>
  );
};
