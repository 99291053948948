import { lang } from "client-server-shared/constants";
import { PostConfig } from "client-server-shared/types/types";

export const addDefaultPostConfig = <T extends PostConfig = PostConfig>(
  postConfig: T
): T => {
  const config = postConfig || {};
  const { wordsCount, variationsCount, constraintCategories, categoriesList } =
    config;
  return {
    ...config,
    language: config.language || lang,
    categoriesList: Array.isArray(categoriesList) ? categoriesList : [],
    wordsCount: typeof wordsCount !== "number" ? 1000 : wordsCount,
    variationsCount: typeof variationsCount !== "number" ? 1 : variationsCount,
    constraintCategories:
      typeof constraintCategories === "undefined"
        ? false
        : constraintCategories,
    keywords: Array.isArray(config.keywords) ? config.keywords : [],
  };
};
