export const getH1 = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const h1Elements = doc.querySelectorAll("h1");

  for (const el of h1Elements) {
    if (el.textContent && el.textContent.trim().length > 0) {
      return el.textContent;
    }
  }

  return "";
};

export const removeH1 = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const el = doc.querySelector("h1");

  if (el) {
    el.remove();
  }
  return doc.body.innerHTML;
};

export const getAllLinksWithAncharText = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const links = [...doc.querySelectorAll("a")];
  const linksWithAncharText = links
    .map((link) => {
      const href = link.getAttribute("href");
      const text = link.textContent;
      if (!href || !text) {
        return null;
      }
      if (href.includes("/storage/v1")) {
        return null;
      }
      return { link: href, text };
    })
    .filter(Boolean);
  return linksWithAncharText as { link: string; text: string }[];
};

export const getStats = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const h1 = doc.querySelectorAll("h1");
  const h2 = doc.querySelectorAll("h2");
  const h3 = doc.querySelectorAll("h3");

  const headings = h1.length + h2.length + h3.length;
  const images = doc.querySelectorAll("img").length;

  return {
    headings,
    images,
  };
};
