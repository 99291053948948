import React from "react";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";
import { useCloseSelectedPopup, useOpenPopup } from "context/popup-context";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { PurplePinkButton } from "components/purple-button";

const NoBillingRightModal = () => {
  const onClosePopup = useCloseSelectedPopup();
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: "12px 0",
        },
      }}
      open
      onClose={onClosePopup}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "24 24px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            marginRight: "24px",
          }}
        >
          Action Required: Contact Team Owner
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px",
            marginBottom: "12px",
          }}
        >
          You do not have the necessary permissions to make changes related to
          billing or to purchase credits. Please contact your team owner to
          perform this action.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "12px",
            marginTop: "24px",
          }}
        >
          <OutlineGreyButton
            sx={{}}
            onClick={() => {
              onClosePopup();
            }}
          >
            Ok
          </OutlineGreyButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NoBillingRightModal;
