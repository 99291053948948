// import remarkHtml from "remark-html";
/*
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
*/

let rehypeParsePromise: any;
let rehypeRemarkPromise: any;
let remarkStringifyPromise: any;
let remarkPromise: Awaited<typeof import("remark")> | null = null;
let remarkGFMPromise: Awaited<typeof import("remark-gfm")> | null = null;
const getRehypeParse = async () => {
  if (!rehypeParsePromise) {
    rehypeParsePromise = await import("rehype-parse").then(
      (module) => module.default
    );
  }
  return rehypeParsePromise;
};
const getRehypeRemark = async () => {
  if (!rehypeRemarkPromise) {
    rehypeRemarkPromise = await import("rehype-remark").then(
      (module) => module.default
    );
  }
  return rehypeRemarkPromise;
};
const getRehypeStringify = async () => {
  if (!remarkStringifyPromise) {
    remarkStringifyPromise = await import("remark-stringify").then(
      (module) => module.default
    );
  }
  return remarkStringifyPromise;
};

const getRemark = async () => {
  if (!remarkPromise) {
    remarkPromise = await import("remark").then((module) => module.remark);
  }
  return remarkPromise;
};

const getRemarkGFM = async () => {
  if (!remarkGFMPromise) {
    remarkGFMPromise = await import("remark-gfm").then(
      (module) => module.default
    );
  }
  return remarkGFMPromise;
};

const loadAll = async () => {
  return await Promise.all([
    getRehypeParse(),
    getRehypeRemark(),
    getRehypeStringify(),
    getRemark(),
    getRemarkGFM(),
  ]);
};

function iframeToVideo() {
  return (tree) => {
    function transformNode(node, parent) {
      if (node.type === "element" && node.tagName === "iframe") {
        const index = parent.children.indexOf(node);
        const src = node.properties.src;

        parent.children[index] = {
          type: "element",
          tagName: "video",
          properties: {
            controls: true,
            src,
          },
          children: [],
          position: node.position,
        };
      } else if (node.children) {
        node.children.forEach((child) => transformNode(child, node));
      }
    }

    transformNode(tree, null);
  };
}

function preserveVideo() {
  return (tree) => {
    function transformNode(node, parent) {
      if (node.type === "element" && node.tagName === "video") {
        const index = parent.children.indexOf(node);
        console.log("node", index, node.type, node.tagName, node);

        parent.children[index] = {
          type: "html",
          tagName: "video",
          value: `<video controls src="${node.properties.src}"></video>`,
          position: node.position,
        };
      } else if (node.children) {
        node.children.forEach((child) => transformNode(child, node));
      }
    }

    transformNode(tree, null);
  };
}

export async function htmlToMarkdown(htmlText: string) {
  const [rehypeParse, rehypeRemark, remarkStringify, remark, remarkGfm] =
    await loadAll();
  const file = remark()
    // .use(iframeToVideo)
    .use(rehypeParse)
    .use(rehypeRemark)
    //   .use(preserveVideo)
    .use(remarkGfm)
    .use(remarkStringify)
    .processSync(htmlText);
  return String(file);
}
