import React from "react";

import { wordpressAuthSucceeded } from "reducers/user";
import protectedClientApi from "utils/protected-client-api";
import { useAppDispatch } from "./use-app-dispatch";
import { useUser } from "./use-user";
import { wordPressRedirectUri } from "client-server-shared/integration/wordpress";
import { useNotifications } from "./use-notification";
import { useSelector } from "./use-selector";
import { selectUserHasWordPressIntegrated } from "selectors/user";
import { useLoading } from "./use-loading";
import { useMounted } from "./use-mounted";
import { useEditPostPropertiesById } from "./use-edit-post-properties";
import { MetaDataField } from "client-server-shared/config/fieldMapping";
import { useOpenPopup } from "context/popup-context";
import { useLoadIntegrations } from "./use-deferrable-data";
import { selectPostByCollectionIdAndPostId } from "selectors/collections";
import { openLinkInNewTab } from "utils/open-new-tab";
import { getRequestErrorObject } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";
import { SchedulePublishConfig } from "client-server-shared/types/workflow";
import { useRefreshCollection } from "./use-load-collection";

const buildOAuthUrl = () => {
  const redirectUri = encodeURIComponent(wordPressRedirectUri);

  return `https://public-api.wordpress.com/oauth2/authorize?client_id=${process.env.NEXT_PUBLIC_WORDPRESS_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code`;
};

function popupCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers       Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export const useStartWordpressOAuth = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const { getIntegrations } = useLoadIntegrations();
  const resolveRef = React.useRef<(status: boolean) => void>(null);
  const onListenToMessage = React.useCallback(
    async (event: MessageEvent<any>) => {
      const success = event.data.success;
      if (typeof success !== "undefined") {
        await onReceiveMessage(success);
        window.removeEventListener("message", onListenToMessage);
        if (resolveRef.current) {
          resolveRef.current(!!success);
        }
      }
    },
    []
  );

  const onReceiveMessage = React.useCallback(async (success: boolean) => {
    if (!success || !user?.id) {
      addFailureNotification("Failed to connect to Wordpress.");
    } else {
      await getIntegrations();
      addSuccessNotification("Successfully connected to Wordpress!");
    }
  }, []);

  const onInitAuthorizedProcess = React.useCallback(
    (e?: React.MouseEvent): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        const authUrl = buildOAuthUrl();

        e?.preventDefault();

        const width = 800;
        const height = 600;
        popupCenter(authUrl, "WordPress Authorization", width, height);
        resolveRef.current = resolve;

        window.addEventListener("message", onListenToMessage);
      });
    },
    [dispatch]
  );

  return { onInitAuthorizedProcess };
};

export const useConnectToWordPressIfNeeded = () => {
  const hasWordpressIntegrated = useSelector(selectUserHasWordPressIntegrated);
  const { onInitAuthorizedProcess } = useStartWordpressOAuth();

  const onConnect = React.useCallback(async () => {
    return await onInitAuthorizedProcess();
  }, [onInitAuthorizedProcess]);
  return {
    onConnect,
    hasWordpressIntegrated,
  };
};

export type OnImportedParams = {
  content?: string;
  title?: string;
  slug?: string;
  id?: string;
};

export const useImportFromWordPress = ({
  onImported,
}: {
  onImported: (params: OnImportedParams) => void;
}) => {
  const hasWordpressIntegrated = useSelector(selectUserHasWordPressIntegrated);
  const { isLoading, onLoading, onIdle } = useLoading();
  const { safeRunAsync, safeRun } = useMounted();

  const { addFailureNotification, addSuccessNotification } = useNotifications();
  const onImportFromWordPress = React.useCallback(
    async (slugOrId, integrationId) => {
      if (isLoading) {
        return;
      }

      await safeRunAsync(async () => {
        onLoading();
        try {
          const data = await protectedClientApi.importFromWordPress(
            slugOrId,
            integrationId
          );
          safeRun(() => {
            onImported({
              content: data.content || "",
              title: data.title,
              slug: data.slug,
              id: data.id,
            });

            onIdle();
          });
        } catch (e) {
          addFailureNotification(
            "There's an error importing from WordPress. Please check if the id is correct and the Wordpress connection is still valid."
          );
          onIdle();
        }
      });
    },
    [hasWordpressIntegrated, isLoading]
  );
  return {
    isLoading,
    onImportFromWordPress,
  };
};

export const usePublishToWordpress = ({ collectionId, postId }) => {
  const { isLoading, onLoading, onIdle } = useLoading();
  const { safeRunAsync, safeRun } = useMounted();
  const { addFailureNotification, addSuccessNotification } = useNotifications();
  const { hasWordpressIntegrated, onConnect } = useConnectToWordPressIfNeeded();
  const openPopup = useOpenPopup();
  const { onPostMetadataChanged } = useEditPostPropertiesById(
    collectionId,
    postId
  );
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const { onRefreshCollection } = useRefreshCollection();
  const existingPostId = post?.postMetadata?.wordpress_id;

  const openPublishWordPressModal = React.useCallback(
    async ({ postId }: { postId: string }) => {
      if (!hasWordpressIntegrated) {
        const success = await onConnect();
        if (!success) {
          return;
        }
      }
      openPopup("wordpressPublishModal", {
        postId,
        collectionId,
      });
    },
    [hasWordpressIntegrated, isLoading]
  );

  const onPublishToWordPress = React.useCallback(
    async ({
      postId,
      onSuccess,
      integrationId,
      status,
      author,
      categories,
      tags,
      authorName,
      schedulePublishConfig,
    }: {
      authorName?: string;
      tags?: string[];
      integrationId: string;
      postId: string;
      author?: string;
      status: string;
      categories?: string[];
      onSuccess?: () => void;
      schedulePublishConfig?: SchedulePublishConfig;
    }) => {
      if (isLoading) {
        return;
      }
      if (!hasWordpressIntegrated) {
        const success = await onConnect();
        if (!success) {
          return;
        }
      }

      await safeRunAsync(async () => {
        onLoading();
        try {
          const res = await protectedClientApi.publishToWordPress({
            postId,
            status,
            integrationId,
            existingPostId,
            author,
            categories,
            tags,
            schedulePublishConfig,
          });
          safeRun(() => {
            if (!schedulePublishConfig?.publishedDate) {
              const { url, id } = res;
              addSuccessNotification("Successfully published to WordPress!");
              openLinkInNewTab(url);
              onPostMetadataChanged("wordpress_id")(id);
            } else {
              addSuccessNotification(
                "Successfully scheduled to publish to WordPress!"
              );
            }
            onIdle();

            if (onSuccess) {
              onSuccess();
            }
          });
          await onRefreshCollection(collectionId);
        } catch (e) {
          const errorJSON = getRequestErrorObject(e);

          let message = `There's an error publishing to WordPress. Please check if your WordPress connection is still valid and try again.`;

          if (errorJSON?.code === ErrorCode.InvalidWordpressPermissionsError) {
            message = `Your WordPress account does not have the necessary permissions to publish articles. Please check your WordPress account and try again.`;

            if (authorName) {
              message = `Your do not have permissions to publish articles as ${authorName}. Please check your WordPress account or choose a different author or leave the author field blank and try again.`;
            }
          }

          addFailureNotification(message);
          onIdle();
        }
      });
    },
    [hasWordpressIntegrated, collectionId, isLoading, existingPostId]
  );
  return {
    isLoading,
    openPublishWordPressModal,
    onPublishToWordPress,
    hasWordpressIntegrated,
  };
};
