export const inMobileApp = () =>
  typeof window !== "undefined" && (window as any).inMobileApp === true;

export const getMobileActions = () => {
  if (typeof window === "undefined") {
    return null;
  }
  const effectiveWindow = window as any;
  return {
    onMobileLogout: effectiveWindow.onMobileLogout,
    onMobileSessionExpired: effectiveWindow.onMobileSessionExpired,
    openBrowserUrl: effectiveWindow.openBrowserUrl,
  };
};
