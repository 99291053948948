import type { ContentFields, Entry } from "contentful-management";
import {
  GeneratorField,
  GeneratorFields,
} from "client-server-shared/types/fields-mapping";

export const supportedContentfulLocale = "en-US";

export enum MetaDataField {
  MetaTitle = "metaTitle",
  MetaDescription = "metaDescription",
  Date = "date",
  Category = "categories",
  Tags = "tags",
  Slug = "slug",
}

export type FieldMappings = Record<string, any>;

export const validateFieldMappingSelection = (
  generatorField: GeneratorField,
  selectedField?: ContentFields
): { valid: boolean; text?: string } => {
  if (!selectedField) {
    return { valid: true };
  }
  if (
    generatorField.asArray &&
    (generatorField.type === "Symbol" || generatorField.type === "Text")
  ) {
    if (
      selectedField.items?.type === "Symbol" ||
      selectedField.items?.type === "Text"
    ) {
      return { valid: true };
    }

    if (
      generatorField.name === "categories" &&
      (selectedField.type === "Symbol" || selectedField.type === "Text")
    ) {
      return {
        valid: true,
      };
    }
  }
  if (
    (generatorField.type === "Symbol" || generatorField.type === "Text") &&
    selectedField.type !== "Symbol" &&
    selectedField.type !== "Text"
  ) {
    return {
      valid: false,
      text: `Expect ${generatorField.id} to be type of Text`,
    };
  }
  if (generatorField.type === "Image" && selectedField.linkType !== "Asset") {
    return {
      valid: false,
      text: `Expect ${generatorField.id} to be type of Image`,
    };
  }
  if (selectedField.type === "Link" && selectedField.linkType !== "Asset") {
    return {
      valid: false,
      text: `Current only image references are supported`,
    };
  }
  return { valid: true };
};

export const preSelectFieldMapping = (
  generatorFields: GeneratorFields,
  contentfulFields: EnhancedContentField[]
) => {
  const filedMapping: FieldMappings = {};
  generatorFields.forEach((generatorField) => {
    let found = contentfulFields.find(
      (field) => field.id.toLowerCase() === generatorField.id.toLowerCase()
    );

    if (!found) {
      found = contentfulFields.find(
        (field) =>
          field.id.toLowerCase().includes(generatorField.id.toLowerCase()) ||
          generatorField.id.toLowerCase().includes(field.id.toLowerCase())
      );
    }

    if (found) {
      const validation = validateFieldMappingSelection(generatorField, found);
      if (validation.valid) {
        filedMapping[generatorField.id] = found;
      }
    }
  });
  return filedMapping;
};

export const mapContentfulFields = (
  item: Entry,
  fieldMapping: FieldMappings
) => {
  const result = {
    title:
      item.fields[fieldMapping.title?.id]?.[supportedContentfulLocale] ?? null,
    content:
      item.fields[fieldMapping.content?.id]?.[supportedContentfulLocale] ??
      null,
    category:
      item.fields[fieldMapping.category?.id]?.[supportedContentfulLocale] ??
      null,
    metaTitle:
      item.fields[fieldMapping.metaTitle?.id]?.[supportedContentfulLocale] ??
      null,
    metaDescription:
      item.fields[fieldMapping.metaDescription?.id]?.[
        supportedContentfulLocale
      ] ?? null,
    slug:
      item.fields[fieldMapping.slug?.id]?.[supportedContentfulLocale] ?? null,
    tags: item.fields[fieldMapping.tags?.id]?.[supportedContentfulLocale] ?? [],
    featureImage:
      item.fields[fieldMapping.featureImage?.id]?.[supportedContentfulLocale] ??
      null,
    featureImageAlt:
      item.fields[fieldMapping.featureImageAlt?.id]?.[
        supportedContentfulLocale
      ] ?? null,
    platform: "contentful",
    contentfulId: item.sys.id,
    contentfulData: item,
  };
  return result;
};
