import { isCn } from "client-server-shared/constants";

let userId = null;

export const setUserId = (id) => {
  userId = id;
};

let uploadingImageTexts = {
  title: "Uploading images...",
  message: "We are uploading your images, please wait a moment",
};

if (isCn()) {
  uploadingImageTexts = {
    title: "正在上传图片...",
    message: "正在上传您的图片，请稍候",
  };
}

export { userId, uploadingImageTexts };
