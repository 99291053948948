import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";
import { Typography } from "../ui/Typography";
import { Box } from "../ui/box";
import { OutlineGreyButton } from "../ui/button/button-with-loading";

const RatelimitModal = ({
  onClosePopup,
  title,
  description,
  buttonText,
}: {
  onClosePopup: () => void;
  title: string;
  description: string;
  buttonText: string;
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: "12px 0",
        },
      }}
      open
      onClose={onClosePopup}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "24 24px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            marginRight: "24px",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px",
            marginBottom: "12px",
          }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "12px",
            marginTop: "24px",
          }}
        >
          <OutlineGreyButton
            onClick={() => {
              onClosePopup();
            }}
          >
            {buttonText}
          </OutlineGreyButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RatelimitModal;
