import { isLoading, isSuccess, processingLogUtils } from "hooks/use-loading";
import { notificationUtils } from "hooks/use-notification";
import { foldersAdded } from "reducers/folders";
import { folderDeleted } from "reducers/folders/action";
import { LoadingStatus } from "reducers/processing-log/types";
import { AppDispatch, AppThunk, RootState } from "reducers/types";
import { selectFolderById } from "selectors/folders";
import { selectProcessingLogById } from "selectors/processing-log";
import { captureException } from "utils/error-catching/lazy-sentry";
import protectedClientApi from "utils/protected-client-api";

export const onCreateFolderThunk = (
  name: string,
  {
    onFailure,
    onSuccess,
  }: {
    onFailure: () => void;
    onSuccess: () => void;
  }
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const { addFailureNotification } = notificationUtils(dispatch);
    try {
      const created = await protectedClientApi.onCreateFolder(name);
      dispatch(
        foldersAdded([
          {
            ...created,
            status: LoadingStatus.Success,
          },
        ])
      );
      onSuccess();
    } catch (e) {
      addFailureNotification(
        `There's an error while saving folder. Please double check your internet connection and try again.`
      );
      onFailure();
    }
  };
};

export const getFoldersLogId = "GET_FOLDERS";

export const onGetFolderById = (id: string): AppThunk => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const folder = selectFolderById(getState(), id);
    const { addLog, onSuccess, onError } = processingLogUtils(id)(dispatch);
    try {
      addLog();
      const newFolder = await protectedClientApi.getFolderById(id);
      dispatch(
        foldersAdded([
          {
            ...newFolder,
            status: folder?.status ?? LoadingStatus.Idle,
          },
        ])
      );
      onSuccess();
    } catch (e) {
      captureException(e);
      onError();
    }
  };
};

export const onDeleteFolderThunk = (id: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const { addSuccessNotification } = notificationUtils(dispatch);
    try {
      addSuccessNotification(`Folder deleted`);
      dispatch(folderDeleted(id));
      await protectedClientApi.onDeleteFolder(id);
    } catch (e) {
      captureException(e);
    }
  };
};
