import { IconButton } from "components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import { useTranslation } from "components/translate";
import { useCloseSelectedPopup } from "context/popup-context";
import { Box } from "./ui/box";
import {
  IntervalSwitcher,
  MonthlyPlan,
  Pricing,
  PricingContext,
  PricingProvider,
  YearlyPlan,
} from "modules/payment/pricing";
import { useUser } from "hooks/use-user";
import { Interval } from "client-server-shared/pricing/v2";
import { trackTogglePlanType } from "utils/tracking/payment";
import { SourceType } from "utils/tracking/type";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import { PaidTrailBanner } from "modules/marketing-pages/popup-banner";
import PricingTable from "modules/payment/pricing-table";

const UpgradePopup = () => {
  const onClosePopup = useCloseSelectedPopup();
  const { t } = useTranslation("pricing");

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          background: "#F4F8FB",
          ...(matches
            ? {
                maxHeight: "initial",
                height: "100%",
                width: "100%",
                margin: 0,
                maxWidth: "initial",
                borderRadius: 0,
              }
            : {}),
        },
      }}
      fullWidth
      maxWidth="md"
      open
      onClose={onClosePopup}
    >
      <DialogTitle
        sx={{
          padding: "12px 24px 12px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label={t("close")}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <PricingProvider page={SourceType.UpgradePopup}>
          {userCanHavePaidTrial && <PaidTrailBanner />}
          <IntervalSwitcher
            hideHeading={true}
            page={SourceType.UpgradePopup}
            sx={{
              margin: userCanHavePaidTrial ? "48px 0" : "24px 0",
              marginTop: userCanHavePaidTrial ? "48px" : "24px 0",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              rowGap: "24px",
              columnGap: "24px",
              paddingBottom: "24px",
              padding: {
                xs: "0 30px 24px 0",
                sm: "0 30px 24px 0",
                md: "0 30px",
                xl: "0 0 24px 0",
                lg: "0 0 24px 0",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                xl: "row",
                lg: "row",
              },
            }}
          >
            <>
              <MonthlyPlan
                sx={{
                  flexBasis: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                }}
                page={SourceType.UpgradePopup}
              />
              <YearlyPlan
                sx={{
                  flexBasis: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                }}
                page={SourceType.UpgradePopup}
              />
            </>
          </Box>
        </PricingProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePopup;
