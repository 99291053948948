import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useLoading } from "hooks/use-loading";

import { PurplePinkButton } from "components/purple-button";
import protectedClientApi from "utils/protected-client-api";
import { IntegrationType } from "client-server-shared/constants";
import { integrationAdded, integrationModified } from "reducers/user";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useNotifications } from "hooks/use-notification";
import OrText from "modules/selections/or-text";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import {
  HighlightedSpan,
  InlineLinkSpan,
  InlineSpan,
} from "components/as-inline-span";
import { useConnectToWordPressIfNeeded } from "hooks/usewordpress";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { useSelector } from "hooks/use-selector";
import { selectUserInActiveProPlan } from "selectors/user";
import { LimitReachModalPlain } from "components/limit-reached-modal/limit-reached-modal";

const SelfHostWordPressConnectModal = ({
  onClose,
  afterConnect,
  ...rest
}: {
  afterConnect?: () => void;
  onClose: () => void;
  url?: string;
  username?: string;
  password?: string;
  id?: string;
}) => {
  const [config, setConfig] = React.useState({
    url: rest?.url || "",
    username: rest?.username || "",
    password: rest?.password || "",
  });
  const dispatch = useAppDispatch();
  const loading = useLoading();
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const wordpressConnection = useConnectToWordPressIfNeeded();

  const onConnect = async () => {
    if (loading.isLoading) {
      return;
    }
    loading.onLoading();
    try {
      const cleanUrl = config.url.trim().endsWith(`/`)
        ? config.url.trim().substring(0, config.url.trim().length - 1)
        : config.url.trim();
      const toAPIFormat = {
        name: cleanUrl,
        wordpress_blog_url: cleanUrl,
        wordpress_username: config.username.trim(),
        wordpress_password: config.password.trim(),
        type: IntegrationType.WordPress,
      };
      if (rest.id) {
        const updated = await protectedClientApi.updateIntegration(
          rest.id,
          toAPIFormat
        );
        dispatch(integrationModified(updated));
      } else {
        const created = await protectedClientApi.saveIntegration(toAPIFormat);
        dispatch(integrationAdded(created));
      }
      loading.onIdle();
      addSuccessNotification("Connected to WordPress successfully");
      onClose();
      if (afterConnect) {
        afterConnect();
      }
    } catch (e) {
      addFailureNotification(
        `Failed to connect to WordPress. Please double-check your credentials and try again.`
      );
      loading.onIdle();
    }
  };
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.WordPressIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();

  let isWordPressUrlPotentiallyInValid = false;

  let isWordPressUsernamePotentiallyInValid = false;

  if (config.url) {
    if (
      !config.url.startsWith("http://") &&
      !config.url.startsWith("https://")
    ) {
      isWordPressUrlPotentiallyInValid = true;
    }

    try {
      if (new URL(config.url).pathname !== "/") {
        isWordPressUrlPotentiallyInValid = true;
      }
    } catch (e) {}
  }

  if (config.username) {
    if (config.username.includes(` `)) {
      isWordPressUsernamePotentiallyInValid = true;
    }
  }

  let isWordPressPasswordPotentiallyInValid = false;

  if (config.password) {
    const matches = config.password.match(/ /g);
    if (!Array.isArray(matches) || matches.length < 5) {
      isWordPressPasswordPotentiallyInValid = true;
    }
  }

  const userInProPlan = useSelector(selectUserInActiveProPlan);
  if (!userInProPlan) {
    return (
      <LimitReachModalPlain
        onClosePopup={onClose}
        title="Upgrade To Unlock"
        description1="You need to be on a Premium plan to use this feature."
        description2="Ready to unlock more features and benefits with our paid plans?"
        actionText="Upgrade"
      />
    );
  }

  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              Connect To WordPress
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose();
            }}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "6px",
          }}
        >
          <Description
            sx={{
              marginTop: "6px",
              maxWidth: "400px",
            }}
          >
            Learn where to find your WordPress Application Password{" "}
            <InlineLinkSpan
              inherit
              href="https://writingtools.ai/docs/wordpress-integration"
            >
              here
            </InlineLinkSpan>
            .
          </Description>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: "36px",
        }}
      >
        <Section>
          <SectionTitle
            description={`The root path of your WordPress URL, it should not contain any trailing slashes. Make sure to include "https://", "http://" or www if necessary.`}
          >
            Your WordPress URL
          </SectionTitle>
          <PrimaryInput
            fullWidth
            size="small"
            error={isWordPressUrlPotentiallyInValid}
            helperText={
              isWordPressUrlPotentiallyInValid
                ? `Your WordPress URL is potentially not correct. Did you forget to include "https://", "http://", or "www" in your URL? In most cases, it should normally be the root URL unless your WordPress is hosted at a sub-page.`
                : ""
            }
            placeholder="Eg: https://www.junia.ai or https://www.junia.ai/blog"
            value={config.url}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                url: e.target.value,
              }));
            }}
          />
        </Section>
        <Section>
          <SectionTitle description="The username of your WordPress account, the one you use to log in to your WordPress site.">
            Username
          </SectionTitle>
          <PrimaryInput
            fullWidth
            size="small"
            error={isWordPressUsernamePotentiallyInValid}
            helperText={
              isWordPressUsernamePotentiallyInValid
                ? `Your WordPress Username is potentially not correct. Please double-check the case sensitivity, special characters, and spaces.`
                : ""
            }
            value={config.username}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                username: e.target.value,
              }));
            }}
          />
        </Section>
        <Section>
          <SectionTitle description="Application Password of your WordPress account">
            Application Password
          </SectionTitle>
          <PrimaryInput
            fullWidth
            size="small"
            type="password"
            error={isWordPressPasswordPotentiallyInValid}
            helperText={
              isWordPressPasswordPotentiallyInValid
                ? `Your WordPress Application Password is potentially not correct. Please follow the instructions on how to create an Application Password.`
                : ""
            }
            value={config.password}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
          />
        </Section>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0 12px 36px 12px",
          }}
        >
          <PurplePinkButton
            disabled={
              !hasIntegrationFeature || Object.values(config).some((v) => !v)
            }
            loading={loading.isLoading}
            onClick={() => {
              onConnect();
            }}
          >
            Connect
          </PurplePinkButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelfHostWordPressConnectModal;
