import { BrandLoadingOverlay } from "components/ui/loading/typing-loading";

import * as React from "react";
// import IntegrationsPopup from "src/modules/integrations/integrations-popup";
import dynamic from "next/dynamic";
import UpgradePopup from "components/upgrade-popup";
import Popup from "components/popup";
import AddonModal from "components/addon-modal";
import ConfirmDeleteModal from "components/confirm-delete-modal";
import LimitReachModalContainer from "components/limit-reached-modal";
import RateLimitModal from "components/rate-limit-modal";
import LimitReachModal from "components/limit-reached-modal/limit-reached-modal";
import ShopifyModal from "modules/integration/shopify/shopify-publish";
import BulkShopifyModal from "modules/integration/shopify/bulk-shopify-publish";
import MediumPublishModal from "modules/integration/medium/medium-publish-modal";
import RepurposeModal from "modules/repurpose/repurpose-modal";
import RedditPublishModal from "modules/integration/reddit/reddit-publish-modal";
import ShopifyConnectModal from "modules/integration/shopify/shopify-connect-modal";
import WebflowPublishModal from "modules/integration/webflow/webflow-publish";
import BulkMediumPublishModal from "modules/integration/medium/bulk-medium-publish-modal";
import ReconnectWebsiteModal from "modules/integration/reconnect-website";
import APIPublishModal from "modules/integration/api/bulk-api-publish-modal";
import NoBillingRightModal from "components/no-billing-right";

const FeedbackWidget = dynamic(() => import("modules/client-app/feedback"), {
  ssr: false,
});

const OldShopifyModal = dynamic(
  () => import("modules/integration/shopify/old-shopify"),
  { ssr: false, loading: () => <BrandLoadingOverlay /> }
);

const WordPressPublishModal = dynamic(
  () => import("modules/integration/wordpress-publish"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const BulkWordPressPublishModal = dynamic(
  () => import("modules/integration/wordpress/bulk-wordpress-publish"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const DocumentsSelector = dynamic(
  () => import("modules/collection-by-id/import/documents-selector"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);
const ImproveSEOPopup = dynamic(
  () => import("modules/collection-by-id/editor/improve-seo-modal"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const KeywordExplorer = dynamic(
  () => import("modules/keywords/keywords-modal"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const PromptLibrary = dynamic(() => import("modules/prompt-library"), {
  ssr: false,
  loading: () => <BrandLoadingOverlay />,
});

const ImageFormModal = dynamic(
  () => import("modules/ai-image/image-form-modal"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const TranslatePopup = dynamic(() => import("components/transplate-popup"), {
  ssr: false,
  loading: () => <BrandLoadingOverlay />,
});

const TemplatesPopup = dynamic(
  () => import("modules/templates/editor-template-popup-selector"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const FileUpload = dynamic(
  () => import("components/file-upload/file-upload-modal"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);

const InfoBasePopup = dynamic(() => import("components/info-base-popup"), {
  ssr: false,
  loading: () => <BrandLoadingOverlay />,
});

const StylePopup = dynamic(() => import("components/style-popup"), {
  ssr: false,
  loading: () => <BrandLoadingOverlay />,
});

const AddWebSiteIntegration = dynamic(
  () => import("modules/integration/add-website-integration"),
  {
    ssr: false,
    loading: () => <BrandLoadingOverlay />,
  }
);
const ManageWebsiteIntegration = dynamic(
  () => import("modules/integration/manage-website-integration/index"),
  {
    loading: () => <BrandLoadingOverlay />,
    ssr: false,
  }
);

type Popup = {
  componentType: PopupComponentType;
  props?: Record<string, any>;
};

interface PopupContextValue {
  popups: Popup[];
  onOpenPopup: (
    componentType: PopupComponentType,
    props?: Record<string, any>
  ) => void;
  onClosePopup: (componentType: PopupComponentType) => void;
  clearPopups: () => void;
}

const PopupContext = React.createContext<PopupContextValue | null>(null);

export const usePopupContext = () => {
  const context = React.useContext(PopupContext);
  if (!context) {
    throw new Error("PopupContext needs to be used with PopupContextProvider");
  }
  return context;
};

const popupsByType = {
  templatesPopupLibrary: TemplatesPopup,
  // integrationsPopupLibrary: IntegrationsPopup,
  confirmDeletePopup: ConfirmDeleteModal,
  feedback: FeedbackWidget,
  promptLibrary: PromptLibrary,
  fileUpload: FileUpload,
  translatePopup: TranslatePopup,
  infoBasePopup: InfoBasePopup,
  stylePopup: StylePopup,
  imageForm: ImageFormModal,
  addWebSiteIntegration: AddWebSiteIntegration,
  manageWebsiteIntegration: ManageWebsiteIntegration,
  creditLimitReached: LimitReachModalContainer,
  limitReached: LimitReachModal,
  rateLimitModal: RateLimitModal,
  upgradePopup: UpgradePopup,
  addonModal: AddonModal,
  popup: Popup,
  documentsSelector: DocumentsSelector,
  keywordExplorer: KeywordExplorer,
  oldShopifyModal: OldShopifyModal,
  shopifyModal: ShopifyModal,
  bulkShopifyModal: BulkShopifyModal,
  shopifyConnectModal: ShopifyConnectModal,
  mediumPublishModal: MediumPublishModal,
  bulkMediumPublishModal: BulkMediumPublishModal,
  repurposeModal: RepurposeModal,
  redditPublishModal: RedditPublishModal,
  wordpressPublishModal: WordPressPublishModal,
  bulkWordpressPublishModal: BulkWordPressPublishModal,
  improveSEO: ImproveSEOPopup,
  webflowPublishModal: WebflowPublishModal,
  reconnectWebsiteModal: ReconnectWebsiteModal,
  apiPublishModal: APIPublishModal,
  noBillingRightModal: NoBillingRightModal
} as const;

export type PopupComponentType = keyof typeof popupsByType;
export type Component = typeof popupsByType[PopupComponentType];

interface PopupInnerContextValue {
  onClosePopup: () => void;
}
const PopupInnerContext = React.createContext<PopupInnerContextValue | null>(
  null
);
const usePopupInnerContext = () => {
  const context = React.useContext(PopupInnerContext);
  if (!context) {
    throw new Error(
      "PopupInnerContext needs to be used with PopupInnerContextProvider"
    );
  }
  return context;
};

export const useCloseSelectedPopup = () => usePopupInnerContext().onClosePopup;

const PopupInnerContextProvider = (popup: Popup) => {
  const { componentType, props } = popup;
  const onClosePopup = useClosePopup();

  const PopupComponent = popupsByType[componentType];
  const values = {
    onClosePopup: () => onClosePopup(componentType),
  };
  if (!PopupComponent) {
    return null;
  }
  return (
    <PopupInnerContext.Provider value={values}>
      <PopupComponent key={componentType} {...props} />
    </PopupInnerContext.Provider>
  );
};

export const PopupRenderer = () => {
  const popups = useSelectPopups();
  return (
    <>
      {popups.map((popup) => {
        return (
          <PopupInnerContextProvider key={popup.componentType} {...popup} />
        );
      })}
    </>
  );
};

export const PopupContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [popups, setPopups] = React.useState<Popup[]>([]);

  const clearPopups = React.useCallback(() => {
    setPopups([]);
  }, []);

  const onOpenPopup = React.useCallback(
    (componentType: PopupComponentType, props = {}) => {
      setPopups((previous) => {
        const found = previous.find(
          (popup) => popup.componentType === componentType
        );
        if (!found) {
          return [
            ...previous,
            {
              componentType,
              props,
            },
          ];
        }
        return previous;
      });
    },
    []
  );

  const onClosePopup = React.useCallback(
    (componentType: PopupComponentType) => {
      setPopups((previous) => {
        return previous.filter(
          (popup) => popup.componentType !== componentType
        );
      });
    },
    []
  );
  const values = {
    popups,
    onOpenPopup,
    onClosePopup,
    clearPopups,
  };

  return (
    <PopupContext.Provider value={values}>
      {children}
      <PopupRenderer />
    </PopupContext.Provider>
  );
};

export const useSelectPopups = () => usePopupContext().popups;

export const useOpenPopup = () => usePopupContext().onOpenPopup;

export const useClosePopup = () => usePopupContext().onClosePopup;
