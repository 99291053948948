import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useCloseSelectedPopup, useOpenPopup } from "context/popup-context";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { PurplePinkButton } from "components/purple-button";
import { Section, SectionTitle } from "modules/selections/common";
import { Select } from "components/ui/select";
import { MenuItem } from "components/ui/menu";
import { textEllipsis } from "modules/themes/styleUtils";
import { useLoading } from "hooks/use-loading";
import { apiClient } from "utils/client-axios";
import { PrimaryInput } from "components/input";
import { CircularProgress } from "components/ui/loading";
import { IntegrationServer, Post } from "client-server-shared/types/types";
import { useSelector } from "hooks/use-selector";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { slugify } from "client-server-shared/utils/text-utils";
import { useNotifications } from "hooks/use-notification";
import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import { getH1 } from "utils/get-h1";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { getRequestErrorObject } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";
import { IntegrationLayout } from "../layout";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import SchedulePublish from "../schedule-publish";
import { SchedulePublishConfig } from "client-server-shared/types/workflow";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { selectIntegrationsByType } from "selectors/user";
import { ShopifyConnectModal } from "./shopify-connect-modal";
import protectedClientApi from "utils/protected-client-api";

const ShopifyModal = (props) => {
  const { onSave } = props;
  const onClosePopup = useCloseSelectedPopup();
  const { selectedIntegration, onCustomEditPost } = useIntegrationContext();

  const [blogs, setBlogs] = React.useState<{ title: string; id: string }[]>([]);
  const [authors, setAuthors] = React.useState<string[]>([]);

  const [errorMesssage, setErrorMessage] = React.useState<string>("");
  const [config, setConfig] = React.useState({
    status: "Published",
  });

  const integrationIdRef = React.useRef<string | null>(null);

  React.useEffect(() => {
    if (selectedIntegration) {
      integrationIdRef.current = selectedIntegration.id;
    }
  }, [selectedIntegration?.id]);

  const openPopup = useOpenPopup();
  const [schedulePublishConfig, setSchedulePublishConfig] =
    React.useState<SchedulePublishConfig>({
      enabled: false,
      amount: 1,
      ...props.schedulePublishConfig,
    });

  const onConfigChange = React.useCallback((key, value) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);
  const gettingBlogs = useLoading();

  const { onUpgrade } = useClickUpgrade({
    source: SourceType.ShopifyIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();

  const onHandleSave = async () => {
    if (!selectedIntegration) {
      return;
    }

    onSave({
      blogId: config.blog,
      author: config.author,
      schedulePublishConfig,
      integrationId: selectedIntegration.id,
      status: config.status,
      type: IntegrationType.Shopify,
    });
    onClosePopup();
  };

  const getBlogData = async () => {
    if (!selectedIntegration) {
      return;
    }

    const lastIntegrationId = integrationIdRef.current;
    setErrorMessage("");
    gettingBlogs.onLoading();
    await protectedClientApi
      .getShopifyCommonData(selectedIntegration.id)
      .then((res) => {
        if (lastIntegrationId !== integrationIdRef.current) {
          return;
        }
        const data = res;
        const allBlogs = data.blogs;
        const allAuthors = data.authors;
        setBlogs(allBlogs);
        setAuthors(allAuthors);
        if (allBlogs.length > 0) {
          onConfigChange("blog", allBlogs[0].id);
        }
        if (allAuthors.length > 0) {
          onConfigChange("author", allAuthors[0]);
        }
        gettingBlogs.onIdle();
      })
      .catch((e) => {
        if (lastIntegrationId !== integrationIdRef.current) {
          return;
        }
        console.log("error", e);
        const errorJSON = getRequestErrorObject(e);
        let message = `There's an error getting your Shopify data. Please double check your Shopify credentials. If the issue persists, try reconneting to shopify to see if it helps.`;
        if (errorJSON?.code === ErrorCode.InvalidShopifyURLError) {
          message = `Your Shopify URL is invalid, please double-check your Shopify store URL and try reconnecting to Shopify.`;
        } else if (
          errorJSON?.code === ErrorCode.InvalidShopifyCredentialsError
        ) {
          message = `Your Shopify credentials is invalid, please double-check your Shopify access token and secret key and try reconnecting to Shopify.`;
        } else if (
          errorJSON?.code === ErrorCode.InvalidShopifyPermissionsError
        ) {
          message = `Your Shopify credentials does not have the necessary permissions to access your Shopify data. Please double-check your Shopify permissions and try reconnecting to Shopify.`;
        }
        setErrorMessage(message);

        gettingBlogs.onIdle();
      });
  };

  React.useEffect(() => {
    getBlogData();
  }, [
    selectedIntegration?.id,
    selectedIntegration?.shopifyAccessToken,
    selectedIntegration?.shopifyStoreName,
    selectedIntegration?.shopifySecretKey,
  ]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={() => {
        onClosePopup();
      }}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                marginRight: "24px",
              }}
            >
              Publish To Shopify in Bulk
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>
          <IconButton
            aria-label={"Close"}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          {" "}
          {gettingBlogs.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={32} />
            </Box>
          ) : errorMesssage ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Description>{errorMesssage}</Description>
              <OutlineGreyButton
                onClick={() => {
                  onCustomEditPost(selectedIntegration);
                  /*
                  openPopup("shopifyConnectModal", {
                    afterConnect: () => {
                      getBlogData();
                    },
                  });
                  */
                }}
              >
                Reconnect To Shopify
              </OutlineGreyButton>
            </Box>
          ) : (
            <>
              <Section>
                <SectionTitle>Select a Blog</SectionTitle>

                <Box
                  sx={{
                    "& .MuiInputBase-root": {
                      width: "100%",
                    },
                  }}
                >
                  <Select
                    size="small"
                    onChange={(e, newValue) => {
                      onConfigChange("blog", e.target.value);
                    }}
                    value={config.blog}
                  >
                    {blogs.map((blog) => {
                      return (
                        <MenuItem key={blog.id} value={blog.id}>
                          <Typography
                            variant="body2"
                            sx={{
                              ...textEllipsis,
                              maxWidth: "100px",
                              fontSize: "12px",
                            }}
                          >
                            {blog.title}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Section>
              <Section>
                <SectionTitle>Select An Author</SectionTitle>{" "}
                <Box
                  sx={{
                    "& .MuiInputBase-root": {
                      width: "100%",
                    },
                  }}
                >
                  <Select
                    size="small"
                    onChange={(e, newValue) => {
                      onConfigChange("author", e.target.value);
                    }}
                    value={config.author}
                  >
                    {authors.map((author) => {
                      return (
                        <MenuItem key={author} value={author}>
                          <Typography
                            variant="body2"
                            sx={{
                              ...textEllipsis,
                              maxWidth: "100px",
                              fontSize: "12px",
                            }}
                          >
                            {author}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Section>
              <Section>
                <SectionTitle>Mode</SectionTitle>
                <Select
                  size="small"
                  onChange={(e, newValue) => {
                    onConfigChange("status", e.target.value);
                  }}
                  value={config.status}
                >
                  {["Draft", "Published"].map((status) => {
                    return (
                      <MenuItem key={status} value={status}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...textEllipsis,
                            maxWidth: "100px",
                            fontSize: "12px",
                          }}
                        >
                          {status}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Section>
              <SchedulePublish
                disableIndexer
                schedulePublishConfig={schedulePublishConfig}
                onChange={setSchedulePublishConfig}
              />
              {errorMesssage ? null : (
                <PurplePinkButton
                  sx={{
                    margin: "auto",
                  }}
                  disabled={!hasIntegrationFeature || !config.blog}
                  onClick={() => {
                    onHandleSave();
                  }}
                >
                  Save
                </PurplePinkButton>
              )}
            </>
          )}
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};
const ShopifyConnectModalInnerContainer = ({ afterConnect, onClose }) => {
  const { selectedIntegration } = useIntegrationContext();

  return (
    <ShopifyConnectModal
      selectedIntegration={selectedIntegration}
      onClose={onClose}
      afterConnect={afterConnect}
    />
  );
};

const BulkShopifyModal = ({
  onSave,
  defaultIntegrationId,
  schedulePublishConfig = {},
}) => {
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<IntegrationServer | null>(null);
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  return (
    <>
      <IntegrationContextProvider
        inPublishMode
        defaultIntegrationId={defaultIntegrationId}
        onAddNewIntegration={() => {
          setShowConnectModal(true);
        }}
        onCustomEditPost={(integration) => {
          setSelectedIntegration(integration);
        }}
        type={IntegrationType.Shopify}
      >
        {showConnectModal && (
          <ShopifyConnectModal
            onClose={() => {
              setShowConnectModal(false);
            }}
            afterConnect={() => {
              setShowConnectModal(false);
            }}
          />
        )}
        <ShopifyModal
          onSave={onSave}
          schedulePublishConfig={schedulePublishConfig}
        />
        {selectedIntegration && (
          <ShopifyConnectModal
            selectedIntegration={selectedIntegration}
            onClose={() => {
              setSelectedIntegration(null);
            }}
            afterConnect={() => {
              setSelectedIntegration(null);
            }}
          />
        )}
      </IntegrationContextProvider>
    </>
  );
};

export default BulkShopifyModal;