import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";

import React from "react";
import { colors } from "src/constants";

const Checkbox = (props: CheckboxProps) => {
  const sx = getStyleProps(props).sx;
  return (
    <MuiCheckbox
      {...passProps(props)}
      sx={{
        ...sx,
        "&.Mui-checked": {
          color: colors.red1,
        },
        "&.MuiCheckbox-indeterminate": {
          color: colors.red1,
        },
        color: colors.red1,
      }}
    />
  );
};

export default Checkbox;
