import React from "react";
import {
  editCollectionPropertiesByCollectionId,
  editPostConfigByCollectionIdAndPostId,
  editPostMetadataByCollectionIdAndPostId,
  editPostPropertiesByCollectionIdAndPostId,
  updateActiveContentByCollectionIdAndPostId,
} from "reducers/collections/actions";
import { PostConfig } from "client-server-shared/types/types";
import { useAppDispatch } from "./use-app-dispatch";
import type { TemplateType } from "templates/shared-templates/types";
import { batch } from "react-redux";
import { addDefaultPostConfig } from "client-server-shared/utils/add-default-post-config";
import { useSelector } from "./use-selector";
import {
  selectPostByCollectionIdAndPostId,
  selectPostConfigByCollectionIdAndPostId,
} from "selectors/collections";
import usePrevious from "external/react-use/usePrevious";
import { saveCollectionByIdThunk } from "redux-middlewares/save-collection-middleware";
import { useLoading } from "./use-loading";
import { useOnCopyToClipboard } from "hooks/use-copy-to-clipboard";
import { fullShareArticleRouteById } from "client-server-shared/config/routes";
import { trackSharePost } from "utils/tracking/collection";
import { languageOptions } from "client-server-shared/constants";

export const useSharePostById = (collectionId: string, postId: string) => {
  const { onPropertyChanged } = useEditPostPropertiesById(collectionId, postId);
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const dispatch = useAppDispatch();

  const { isLoading, onLoading, onIdle } = useLoading();

  const [mounted, setMounted] = React.useState(false);
  const { onCopy } = useOnCopyToClipboard();

  const previousPublic = usePrevious(post?.public);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (mounted && post?.public && !previousPublic && !isLoading) {
      onLoading();
      dispatch(
        saveCollectionByIdThunk("collectionId", {
          onError: () => {
            onIdle();
          },
          afterSuccess: () => {
            onIdle();
            onCopy(fullShareArticleRouteById(postId));
          },
        })
      );
    }
  }, [post?.public, previousPublic]);

  const onShare = React.useCallback(() => {
    trackSharePost();
    if (!post?.public) {
      onPropertyChanged("public", true);
    } else {
      onCopy(fullShareArticleRouteById(postId));
    }
  }, [onPropertyChanged, onCopy, collectionId, postId, post?.public]);
  return { onShare, isLoading };
};

export const usePostLanguage = (collectionId: string, postId: string) => {
  const postConfig = useSelector((state) =>
    selectPostConfigByCollectionIdAndPostId(state, collectionId, postId)
  );

  const { onPostConfigChanged } = useEditPostPropertiesById(
    collectionId,
    postId
  );

  const language = postConfig.language || languageOptions[0].value;

  const onChangeLanguage = (value: string) => {
    onPostConfigChanged("language")(value);
  };
  return {
    language,
    onChangeLanguage,
  };
};

export const useEditPostPropertiesById = (
  collectionId?: string,
  postId?: string
) => {
  const dispatch = useAppDispatch();

  const onPropertyChanged = React.useCallback(
    (field: string, newValue: unknown) => {
      if (!postId || !collectionId) {
        return;
      }
      dispatch(
        editPostPropertiesByCollectionIdAndPostId({
          postId,
          collectionId,
          update: {
            [field]: newValue,
          },
        })
      );
    },
    [dispatch, postId, collectionId]
  );

  const updateActiveContent = React.useCallback(
    (content: string) => {
      if (!postId || !collectionId) {
        return;
      }
      dispatch(
        updateActiveContentByCollectionIdAndPostId({
          postId,
          collectionId,
          content: content,
        })
      );
    },
    [dispatch, postId, collectionId]
  );

  const onTemplateChange = React.useCallback(
    (template: TemplateType) => {
      if (!postId || !collectionId) {
        return;
      }

      const defaultPostConfig = addDefaultPostConfig({
        wordsCount: template.defaultWordsCount,
      });

      batch(() => {
        onPropertyChanged("templateId", template.id);
        dispatch(
          editCollectionPropertiesByCollectionId({
            collectionId,
            update: {
              templateId: template.id,
            },
          })
        );
        dispatch(
          editPostConfigByCollectionIdAndPostId({
            postId,
            collectionId,
            update: defaultPostConfig,
            replace: true,
          })
        );
      });
    },
    [dispatch, onPropertyChanged, collectionId, postId]
  );

  const onPostConfigChanged = React.useCallback(
    (fieldName: keyof PostConfig) => {
      return (value: string | number | boolean | string[]) => {
        if (!postId || !collectionId) {
          return;
        }
        dispatch(
          editPostConfigByCollectionIdAndPostId({
            postId,
            collectionId,
            update: {
              [fieldName]: value,
            },
          })
        );
      };
    },
    [dispatch, collectionId, postId]
  );

  const onPostMetadataChanged = React.useCallback(
    (fieldName: string) => {
      return (value: string | number | boolean | string[]) => {
        if (!postId || !collectionId) {
          return;
        }
        dispatch(
          editPostMetadataByCollectionIdAndPostId({
            postId,
            collectionId,
            update: {
              [fieldName]: value,
            },
          })
        );
      };
    },
    [dispatch, collectionId, postId]
  );

  return {
    onPropertyChanged,
    onPostConfigChanged,
    onTemplateChange,
    updateActiveContent,
    onPostMetadataChanged,
  };
};
