import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { useNotifications } from "hooks/use-notification";
import { useLoading } from "hooks/use-loading";
import protectedClientApi from "utils/protected-client-api";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { Select } from "components/ui/select";
import { MenuItem } from "components/ui/menu";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { HighlightedSpan } from "components/as-inline-span";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { integrationAdded, integrationModified } from "reducers/user";
import { IntegrationClient } from "client-server-shared/types/types";
import { IntegrationLayout } from "../layout";

import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { useSelector } from "hooks/use-selector";
import { selectUserInActiveProPlan } from "selectors/user";
import { LimitReachModalPlain } from "components/limit-reached-modal/limit-reached-modal";

const WebflowConnectModal = ({
  afterConnect,
  onClose,
  fullyClose,
}: {
  fullyClose?: () => void;
  onClose: () => void;
  afterConnect?: () => void;
}) => {
  const {
    integrations,
    activeIntegrationId,
    setActiveIntegrationId,
    selectedIntegration,
  } = useIntegrationContext();
  const integration = selectedIntegration;
  const dispatch = useAppDispatch();
  const [config, setConfig] = React.useState({
    name: selectedIntegration?.name || "",
    apiToken: selectedIntegration?.webflow_api_key || "",
    site: selectedIntegration?.webflow_site || "",
    collection: selectedIntegration?.webflow_collection || "",
    htmlField: selectedIntegration?.webflow_field_html || "",
    metaDescriptionField:
      selectedIntegration?.webflow_field_meta_description || "",
    featureImageField: selectedIntegration?.webflow_field_feature_image || "",
  });
  const [webflowData, setWebflowData] = React.useState<any>(null);
  const { addSuccessNotification, addFailureNotification } = useNotifications();

  const { onUpgrade } = useClickUpgrade({
    source: SourceType.WebflowIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();

  const [selectedCollection, setSelectedCollection] = React.useState<any>(null);
  const saving = useLoading();
  const gettingWorkflowSites = useLoading();
  React.useEffect(() => {
    setWebflowData(null);
    setSelectedCollection(null);
    if (selectedIntegration) {
      setConfig({
        name: selectedIntegration?.name || "",
        apiToken: selectedIntegration?.webflow_api_key || "",
        site: selectedIntegration?.webflow_site || "",
        collection: selectedIntegration?.webflow_collection || "",
        htmlField: selectedIntegration?.webflow_field_html || "",
        metaDescriptionField:
          selectedIntegration?.webflow_field_meta_description || "",
        featureImageField:
          selectedIntegration?.webflow_field_feature_image || "",
      });
    } else {
      setConfig({
        name: "",
        apiToken: "",
        site: "",
        collection: "",
        htmlField: "",
        metaDescriptionField: "",
        featureImageField: "",
      });
    }
  }, [selectedIntegration]);
  const getSelectedCollection = async ({ apiToken, collection } = {}) => {
    try {
      const data = await protectedClientApi.getWebflowCollection({
        apiToken: apiToken || config.apiToken,
        collectionId: collection || config.collection,
      });
      setSelectedCollection(data);

      data.fields.forEach((field) => {
        setConfig((prev) => {
          return {
            ...prev,
            htmlField:
              prev.htmlField ||
              (field.displayName === "Post Body" ? field.id : ""),
            metaDescriptionField:
              prev.metaDescriptionField ||
              (field.displayName === "Post Summary" ? field.id : ""),
            featureImageField:
              prev.featureImageField ||
              (field.displayName === "Main Image" ? field.id : ""),
          };
        });
      });
    } catch (e) {
      addFailureNotification(
        "There's an error getting webflow sites, please double check your API token and the permission you granted."
      );
    }
  };
  const getWebflowSites = async (apiToken?: string) => {
    if (gettingWorkflowSites.isLoading) {
      return;
    }
    gettingWorkflowSites.onLoading();
    try {
      const data = await protectedClientApi.getWebflowSites(
        apiToken || config.apiToken
      );
      setWebflowData(data);
      gettingWorkflowSites.onIdle();
      if (data.length > 0) {
        setConfig((prev) => {
          return {
            ...prev,
            site: prev.site || data[0].id,
            collection:
              prev.collection ||
              (Array.isArray(data[0].collections) &&
              data[0].collections.length > 0
                ? data[0].collections[0].id
                : ""),
          };
        });
      }
    } catch (e) {
      addFailureNotification(
        "There's an error getting webflow sites, please double check your API token and the permission you granted."
      );
      gettingWorkflowSites.onIdle();
    }
  };

  const getWebflowIntegrations = async () => {
    if (integration) {
      setConfig((prev) => {
        return {
          ...prev,
          apiToken: integration.webflow_api_key,
          site: integration.webflow_site,
          collection: integration.webflow_collection,
          htmlField: integration.webflow_field_html,
          metaDescriptionField: integration.webflow_field_meta_description,
          featureImageField: integration.webflow_field_feature_image,
        };
      });
      await getWebflowSites(integration.webflow_api_key);
    }
  };
  React.useEffect(() => {
    getWebflowIntegrations();
  }, [integration?.id]);

  React.useEffect(() => {
    if (config.collection) {
      getSelectedCollection();
    }
  }, [config.collection]);

  const selectedSite = React.useMemo(() => {
    if (config.site && webflowData) {
      return webflowData.find((site) => site.id === config.site);
    }
  }, [config.site, webflowData]);

  const onSave = async () => {
    if (saving.isLoading) {
      return;
    }
    try {
      saving.onLoading();

      const toAPIFormat: Partial<IntegrationClient> = {
        name: config.name,
        webflow_api_key: config.apiToken,
        webflow_site: config.site,
        webflow_collection: config.collection,
        webflow_field_html: config.htmlField,
        webflow_field_meta_description: config.metaDescriptionField,
        webflow_field_feature_image: config.featureImageField,
        type: IntegrationType.Webflow,
      };

      if (selectedIntegration) {
        const updated = await protectedClientApi.updateIntegration(
          selectedIntegration.id,
          toAPIFormat
        );
        dispatch(integrationModified(updated));
      } else {
        const created = await protectedClientApi.saveIntegration(toAPIFormat);
        dispatch(integrationAdded(created));
      }

      addSuccessNotification("Webflow integration saved successfully.");
      setActiveIntegrationId("");
      setConfig({
        name: "",
        apiToken: "",
        site: "",
        collection: "",
        htmlField: "",
        metaDescriptionField: "",
        featureImageField: "",
      });

      if (afterConnect) {
        afterConnect();
      }
    } catch (e) {
      addFailureNotification(
        "There's an error saving the integration, please check your internet connection and try again."
      );
    }

    saving.onIdle();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={integrations.length === 0 ? "sm" : "lg"}
      open
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              Publish To Webflow
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>

          <IconButton
            aria-label="close"
            onClick={() => {
              if (fullyClose) {
                fullyClose();
              }
              onClose();
            }}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "6px",
          }}
        >
          <Description
            sx={{
              marginTop: "6px",
              maxWidth: "400px",
            }}
          >
            You can connect to multiple Webflow websites and choose which site
            to publish to. Learn where to find your Webflow API key{" "}
            <HighlightedSpan inherit>
              <a
                href="https://writingtools.ai/docs/webflow-integration"
                target="_blank"
              >
                here
              </a>
            </HighlightedSpan>
          </Description>
        </Box>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          <Section>
            <SectionTitle
              description={`Give this integration a name so you can easily identify it.`}
            >
              Integration Name
            </SectionTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              <PrimaryInput
                size="small"
                fullWidth
                value={config.name}
                onChange={(e) =>
                  setConfig((previous) => ({
                    ...previous,
                    name: e.target.value,
                  }))
                }
                placeholder="Eg: junia.ai"
              />
            </Box>
          </Section>
          <Section>
            <SectionTitle>API Token</SectionTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              <PrimaryInput
                value={config.apiToken}
                autoFocus
                fullWidth
                placeholder="Enter your API Token here"
                type={"password"}
                size="small"
                onChange={(e) => {
                  setConfig((previous) => ({
                    ...previous,
                    apiToken: e.target.value,
                  }));
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <OutlineGreyButton
                  loading={gettingWorkflowSites.isLoading}
                  sx={{
                    padding: "3px 12px",
                    background: "rgb(216, 49, 209)",
                    marginLeft: "auto",
                    color: "text.white",
                    "&:hover": {
                      "& svg": {
                        fill: "white",
                      },
                    },
                  }}
                  onClick={(e) => {
                    if (config.apiToken) {
                      getWebflowSites();
                    }
                  }}
                >
                  Verify
                </OutlineGreyButton>
              </Box>
            </Box>
          </Section>
          <Section>
            <SectionTitle>Select Webflow Site</SectionTitle>
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%",
                },
              }}
            >
              <Select
                size="small"
                displayEmpty
                onChange={(e, newValue) => {
                  setConfig((prev) => {
                    return {
                      ...prev,
                      site: e.target.value as string,
                      collection: "",
                    };
                  });
                }}
                value={config.site}
              >
                {webflowData && webflowData.length > 0 ? null : (
                  <MenuItem disabled value="">
                    <Description>Please enter an API Token first</Description>
                  </MenuItem>
                )}
                {(webflowData || []).map((site) => {
                  return (
                    <MenuItem key={site.id} value={site.id}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {site.displayName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Section>
          <Section>
            <SectionTitle>Select Webflow Collection</SectionTitle>
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%",
                },
              }}
            >
              <Select
                size="small"
                displayEmpty
                onChange={(e, newValue) => {
                  setConfig((prev) => {
                    return {
                      ...prev,
                      collection: e.target.value as string,
                      htmlField: "",
                      metaDescriptionField: "",
                      featureImageField: "",
                    };
                  });
                }}
                value={config.collection}
              >
                {webflowData && webflowData.length > 0 ? null : (
                  <MenuItem disabled value="">
                    <Description>Please enter an API Token first</Description>
                  </MenuItem>
                )}
                {(selectedSite?.collections || []).map((collection) => {
                  return (
                    <MenuItem key={collection.id} value={collection.id}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {collection.displayName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Section>

          <Section>
            <SectionTitle description="Webflow field to use for HTML">
              HTML Field
            </SectionTitle>
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%",
                },
              }}
            >
              <Select
                size="small"
                displayEmpty
                onChange={(e, newValue) => {
                  setConfig((prev) => {
                    return {
                      ...prev,
                      htmlField: e.target.value as string,
                    };
                  });
                }}
                value={config.htmlField}
              >
                {webflowData && webflowData.length > 0 ? null : (
                  <MenuItem disabled value="">
                    <Description>Please enter an API Token first</Description>
                  </MenuItem>
                )}
                {(selectedCollection?.fields || []).map((field) => {
                  return (
                    <MenuItem key={field.id} value={field.id}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {field.displayName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Section>

          <Section>
            <SectionTitle description="Webflow field to use for Meta Description">
              Meta Description Field
            </SectionTitle>
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%",
                },
              }}
            >
              <Select
                size="small"
                displayEmpty
                onChange={(e, newValue) => {
                  setConfig((prev) => {
                    return {
                      ...prev,
                      metaDescriptionField: e.target.value as string,
                    };
                  });
                }}
                value={config.metaDescriptionField}
              >
                {webflowData && webflowData.length > 0 ? null : (
                  <MenuItem disabled value="">
                    <Description>Please enter an API Token first</Description>
                  </MenuItem>
                )}
                {(selectedCollection?.fields || []).map((field) => {
                  return (
                    <MenuItem key={field.id} value={field.id}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {field.displayName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Section>

          <Section>
            <SectionTitle description="Webflow field to use for Feature Image">
              Feature Image Field
            </SectionTitle>
            <Box
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%",
                },
              }}
            >
              <Select
                size="small"
                displayEmpty
                onChange={(e, newValue) => {
                  setConfig((prev) => {
                    return {
                      ...prev,
                      featureImageField: e.target.value as string,
                    };
                  });
                }}
                value={config.featureImageField}
              >
                {webflowData && webflowData.length > 0 ? null : (
                  <MenuItem disabled value="">
                    <Description>Please enter an API Token first</Description>
                  </MenuItem>
                )}
                {(selectedCollection?.fields || []).map((field) => {
                  return (
                    <MenuItem key={field.id} value={field.id}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {field.displayName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Section>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OutlineGreyButton
              loading={saving.isLoading}
              startIcon={activeIntegrationId ? null : <AddOutlinedIcon />}
              disabled={
                !hasIntegrationFeature ||
                Object.keys(config).some(
                  (key) => config[key].trim().length === 0
                )
              }
              onClick={() => {
                onSave();
              }}
            >
              {activeIntegrationId ? "Update" : "Add"}
            </OutlineGreyButton>
          </Box>
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};

export const WebflowConnectModalContainer = ({
  afterConnect,
  onClose,
  fullyClose,
  defaultIntegrationId,
}: {
  defaultIntegrationId?: string;
  fullyClose?: () => void;
  onClose: () => void;
  afterConnect?: () => void;
}) => {
  const userInProPlan = useSelector(selectUserInActiveProPlan);
  if (!userInProPlan) {
    return (
      <LimitReachModalPlain
        onClosePopup={fullyClose}
        title="Upgrade To Unlock"
        description1="You need to be on a Premium plan to use this feature."
        description2="Ready to unlock more features and benefits with our paid plans?"
        actionText="Upgrade"
      />
    );
  }

  return (
    <IntegrationContextProvider
      defaultIntegrationId={defaultIntegrationId}
      type={IntegrationType.Webflow}
    >
      <WebflowConnectModal
        fullyClose={fullyClose}
        afterConnect={afterConnect}
        onClose={onClose}
      />
    </IntegrationContextProvider>
  );
};

export default WebflowConnectModalContainer;
