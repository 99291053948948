import { Box } from "components/ui/box";
import { Grid } from "components/ui/grid";
import React from "react";
import ExsitingIntegrations from "./existing-integration";
import { useIntegrationContext } from "./context";

export const IntegrationLayout = ({ children }) => {
  const { integrations } = useIntegrationContext();
  if (integrations.length === 0) {
    return <>{children}</>;
  }
  if (!children) {
    return <ExsitingIntegrations />;
  }
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      rowSpacing={2}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            paddingBottom: {
              xs: "24px",
              sm: "24px",
              md: "24px",
            },
            paddingRight: {
              xs: "0px",
              sm: "0px",
              md: "0",
              lg: "24px",
              xl: "24px",
            },
            borderRight: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: "1px solid rgba(0, 0, 0, 0.12)",
              xl: "1px solid rgba(0, 0, 0, 0.12)",
            },
            borderBottom: {
              xs: "1px solid rgba(0, 0, 0, 0.12)",
              sm: "1px solid rgba(0, 0, 0, 0.12)",
              md: "1px solid rgba(0, 0, 0, 0.12)",
              lg: 0,
              xl: 0,
            },
          }}
        >
          {children}
        </Box>
      </Grid>

      <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
        <ExsitingIntegrations />
      </Grid>
    </Grid>
  );
};
