import { createSelector } from "@reduxjs/toolkit";
import { isLoading } from "hooks/use-loading";
import { folderAdapter } from "reducers/folders";
import { folderExtraAdapter } from "reducers/folders/folder-extra";
import { RootState } from "reducers/types";

const foldersSelectors = folderAdapter.getSelectors();
const foldersExtraSelectors = folderExtraAdapter.getSelectors();

const selectBaseFolderState = (state: RootState) => state.folders;

export const selectFolderById = (state: RootState, id?: string) => {
  return !id ? null : foldersSelectors.selectById(state.folders, id);
};

export const selectFolders = createSelector(selectBaseFolderState, (state) => {
  return foldersSelectors.selectAll(state);
});

export const selectFolderExtraById = (state: RootState, id?: string) => {
  return !id ? null : foldersExtraSelectors.selectById(state.foldersExtra, id);
};
