import { useProcessingLog } from "./use-loading";
import React from "react";
import { addCollections } from "reducers/collections/actions";
import { useAppDispatch } from "./use-app-dispatch";
import collectionClientApi from "utils/protected-client-api";
import { useNotifications } from "./use-notification";
import {
  selectAllSavedCollectionIds,
  selectHasMore,
  selectIsCollectionReadyByCollectionId,
} from "selectors/collections";
import { useSelector } from "./use-selector";
import { collectionPageId } from "src/constants";
import { captureException } from "utils/error-catching/lazy-sentry";
import { setHasMore } from "reducers/collections/collection-extra";
import { useLoading } from "./use-loading";
import { getFoldersLogId } from "redux-middlewares/folders-middleware";
import { foldersAdded } from "reducers/folders";
import { folderSetHasMore } from "reducers/folders/folder-extra";
import { LoadingStatus } from "reducers/processing-log/types";
import protectedClientApi from "utils/protected-client-api";
import { selectFolderExtraById } from "selectors/folders";
import { selectFolders } from "selectors/folders";
import usePrevious from "external/react-use/usePrevious";
import { selectIsUserAdmin } from "selectors/user";
import { Post } from "client-server-shared/types/types";

export const useRefreshCollection = () => {
  const dispatch = useAppDispatch();

  const onRefreshCollection = React.useCallback(
    async (id: string) => {
      const collection = await collectionClientApi.getCollectionById(id);
      dispatch(addCollections([collection]));
    },
    [dispatch]
  );

  return { onRefreshCollection };
};

export const useCancelSchedulePublishing = () => {
  const dispatch = useAppDispatch();
  const { onRefreshCollection } = useRefreshCollection();

  const onCancel = React.useCallback(
    async (post: Post) => {
      await collectionClientApi.cancelPostSchedule(post.clientId);

      await onRefreshCollection(post.collectionId!);
    },
    [dispatch, onRefreshCollection]
  );
  return { onCancel };
};

export const useLoadCollectionByIdOnce = (id: string) => {
  const dispatch = useAppDispatch();
  const isCollectionReady = useSelector((state) =>
    selectIsCollectionReadyByCollectionId(state, id)
  );
  const { initiated, addLog, onError, onSuccess } = useProcessingLog(id);
  const getCollection = async () => {
    try {
      addLog();
      const collection = await collectionClientApi.getCollectionById(id);
      dispatch(addCollections([collection]));
      onSuccess();
    } catch (e) {
      captureException(e);
      onError();
    }
  };
  React.useEffect(() => {
    if (!isCollectionReady && !initiated) {
      getCollection();
    }
  }, [id]);
};

let limit = 20;
let hasError = false;
let isInitialLoad = true;
let loadLoading = false;

const useLoadCollection = (
  { folderId }: { folderId?: string } = {
    folderId: undefined,
  }
) => {
  const dispatch = useAppDispatch();
  const folderExtra = useSelector((state) =>
    selectFolderExtraById(state, folderId || "")
  );
  const userAdmin = useSelector(selectIsUserAdmin);

  const hasMore = useSelector(selectHasMore);

  let effectiveHasMore = hasMore;

  if (folderId && folderId !== "no-folder") {
    effectiveHasMore = !folderExtra ? true : folderExtra.hasMore;
  }

  const onLoadCollection = async ({
    offset,
    amount,
  }: {
    offset: number;
    amount: number;
  }) => {
    if (!effectiveHasMore) {
      return;
    }
    try {
      const data = await collectionClientApi.getCollections(
        offset,
        amount,
        folderId,
        userAdmin
      );
      dispatch(addCollections(data.data));
      if (folderId && folderId !== "no-folder") {
        dispatch(
          folderSetHasMore([
            {
              id: folderId,
              hasMore: data.hasMore,
            },
          ])
        );
      } else {
        dispatch(setHasMore(data.hasMore));
      }
    } catch (e) {
      if (hasError) {
        throw e;
      }
      hasError = true;
      limit = 3;

      await onLoadCollection({
        offset,
        amount: limit,
      });
    }
  };
  return { onLoadCollection };
};

export const useLoadCollectionPaginated = (
  {
    folderId,
  }: {
    folderId?: string;
  } = {
    folderId: undefined,
  }
) => {
  const collectionIds = useSelector((state) =>
    selectAllSavedCollectionIds(state, folderId)
  );

  const hasMore = useSelector(selectHasMore);
  const folderExtra = useSelector((state) =>
    selectFolderExtraById(state, folderId || "")
  );
  const previousCollectionIds = usePrevious(collectionIds);
  let effectiveHasMore = hasMore;

  if (folderId && folderId !== "no-folder") {
    effectiveHasMore = !folderExtra ? true : folderExtra.hasMore;
  }

  const offset = collectionIds.length;
  const dispatch = useAppDispatch();
  const { onLoadCollection } = useLoadCollection({
    folderId,
  });
  const log = useProcessingLog(collectionPageId);
  const offsetRef = React.useRef(offset);
  const loading = useLoading();
  React.useEffect(() => {
    offsetRef.current = offset;
  }, [collectionIds]);
  const onLoadmore = async () => {
    if (!effectiveHasMore) {
      return;
    }

    if (loadLoading || log.isLoading || loading.isLoading) {
      return;
    }

    loading.onLoading();

    let initialLoad = offsetRef.current === 0;

    await onLoadCollection({
      offset: offsetRef.current,
      amount: limit,
    });
    offsetRef.current = offsetRef.current + limit;

    if (hasError && isInitialLoad && initialLoad) {
      isInitialLoad = false;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
      await onLoadCollection({
        offset: offsetRef.current,
        amount: limit,
      });
      offsetRef.current = offsetRef.current + limit;
    }
    isInitialLoad = false;
    loading.onIdle();
  };
  React.useEffect(() => {
    if ((previousCollectionIds?.length || 0) > collectionIds.length) {
      onLoadmore();
    }
  }, [previousCollectionIds?.length || 0, collectionIds.length]);
  return {
    onLoadmore,
    hasMore: effectiveHasMore,
    collectionIds,
  };
};

export const useGetFolders = () => {
  const dispatch = useAppDispatch();
  const { isLoading, addLog, onSuccess, isSuccess, onError } =
    useProcessingLog(getFoldersLogId);
  const currentFolders = useSelector(selectFolders);
  const onGetFolders = React.useCallback(async () => {
    if (isLoading) {
      return;
    }
    try {
      addLog();
      const folders = await protectedClientApi.getFolders();
      dispatch(
        foldersAdded(
          folders.map((folder) => {
            const found = currentFolders.find((f) => f.id === folder.id);
            return {
              ...folder,
              status: found?.status || LoadingStatus.Idle,
            };
          })
        )
      );
      onSuccess();
    } catch (e) {
      captureException(e);
      onError();
    }
  }, [isLoading, onSuccess, onError, dispatch]);

  return { onGetFolders };
};

export const useLoadCollectionsOnce = (
  config: {
    loadAllFolders?: boolean;
    folderId?: string;
  } = {}
) => {
  const dispatch = useAppDispatch();
  const { initiated, addLog, onError, onSuccess } = useProcessingLog(
    config.folderId && config.folderId !== "no-folder"
      ? `${config.folderId}-documents`
      : collectionPageId
  );

  const { addFailureNotification } = useNotifications();
  const { onLoadCollection } = useLoadCollection({
    folderId: config.folderId,
  });
  const { onGetFolders } = useGetFolders();
  const getCollections = async () => {
    try {
      loadLoading = true;
      addLog();
      let offset = 0;
      await onLoadCollection({
        offset: offset,
        amount: limit,
      });
      onSuccess();
      offset = offset + limit;

      if (hasError && isInitialLoad) {
        isInitialLoad = false;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
        await onLoadCollection({
          offset: offset,
          amount: limit,
        });
        offset = offset + limit;
      }
      isInitialLoad = false;
      loadLoading = false;
    } catch (e) {
      loadLoading = false;
      captureException(e);
      onError();
      addFailureNotification(
        "Something went wrong getting your collections. Please double check your internet connection and try again."
      );
    }
  };

  React.useEffect(() => {
    return () => {
      hasError = false;
      isInitialLoad = true;
      loadLoading = false;
    };
  }, []);

  React.useEffect(() => {
    const promises: (() => Promise<void>)[] = [];
    if (!initiated) {
      promises.push(() => getCollections());
    }
    if (config.loadAllFolders) {
      promises.push(() => onGetFolders());
    }
    Promise.all(promises.map((p) => p()));
  }, [initiated, config.loadAllFolders]);
};
