import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogContent, {
  DialogContentProps,
} from "@mui/material/DialogContent";
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import MuiDialogActions, {
  DialogActionsProps,
} from "@mui/material/DialogActions";
import MuiBackdrop, { BackdropProps } from "@mui/material/Backdrop";
import React from "react";
import { getStyleProps } from "modules/themes/styleUtils";
import { CircularProgress } from "../loading";

export const Dialog = (props: DialogProps) => {
  return (
    <MuiDialog {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiDialog>
  );
};

export const DialogContent = React.forwardRef(
  (props: DialogContentProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiDialogContent ref={ref} {...rest} {...getStyleProps(rest)}>
        {children}
      </MuiDialogContent>
    );
  }
);
DialogContent.displayName = "DialogContent";

export const DialogTitle = React.forwardRef((props: DialogTitleProps, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiDialogTitle ref={ref} {...rest} {...getStyleProps(rest)}>
      {children}
    </MuiDialogTitle>
  );
});
DialogTitle.displayName = "DialogTitle";

export const DialogActions = (props: DialogActionsProps) => {
  return (
    <MuiDialogActions {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiDialogActions>
  );
};

export const Backdrop = (props: BackdropProps) => {
  return (
    <MuiBackdrop {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiBackdrop>
  );
};

export const FullScreenLoading = () => {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.white.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
