import React from "react";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { useUserHasFeatures } from "hooks/use-features";
import { useSelector } from "hooks/use-selector";
import {
  selectUserId,
  selectUserTeamRole,
  selectUserTeamId,
} from "selectors/user";
import { useLoading } from "hooks/use-loading";
import protectedClientApi from "utils/protected-client-api";
import { useNotifications } from "hooks/use-notification";
import { captureException } from "utils/error-catching/lazy-sentry";
import { InlineSpan } from "./as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";

const NoTeamFeatureModal = () => {
  const { hasTeamFeature } = useUserHasFeatures();
  const userTeamRole = useSelector(selectUserTeamRole);
  const userId = useSelector(selectUserId);
  const teamId = useSelector(selectUserTeamId);
  const leaving = useLoading();
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.NoTeamFeatureModal,
  });
  const { addFailureNotification } = useNotifications();
  const onLeaveTeam = async () => {
    if (leaving.isLoading) {
      return;
    }
    try {
      leaving.onLoading();
      await protectedClientApi.removeUserFromTeam(teamId, userId);
      window.location.reload();
    } catch (e) {
      captureException(e);
      addFailureNotification(
        `There was an error removing the team member. Please try again later.`
      );
    }
    leaving.onIdle();
  };

  if (userId && teamId && !hasTeamFeature && userTeamRole === "Member") {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: "10px",
            padding: "12px 0",
          },
        }}
        open
        onClose={() => {}}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "24 24px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              marginRight: "24px",
            }}
          >
            Hey there!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              marginBottom: "12px",
            }}
          >
            It looks like your current plan doesn't support team members. To
            continue using our platform, please either contact your team owner
            to{" "}
            <InlineSpan
              inherit
              onClick={() => {
                onUpgrade();
              }}
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              upgrade
            </InlineSpan>{" "}
            your plan or leave the team.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "12px",
              marginTop: "24px",
            }}
          >
            <OutlineGreyButton
              loading={leaving.isLoading}
              sx={{}}
              onClick={() => {
                onLeaveTeam();
              }}
            >
              Leave Team
            </OutlineGreyButton>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};

export default NoTeamFeatureModal;
