import { trackClickUpgradeToPro } from "utils/tracking/payment";
import { SourceType } from "utils/tracking/type";
import { useSelector } from "./use-selector";
import { selectUser } from "selectors/user";
import { useOpenPopup } from "context/popup-context";

export const useClickUpgrade = ({ source }: { source: SourceType }) => {
  const user = useSelector(selectUser);
  const onOpenPopup = useOpenPopup();
  const onUpgrade = (newTab?: boolean) => {
    onOpenPopup("upgradePopup");
    trackClickUpgradeToPro({
      source,
      userId: user?.id,
    });
    /*
    if (newTab) {
      window.open(dashboardRouteConfig.upgradeRoute, "_blank");
    } else {
      navigate(dashboardRouteConfig.upgradeRoute);
    }
    */
  };
  return { onUpgrade };
};

