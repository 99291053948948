import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import pluralize from "pluralize";

import { useCloseSelectedPopup } from "context/popup-context";
import { InlineSpan } from "components/as-inline-span";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { PurplePinkButton } from "components/purple-button";
import { Tooltip } from "components/ui/tooltip";
import { FormControlLabel } from "components/ui/form/form-control-label";
import Switch from "components/ui/form/switch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ReconnectWebsiteModal = ({ onConfirm }) => {
  const [pagesAmount, setPagesAmount] = React.useState(20);
  const [includePattern, setIncludePattern] = React.useState<string>("");
  const [excludePattern, setExcludePattern] = React.useState<string>("");
  const [crawNewPagesOnly, setCrawlNewPagesOnly] = React.useState(false);
  const onClose = useCloseSelectedPopup();
  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginRight: "24px",
            }}
          >
            Reconnect Website
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Section>
          <SectionTitle
            optional
            info={`If you enter "/blog" or "/new", we will only include pages that contain these paths. For example, /blog, /blog/1, /news, /news/1, etc., will be included.`}
          >
            Include Pattern
          </SectionTitle>
          <PrimaryInput
            multiline
            maxRows={5}
            minRows={1}
            fullWidth
            value={includePattern}
            onChange={(e) => {
              setIncludePattern(e.target.value);
            }}
            size="small"
            placeholder="Example: /blog, /news. Separate each path with a new line"
          />
        </Section>
        <Section>
          <SectionTitle
            optional
            info={`If you enter "/contact-us", "/policy", we will exclude pages that contain these paths. For example, /contact-us, /policy/private, /policy/terms, /policy/cookie, etc., will be excluded.`}
          >
            Exclude Pattern
          </SectionTitle>
          <PrimaryInput
            multiline
            maxRows={5}
            minRows={1}
            fullWidth
            value={excludePattern}
            onChange={(e) => {
              setExcludePattern(e.target.value);
            }}
            size="small"
            placeholder="Example: /contact-us, /policy. Separate each path with a new line"
          />
        </Section>
        <Section>
          <SectionTitle
            info={`Choose the number of pages per second that your website allows to be crawled.`}
          >
            Crawl Speed
          </SectionTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <PrimaryInput
              type="number"
              onChange={(e) => {
                setPagesAmount(e.target.value);
              }}
              size="small"
              value={pagesAmount}
            />
            <InlineSpan inherit>
              <Description>
                {pluralize("page", pagesAmount)} per second
              </Description>
            </InlineSpan>
          </Box>
        </Section>
        <Section
          sx={{
            margin: "12px 0",
          }}
        >
          <Tooltip
            title={`If enabled, we will only crawl new pages that aren't already in your account, this will make the crawling process faster. If disabled, we will recrawl your entire website.`}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  onChange={() => {
                    setCrawlNewPagesOnly((previous) => !previous);
                  }}
                  checked={crawNewPagesOnly}
                />
              }
              sx={{
                marginRight: 0,
                marginLeft: 0,
              }}
              componentsProps={{
                typography: {
                  variant: "body2",
                  color: "rgb(99, 115, 129)",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                },
              }}
              label={
                <>
                  <InfoOutlinedIcon
                    sx={{
                      height: "15px",
                      width: "15px",
                      fill: "rgba(0, 0, 0, 0.2)",
                    }}
                  />
                  <InlineSpan inherit>Crawl New Pages Only</InlineSpan>{" "}
                </>
              }
            />
          </Tooltip>
        </Section>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "24px 12px 36px 12px",
        }}
      >
        <PurplePinkButton
          onClick={() => {
            onConfirm({
              pagesAmount,
              includePattern,
              excludePattern,
              crawNewPagesOnly,
            });
            onClose();
          }}
        >
          Confirm
        </PurplePinkButton>
        <OutlineGreyButton
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </OutlineGreyButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReconnectWebsiteModal;
