import { serverRoutes } from "client-server-shared/config/routes";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import React from "react";
import { SolidCallToAction } from "./call-to-action";
import { Container, ResponsiveText } from "./front-page/shared";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useRouter } from "next/router";
import { useTranslation } from "components/translate";
import { graidentTextStyle } from "modules/non-protected-page/heros/shared";
import { PurplePinkButton } from "components/purple-button";
import Link from "next/link";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useSelector } from "hooks/use-selector";
import { selectUserInYearlyPlan } from "selectors/user";
import { dayjs } from "client-server-shared/utils/dayjs";
import CountDownTimer from "components/coutdown/CountDownTimer";
import { oneWeekAfter } from "./banner-utils";
import { XMarsBg } from "./banner";
export const isBannerActive = () => {
  const userInYearlyPlan = useSelector(selectUserInYearlyPlan);
  if (userInYearlyPlan) {
    return false;
  }

  return !dayjs().isAfter(oneWeekAfter);
};

const LightBulk = () => {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 35"
      fill="none"
      sx={{
        position: "absolute",
        height: "24px",
        width: "24px",
        left: "-30px",
        top: 0,
        display: {
          xs: "none",
          sm: "initial",
          md: "initial",
          lg: "initial",
        },
      }}
    >
      <path
        d="M7.59297 34.2008H5.69292L7.59297 20.9005H0.942815C-0.15921 20.9005 -0.140209 20.2925 0.220799 19.6464C0.581807 19.0004 0.315801 19.4944 0.353802 19.4184C2.80486 15.0863 6.49094 8.62619 11.3931 0H13.2931L11.3931 13.3003H18.0432C18.9742 13.3003 19.1072 13.9273 18.9362 14.2693L18.8032 14.5543C11.317 27.6456 7.59297 34.2008 7.59297 34.2008Z"
        fill="white"
      />
    </Box>
  );
};

const Line = () => {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: "absolute",
        height: "24px",
        width: "54px",
        right: "0",
        bottom: "-16px",
      }}
      viewBox="0 0 66 10"
      fill="none"
    >
      <path
        d="M2.00213 2.0122C14.7343 1.83503 28.0733 1.01847 40.6709 2.14495C49.7813 2.95961 56.8328 5.68119 64.0072 8.65565"
        stroke="url(#paint0_linear_1202_98)"
        strokeWidth="2.29752"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1202_98"
          x1="32.9503"
          y1="1.42867"
          x2="-47.838"
          y2="26.5217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BC01B5" />
          <stop offset="1" stopColor="#BC01B5" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Box>
  );
};

export const BlackFridayBanner = React.memo(() => {
  return (
    <Box
      sx={{
        borderTopLeftRadius: "10px",
        padding: {
          xs: "12px 12px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },
        position: "relative",
        overflow: "hidden",
        columnGap: "48px",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          background: "#39125F",
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 1,
          borderTopRightRadius: "15px",
          transform: "skewX(10deg)",
          transformOrigin: "100% 100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          position: "relative",
          zIndex: 2,
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          <LightBulk />
          <ResponsiveText
            fontSize={16}
            sx={{
              fontWeight: 600,
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan inherit>
              Special New Year Deal: Get extra 20% off our annual plans using
              the code: JUNIAXMAS2024.
            </InlineSpan>
          </ResponsiveText>
        </Box>
      </Box>
    </Box>
  );
});

export const PaidTrailBanner = React.memo(() => {
  return (
    <Box
      sx={{
        borderTopLeftRadius: "10px",
        padding: {
          xs: "12px 12px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },
        position: "relative",
        overflow: "hidden",
        columnGap: "48px",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          background: "#39125F",
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 1,
          borderTopRightRadius: "15px",
          transform: "skewX(10deg)",
          transformOrigin: "100% 100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          position: "relative",
          zIndex: 2,
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          <LightBulk />
          <ResponsiveText
            fontSize={16}
            sx={{
              fontWeight: 600,
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan inherit>
              Try Junia with a 5-day trial for only{" "}
            </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                color: "rgba(233, 98, 255, 1)",
              }}
            >
              $2,99
            </InlineSpan>
            <InlineSpan inherit>. Cancel anytime.</InlineSpan>{" "}
          </ResponsiveText>
        </Box>
      </Box>
    </Box>
  );
});

export const PopupBanner3 = React.memo(() => {
  const { t } = useTranslation("front-page");
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.Dashboard,
  });
  const userInYearlyPlan = useSelector(selectUserInYearlyPlan);

  if (userInYearlyPlan) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "#CC231E",
        padding: {
          xs: "12px 6px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },

        columnGap: "48px",
        position: "relative",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
      }}
    >
      <XMarsBg />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          {/*
            <LightBulk />
            */}
          <ResponsiveText
            fontSize={16}
            sx={{
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan
              inherit
              sx={{
                fontWeight: "700!important",
              }}
            >
              NEW YEAR{" "}
            </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                fontWeight: 400,
              }}
            >
              Get extra
            </InlineSpan>{" "}
            <InlineSpan
              inherit
              sx={{
                color: "text.white",
                fontWeight: "700!important",
              }}
            >
              20%
            </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                fontWeight: 400,
              }}
            >
              {" "}
              off on All Annual Plans
            </InlineSpan>
          </ResponsiveText>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "48px",
              marginRight: "24px",
              justifyContent: "space-between",
              rowGap: "12px",
              whiteSpace: "nowrap",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              position: "relative",
            }}
          >
            <ResponsiveText
              fontSize={16}
              sx={{
                fontWeight: 500,
                color: "text.white",
              }}
            >
              {t("Use code: ")}
              <InlineSpan
                fontSize={16}
                sx={{
                  fontWeight: "700!important",
                  color: "text.white",
                }}
              >
                JUNIAXMAS2024
              </InlineSpan>
            </ResponsiveText>

          {
            /*
              <Line />
            */
          }
          </Box>
          <CountDownTimer targetDate={oneWeekAfter} />
          <PurplePinkButton
            onClick={() => {
              onUpgrade();
            }}
            sx={{
              margin: 0,
              whiteSpace: "nowrap",
              padding: {
                xs: "7px 12px",
                sm: "7px 24px",
                md: "7px 24px",
                lg: "7px 24px",
              },
              borderRadius: "10px",
              background:
                "#235E6F",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {t("Upgrade now")}
          </PurplePinkButton>
        </Box>
      </Box>
    </Box>
  );
});

const PopupBanner2 = React.memo(() => {
  const [open, setOpen] = React.useState(true);
  const router = useRouter();
  const { t } = useTranslation("front-page");
  const active = isBannerActive();
  if (!active) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        background: "#111827",
        padding: "12px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          paddingTop: "12px",
          borderRadius: "6px",
          paddingBottom: "12px",
          justifyContent: "space-between",
          columnGap: "48px",
          rowGap: "6px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          background: "#E6E6FA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            columnGap: "24px",
            padding: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "0 24px",
            },
            rowGap: "12px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <ResponsiveText
              fontSize={16}
              sx={{
                color: "text.black",
                marginRight: "auto",
              }}
            >
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                Special Black Friday Deal
              </InlineSpan>
              <InlineSpan inherit>
                {" "}
                – Limited time offer for total savings of{" "}
              </InlineSpan>

              <InlineSpan
                inherit
                sx={{
                  ...graidentTextStyle,
                  fontWeight: "700!important",

                  background:
                    "linear-gradient(to right, #D400BF, rgba(76, 56, 198, 0.85) 143.99%, rgba(8, 8, 8, 0) 100%)",
                }}
              >
                50% {""}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: 400,
                }}
              >
                on Annual Plans. Using the code:{" "}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                JUNIAFRIDAY
              </InlineSpan>
            </ResponsiveText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "48px",
              paddingRight: "24px",
              justifyContent: "space-between",
              rowGap: "12px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <CountDownTimer inFrontPage targetDate={oneWeekAfter} />
          </Box>
          {/*
            <PurplePinkButton
            component={Link}
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              margin: 0,
              padding: "7px 24px",
              borderRadius: "22px",
              background: "#C326FA",
              marginRight: "24px",
              fontSize: "14px",
              fontWeight: 600,
            }}
            href={serverRoutes.pricing}
          >
            {t("Go Unlimited now")}
          </PurplePinkButton>
            */}
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label={t("close")}
          sx={{
            position: "absolute",
            padding: 0,
            border: ".5px solid #FFFFFF",
            opacity: 0.5,
            top: {
              xs: "initial",
              sm: "initial",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            bottom: {
              xs: "10px",
              sm: "10px",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
            right: "10px",
            "&:hover": {
              border: ".5px solid transparent",
            },
          }}
        >
          <CloseOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      </Container>
    </Box>
  );
});

const PopupBanner = React.memo(() => {
  const [open, setOpen] = React.useState(true);
  const router = useRouter();
  const { t } = useTranslation("front-page");
  return <PopupBanner2 />;
  if (router.pathname !== serverRoutes.front) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        background: "#111827",
        padding: "12px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          paddingTop: "12px",
          borderRadius: "6px",
          paddingBottom: "12px",
          justifyContent: "space-between",
          columnGap: "48px",
          rowGap: "6px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          background: {
            xs: "linear-gradient(90.02deg, #AC5ADC 13.24%, #7E51F0 84.62%)",
            sm: "linear-gradient(90.02deg, #AC5ADC 13.24%, #7E51F0 84.62%)",
            md: "linear-gradient(90.02deg, #AC5ADC 13.24%, #7E51F0 84.62%)",
            lg: "linear-gradient(90.02deg, #AC5ADC 13.24%, #C8BAED 49.32%, #7E51F0 84.62%)",
            xl: "linear-gradient(90.02deg, #AC5ADC 13.24%, #C8BAED 49.32%, #7E51F0 84.62%)",
          },
        }}
      >
        <ResponsiveText
          fontSize={18}
          sx={{
            fontWeight: 600,
            color: "text.white",
            marginRight: "auto",
          }}
        >
          {t("what-is-new-popup-title")}
        </ResponsiveText>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            paddingRight: "24px",
            justifyContent: "space-between",
            rowGap: "12px",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <ResponsiveText
            fontSize={14}
            sx={{
              fontWeight: 500,
            }}
          >
            {t("what-is-new-popup-description")}
          </ResponsiveText>
          <SolidCallToAction
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              margin: 0,
              padding: "7px 24px",
              backgroundColor: "#2F143E",
              marginRight: "auto",
            }}
            to={serverRoutes.editor}
          >
            {t("learn-more")}
          </SolidCallToAction>
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label={t("close")}
          sx={{
            position: "absolute",
            padding: 0,
            border: ".5px solid #FFFFFF",
            opacity: 0.5,
            top: {
              xs: "initial",
              sm: "initial",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            bottom: {
              xs: "10px",
              sm: "10px",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
            right: "10px",
            "&:hover": {
              border: ".5px solid transparent",
            },
          }}
        >
          <CloseOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
              fill: "#FFFFFF",
            }}
          />
        </IconButton>
      </Container>
    </Box>
  );
});

export default PopupBanner;
