import React from "react";

import { useOpenPopup } from "context/popup-context";
import { modalPopupEmitter } from "utils/modal-popup-emitter";

export const ModalPopupListener = React.memo(() => {
  const onOpenPopup = useOpenPopup();
  const onOpenModalPopup = React.useCallback((name: string) => {
    onOpenPopup(name);
  }, []);

  React.useEffect(() => {
    modalPopupEmitter.on("open-modal", onOpenModalPopup);
    return () => {
      try {
        modalPopupEmitter.off("open-modal", onOpenModalPopup);
      } catch (e) {}
    };
  }, []);

  return null;
});
