import { useAppDispatch } from "hooks/use-app-dispatch";
import { useLoading } from "hooks/use-loading";
import { useNotifications } from "hooks/use-notification";
import { useSelector } from "hooks/use-selector";
import React from "react";
import { setUserDetails } from "reducers/user";
import {
  selectUserDetails,
  selectUserId,
  selectUserMediumToken,
  selectUserReddit,
} from "selectors/user";
import protectedClientApi from "utils/protected-client-api";

export const usePublishToReddit = () => {
  const loading = useLoading();
  const userId = useSelector(selectUserId);
  const dispatch = useAppDispatch();
  const userDetails = useSelector(selectUserDetails);
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const userReddit = useSelector(selectUserReddit);
  const onSaveReddit = React.useCallback(
    async (config) => {
      if (!userDetails) {
        return;
      }
      if (loading.isLoading) {
        return;
      }
      try {
        loading.onLoading();
        await protectedClientApi.updateUserById(userDetails.id, {
          ...config,
        });
        dispatch(
          setUserDetails({
            ...userDetails,
            ...config,
          })
        );
        loading.onSuccess();
        addSuccessNotification("Reddit credentials saved!");
      } catch (e) {
        addFailureNotification(
          `There's an unknown error saving your reddit credentials, please try again!`
        );
      }
    },
    [userDetails]
  );

  return {
    onSaveReddit,
    userReddit,
    loading,
  };
};
