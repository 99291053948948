import { trackWithLimitedTime } from "./tracker";
import { SourceType } from "./type";

export const trackOpenCustomerPortal = async () => {
  await trackWithLimitedTime("open_customer_portal");
};

export const trackTogglePlanType = async ({
  source,
  register,
}: {
  source: SourceType;
  register: boolean;
}) => {
  await trackWithLimitedTime("toggle_plan_type", {
    source,
    register,
  });
};

export const trackClickOnPricing = async ({
  source,
  register,
}: {
  register: boolean;
  source: SourceType;
}) => {
  await trackWithLimitedTime("click_on_pricing", {
    source,
    register,
  });
};

export const trackClickUpgradeToPro = async ({
  source,
  userId,
}: {
  userId?: string;
  source: SourceType;
}) => {
  await trackWithLimitedTime("clicked_upgrade_to_pro", {
    source,
    userId: userId || "",
  });
};
