import { getStyleProps, passProps } from "modules/themes/styleUtils";
import React from "react";
import MuiStack, { StackProps } from "@mui/material/Stack";

const Stack = React.forwardRef((props: StackProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiStack {...passProps(rest)} ref={ref} {...getStyleProps(rest)}>
      {children}
    </MuiStack>
  );
});
Stack.displayName = "Stack";

export default Stack;
