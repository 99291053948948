export function isMobile() {
  if (typeof navigator === "undefined") {
    return false;
  }
  if (typeof navigator.userAgent === "undefined" || !navigator.userAgent) {
    return false;
  }
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ["android", "iphone", "ipad", "ipod", "windows phone"];
  return mobileKeywords.some((keyword) => userAgent.indexOf(keyword) !== -1);
}
