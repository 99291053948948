import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useCloseSelectedPopup } from "context/popup-context";

import { PurplePinkButton } from "components/purple-button";
import SchedulePublish from "../schedule-publish";

const APIPublishModal = ({ onSave, singleArticle }) => {
  const onClose = useCloseSelectedPopup();

  const [baseURL, setBaseURL] = React.useState("");

  const [schedulePublishConfig, setSchedulePublishConfig] = React.useState({
    enabled: false,
    amount: singleArticle ? 1 : 5,
  });

  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginRight: "24px",
            }}
          >
            Publish To Junia API
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Section>
          <SectionTitle>Base URL</SectionTitle>
          <PrimaryInput
            size="small"
            value={baseURL}
            onChange={(e) => {
              setBaseURL(e.target.value);
            }}
          />
        </Section>
        <SchedulePublish
          schedulePublishConfig={schedulePublishConfig}
          onChange={setSchedulePublishConfig}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PurplePinkButton
            onClick={async () => {
              onSave({
                schedulePublishConfig,
                baseURL,
              });
              onClose();
            }}
          >
            Save
          </PurplePinkButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default APIPublishModal;
