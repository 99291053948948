import { useOpenPopup } from "context/popup-context";
import React from "react";

export const useRateLimitModal = () => {
  const openPopup = useOpenPopup();

  const onRateLimitHit = React.useCallback(() => {
    openPopup("rateLimitModal");
  }, [openPopup]);
  return { onRateLimitHit };
};
