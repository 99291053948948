import React from "react";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useCloseSelectedPopup } from "context/popup-context";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { PurplePinkButton } from "components/purple-button";
import { Section, SectionTitle } from "modules/selections/common";
import { Select } from "components/ui/select";
import { MenuItem } from "components/ui/menu";
import { textEllipsis } from "modules/themes/styleUtils";
import { useLoading } from "hooks/use-loading";
import { PrimaryInput } from "components/input";
import { useSelector } from "hooks/use-selector";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { slugify } from "client-server-shared/utils/text-utils";
import { useNotifications } from "hooks/use-notification";
import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import { getH1 } from "utils/get-h1";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import WebflowConnectModal from "./webflow-connect-modal";
import protectedClientApi from "utils/protected-client-api";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationLayout } from "../layout";

import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { useRefreshCollection } from "hooks/use-load-collection";

const WebflowPublishModal = ({
  postId,
  collectionId,
  connectModalOpen,
  setConnectModalOpen,
  defaultIntegrationId,
  setDefaultIntegrationId,
}: {
  setDefaultIntegrationId: (id: string) => void;
  defaultIntegrationId?: string;
  postId: string;
  collectionId: string;
  setConnectModalOpen: (open: boolean) => void;
  connectModalOpen: boolean;
}) => {
  const { integrations, selectedIntegration, activeIntegrationId } =
    useIntegrationContext();

  const onClosePopup = useCloseSelectedPopup();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const content = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );
  const [errorMesssage, setErrorMessage] = React.useState<string>("");

  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const [config, setConfig] = React.useState({
    status: "Published",
    title: getH1(content) || post?.title,
  });
  const integration = selectedIntegration;
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.WebflowIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();
  const { onRefreshCollection } = useRefreshCollection();
  React.useEffect(() => {
    setErrorMessage("");
  }, [activeIntegrationId]);
  const { onPropertyChanged, onPostMetadataChanged } =
    useEditPostPropertiesById(collectionId, postId);

  React.useLayoutEffect(() => {
    setConnectModalOpen(!integration);
  }, []);
  React.useEffect(() => {
    if (!post.slug) {
      const h1 = getH1(content);
      if (h1) {
        onPropertyChanged("slug", slugify(h1));
      }
    }
  }, []);

  const onConfigChange = React.useCallback((key, value) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);
  const publishing = useLoading();

  const onPublish = async () => {
    if (publishing.isLoading) {
      return;
    }
    try {
      publishing.onLoading();
      const { id } = await protectedClientApi.publishToWebflow({
        ...post,
        integrationId: integration?.id,
        title: config.title,
        status: config.status,
      });

      onPostMetadataChanged("webflow_id")(id);

      publishing.onIdle();
      addSuccessNotification(`Published to Webflow successfully!`);
      onClosePopup();
      await onRefreshCollection(collectionId);
    } catch (e) {
      setErrorMessage(
        `There's an error publishing to Webflow. Please double check your Webflow credentials. If the issue persists, try reconneting to Webflow to see if it helps.`
      );
      publishing.onIdle();
    }
  };

  if (connectModalOpen) {
    return (
      <WebflowConnectModal
        defaultIntegrationId={defaultIntegrationId}
        fullyClose={() => {
          onClosePopup();
        }}
        afterConnect={() => {
          setConnectModalOpen(false);
          setDefaultIntegrationId("");
        }}
        onClose={() => {
          setConnectModalOpen(false);
          setDefaultIntegrationId("");
        }}
      />
    );
  }

  if (!integration) {
    return (
      <WebflowConnectModal
        fullyClose={() => {
          onClosePopup();
        }}
        afterConnect={() => {
          setConnectModalOpen(false);
          setDefaultIntegrationId("");
        }}
        onClose={() => {
          setConnectModalOpen(false);
          setDefaultIntegrationId("");
        }}
      />
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth={integrations.length === 0 ? "sm" : "lg"}
      open
      onClose={() => {
        onClosePopup();
      }}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                marginRight: "24px",
              }}
            >
              Publish To Webflow
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>

          <IconButton
            aria-label={"Close"}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            maxWidth: "400px",
          }}
        >
          You can connect to multiple Webflow websites and choose which site to
          publish to.
        </Description>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          {errorMesssage ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Description>{errorMesssage}</Description>
              <OutlineGreyButton
                onClick={() => {
                  setConnectModalOpen(true);
                  setErrorMessage("");
                }}
              >
                Reconnect To Webflow
              </OutlineGreyButton>
            </Box>
          ) : (
            <>
              <Section>
                <SectionTitle
                  optional
                  info="This is only needed if you're updating the same article that has been published previously"
                >
                  Existing Webflow Article Id
                </SectionTitle>
                <PrimaryInput
                  fullWidth
                  size="small"
                  placeholder="You can find it in the URL of the article"
                  value={post?.postMetadata?.webflow_id || ""}
                  onChange={(e) => {
                    onPostMetadataChanged("webflow_id")(e.target.value);
                  }}
                />
              </Section>
              <Section>
                <SectionTitle>Title</SectionTitle>
                <PrimaryInput
                  fullWidth
                  size="small"
                  placeholder="Enter a Title"
                  value={config.title}
                  onChange={(e) => {
                    onConfigChange("title", e.target.value);
                  }}
                />
              </Section>
              <Section>
                <SectionTitle>Slug</SectionTitle>
                <PrimaryInput
                  fullWidth
                  size="small"
                  placeholder="Enter a Slug"
                  value={post?.slug || ""}
                  onChange={(e) => {
                    onPropertyChanged("slug", e.target.value);
                  }}
                />
              </Section>
              <Section>
                <SectionTitle>Mode</SectionTitle>
                <Select
                  size="small"
                  onChange={(e, newValue) => {
                    onConfigChange("status", e.target.value);
                  }}
                  value={config.status}
                >
                  {["Draft", "Published"].map((status) => {
                    return (
                      <MenuItem key={status} value={status}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...textEllipsis,
                            maxWidth: "100px",
                            fontSize: "12px",
                          }}
                        >
                          {status}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Section>
              {errorMesssage || !selectedIntegration ? null : (
                <Description
                  sx={{
                    marginBottom: "12px",
                  }}
                >
                  This will publish the article to {selectedIntegration.name}
                </Description>
              )}
              {errorMesssage ? null : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PurplePinkButton
                    disabled={!hasIntegrationFeature}
                    loading={publishing.isLoading}
                    onClick={() => {
                      onPublish();
                    }}
                  >
                    Publish
                  </PurplePinkButton>
                </Box>
              )}
            </>
          )}
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};

export const WebflowWebflowPublishModalContainer = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  const [connectModalOpen, setConnectModalOpen] = React.useState(false);
  const [defaultIntegrationId, setDefaultIntegrationId] = React.useState("");
  return (
    <IntegrationContextProvider
      onAddNewIntegration={() => {
        setConnectModalOpen(true);
      }}
      onEdit={(id: string) => {
        setConnectModalOpen(true);

        setDefaultIntegrationId(id);
      }}
      defaultIntegrationId={defaultIntegrationId}
      inPublishMode
      type={IntegrationType.Webflow}
    >
      <WebflowPublishModal
        defaultIntegrationId={defaultIntegrationId}
        setDefaultIntegrationId={setDefaultIntegrationId}
        connectModalOpen={connectModalOpen}
        setConnectModalOpen={setConnectModalOpen}
        postId={postId}
        collectionId={collectionId}
      />
    </IntegrationContextProvider>
  );
};

export default WebflowWebflowPublishModalContainer;
