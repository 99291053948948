import { trackWithLimitedTime } from "./tracker";

export const trackDeleteCollection = async () => {
  await trackWithLimitedTime("delete_collection");
};

export const trackDeletePost = async () => {
  await trackWithLimitedTime("delete_post");
};

export const trackClickOnCollection = async () => {
  await trackWithLimitedTime("click_on_collection");
};

export const trackClickOnPost = async () => {
  await trackWithLimitedTime("click_on_post");
};

export const trackSharePost = async (postId: string) => {
  await trackWithLimitedTime("share_post", {
    id: postId,
  });
};
