import React from "react";

import { Box } from "components/ui/box";
import { Menu, MenuItem } from "components/ui/menu";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";

import { useDashboardTranslation } from "components/translate";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useShortId } from "hooks/use-short-id";
import { WordPressIcon } from "client-server-shared/icons/word-press-icon";
import SelfHostWordPressConnectModal from "./self-host-wordpress-connect";
import { useStartWordpressOAuth } from "hooks/usewordpress";
import { Tooltip } from "components/ui/tooltip";

const ITEM_HEIGHT = 48;

const AddNewWordPressButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useDashboardTranslation();
  const menuId = useShortId();
  const menuId2 = useShortId();

  const [selfHostedOpen, setSelfHostedOpen] = React.useState(false);

  const { onInitAuthorizedProcess } = useStartWordpressOAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "12px",
      }}
    >
      <OutlineGreyButton
        onClick={() => {
          setSelfHostedOpen(true);
          handleClose();
        }}
        startIcon={<AddOutlinedIcon />}
      >
        Connect To More WordPress Sites
      </OutlineGreyButton>

      {selfHostedOpen && (
        <SelfHostWordPressConnectModal
          onClose={() => {
            setSelfHostedOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default AddNewWordPressButton;
