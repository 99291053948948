import React from "react";
import {
  selectUserHasActiveSubscription,
  selectUserInActiveCreatorPlan,
  selectUserInActiveUnlimitedPlan,
  selectUserInActiveProPlan,
} from "selectors/user";
import { useSelector } from "./use-selector";
import { useOpenPopup } from "context/popup-context";
import { useDashboardTranslation } from "components/translate";
import { LimitReachedType } from "client-server-shared/types/limit";

export const useNotEnoughWorkflows = () => {
  const { t } = useDashboardTranslation();
  const onOpenPopup = useOpenPopup();

  const inPaidPlan = useSelector(selectUserHasActiveSubscription);
  const inCreatorPlan = useSelector(selectUserInActiveCreatorPlan);
  const inUnlimitedPlan = useSelector(selectUserInActiveUnlimitedPlan);
  const inProPlan = useSelector(selectUserInActiveProPlan);

  const onNotEnoughWorkflows = ({
    description1,
  }: {
    description1?: string;
  } = {}) => {
    onOpenPopup("creditLimitReached", {
      type: LimitReachedType.wordsLimitReached,
      description1:
        description1 ||
        t("You don't have enough workflow runs for this generation."),
      description2: !inPaidPlan
        ? t("Would you like to unlock more workflow runs with our paid plans?")
        : inCreatorPlan
        ? t("Would you like to unlock more workflow runs with our Pro plan?")
        : t("Would you like to purchase additional workflow runs?"),
      action:
        inUnlimitedPlan || inProPlan
          ? () =>
              onOpenPopup("addonModal", {
                type: "workflows",
              })
          : undefined,
    });
  };

  return { onNotEnoughWorkflows };
};

export const useWordsLimitReachedPrompt = ({
  type,
}: {
  type?: "blog-post-workflow" | "free-plan-regenerate-outline";
} = {}) => {
  const onOpenPopup = useOpenPopup();
  const { t } = useDashboardTranslation();
  const { onNotEnoughWorkflows } = useNotEnoughWorkflows();
  const showPrompt = React.useCallback(() => {
    if (type === "blog-post-workflow") {
      onNotEnoughWorkflows();
    } else if (type === "free-plan-regenerate-outline") {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.wordsLimitReached,
        description1: t(
          "To regenerate this outline, you'll need to wait a few minutes or subscribe to a paid plan."
        ),
        description2:
          "Would you like to unlock more features and benefits with our paid plans?",
      });
    } else {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.wordsLimitReached,
      });
    }
  }, []);
  return {
    showPrompt,
  };
};
export const useImagesLimitReachedPrompt = () => {
  const onOpenPopup = useOpenPopup();
  const showPrompt = React.useCallback(() => {
    onOpenPopup("creditLimitReached", {
      type: LimitReachedType.imagesLimitReached,
    });
  }, []);
  return {
    showPrompt,
  };
};
export const useActivePaidPlanPrompt = () => {
  const userInActivePaidPlan = useSelector(selectUserHasActiveSubscription);

  const onOpenPopup = useOpenPopup();

  const showPrompt = React.useCallback(() => {
    if (!userInActivePaidPlan) {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.requiredPaidPlan,
      });
    }
  }, [userInActivePaidPlan]);

  return {
    userInActivePaidPlan,
    showPrompt,
  };
};
