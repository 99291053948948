import { IconButton } from "components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import pluralize from "pluralize";

import { useCloseSelectedPopup } from "context/popup-context";
import { Box } from "./ui/box";
import { useUser } from "hooks/use-user";
import { Interval, workflowsPerUsd } from "client-server-shared/pricing/v2";
import { trackTogglePlanType } from "utils/tracking/payment";
import { SourceType } from "utils/tracking/type";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "hooks/use-selector";
import { selectUserPaidWords } from "selectors/user";

import Slider from "components/ui/form/slider";
import { useOnetimeCheckout } from "hooks/use-checkout";
import { selectUserDetails, selectUserRemainingWords } from "selectors/user";
import { wordsPerUsd } from "client-server-shared/pricing/v2";
import { useDashboardTranslation } from "./translate";
import { PurpleButton } from "./purple-button";

const CreditsSelection = () => {
  const min = 10000 * 5;
  const [words, setWords] = React.useState(min);
  const onClosePopup = useCloseSelectedPopup();

  const userDetails = useSelector(selectUserDetails);
  const { t } = useDashboardTranslation();
  const userPaidCredits = useSelector(selectUserPaidWords);

  const remainingWords = useSelector(selectUserRemainingWords);
  const { handleOnetimeCheckout, isLoading } = useOnetimeCheckout();
  if (!userDetails) {
    return null;
  }
  return (
    <>
      <DialogTitle
        sx={{
          padding: "24px 24px 12px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginRight: "12px",
                fontSize: "18px",
              }}
            >
              {"Credits"}
            </Typography>
          </Box>
          <IconButton
            aria-label={t("close")}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "48px 72px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "24px 0",
          }}
        >
          <Typography
            variant="body1"
            fontWeight={600}
            sx={{ textAlign: "center" }}
          >
            {words} credits
          </Typography>
          <Slider
            min={min}
            step={2000 * 20}
            max={10000000}
            value={words}
            onChange={(e, value) => {
              setWords(value);
            }}
            valueLabelDisplay="auto"
            aria-label={"Choose number of credits"}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Price:</Typography>
            <Typography>${words / wordsPerUsd}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PurpleButton
            loading={isLoading}
            sx={{
              padding: "6px 24px",
            }}
            onClick={() => {
              handleOnetimeCheckout(words);
            }}
          >
            Check out
          </PurpleButton>
        </Box>
      </DialogContent>
    </>
  );
};

const WorkflowsSelection = () => {
  const min = 16;
  const [workflows, setWorkflows] = React.useState(min);
  const onClosePopup = useCloseSelectedPopup();

  const userDetails = useSelector(selectUserDetails);
  const { t } = useDashboardTranslation();
  const userPaidCredits = useSelector(selectUserPaidWords);

  const remainingWords = useSelector(selectUserRemainingWords);
  const { handleOnetimeCheckout, isLoading } = useOnetimeCheckout();
  if (!userDetails) {
    return null;
  }
  return (
    <>
      <DialogTitle
        sx={{
          padding: "24px 24px 12px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginRight: "12px",
                fontSize: "18px",
              }}
            >
              {t("Workflows")}
            </Typography>
          </Box>
          <IconButton
            aria-label={t("close")}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "48px 72px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "24px 0",
          }}
        >
          <Typography
            variant="body1"
            fontWeight={600}
            sx={{ textAlign: "center" }}
          >
            {workflows} {pluralize(t("workflows"), workflows)}
          </Typography>
          <Slider
            min={min}
            step={12}
            max={300}
            value={workflows}
            onChange={(e, value) => {
              setWorkflows(value);
            }}
            valueLabelDisplay="auto"
            aria-label={t("workflows slider")}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{t("price-section")}</Typography>
            <Typography>${workflows * workflowsPerUsd}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PurpleButton
            loading={isLoading}
            sx={{
              padding: "6px 24px",
            }}
            onClick={() => {
              handleOnetimeCheckout(workflows, "workflows");
            }}
          >
            Check out
          </PurpleButton>
        </Box>
      </DialogContent>
    </>
  );
};

const AddonModal = ({ type }: { type: "credits" | "workflows" }) => {
  const onClosePopup = useCloseSelectedPopup();
  const user = useUser();
  const { t } = useDashboardTranslation();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          background: "#F4F8FB",
          ...(matches
            ? {
                maxHeight: "initial",
                height: "100%",
                width: "100%",
                margin: 0,
                maxWidth: "initial",
                borderRadius: 0,
              }
            : {}),
        },
      }}
      fullWidth
      maxWidth="xs"
      open
      onClose={onClosePopup}
    >
      {type === "credits" && <CreditsSelection />}
      {type === "workflows" && <WorkflowsSelection />}
    </Dialog>
  );
};

export default AddonModal;
