import { authorsAdapter } from "reducers/authors";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reducers/types";

const emptyArry = [];

const authorsSelectors = authorsAdapter.getSelectors();

const selectBaseAuthorsState = (state: RootState) => state.authors;

export const selectAllAuthors = createSelector(
  selectBaseAuthorsState,
  (authorState) => authorsSelectors.selectAll(authorState)
);

const selectAuthorById = createSelector(
  [selectBaseAuthorsState, (state, authorId: string) => authorId],
  (authorState, authorId) => authorsSelectors.selectById(authorState, authorId)
);

export const selectAuthorsByIds = createSelector(
  [selectAllAuthors, (state, authorIds: string[]) => authorIds],
  (allAuthors, authorIds) =>
    authorIds.length === 0
      ? emptyArry
      : allAuthors.filter((author) => authorIds.includes(author.clientId))
);

export const selectAllAuthorIds = createSelector(
  selectBaseAuthorsState,
  (authorState) => authorsSelectors.selectIds(authorState)
);
