import * as React from "react";
import { TextField, TextFieldProps } from "components/ui/form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ButtonWithLoadingProps,
  IconButtonWithLoading,
} from "./ui/button/button-with-loading";
import { Tooltip } from "./ui/tooltip";
import { useCommonTranslation } from "./translate";
import { PrimaryInput } from "./input";
import type { BoxProps } from "./ui/box";

export const Searchbar = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...(props.InputProps || {}),
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export type SearchbarWithButtonProps = {
  onButtonClick?: (
    e: React.MouseEvent | React.KeyboardEvent,
    value?: string
  ) => void;
  loading?: boolean;
  variant?: string;
  icon?: React.ReactNode;
  buttonProps?: ButtonWithLoadingProps;
  iconProps?: BoxProps;
} & TextFieldProps;

export const SearchbarWithButton = (props: SearchbarWithButtonProps) => {
  const {
    icon,
    value,
    loading,
    tooltip,
    onButtonClick,
    buttonProps = {},
    iconProps = {},
    variant,
    ...rest
  } = props;
  const { t } = useCommonTranslation();
  if (variant === "primary") {
    return (
      <PrimaryInput
        size="small"
        value={value}
        onKeyDown={(e) => {
          if (!e.shiftKey) {
            if (e.key === "Enter") {
              e.stopPropagation();
              e.preventDefault();
              if (onButtonClick) {
                onButtonClick(e, value);
              }
            }
          }
        }}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={tooltip || t("Search")}>
                <IconButtonWithLoading
                  loading={loading}
                  onClick={(e) => {
                    if (onButtonClick) {
                      onButtonClick(e, value);
                    }
                  }}
                  {...buttonProps}
                >
                  {icon || (
                    <SearchOutlinedIcon
                      {...iconProps}
                      sx={{
                        height: "17px",
                        width: "17px",
                        ...(iconProps.sx || {}),
                      }}
                    />
                  )}
                </IconButtonWithLoading>
              </Tooltip>
            </InputAdornment>
          ),
          ...(rest.InputProps || {}),
        }}
      />
    );
  }
  return (
    <TextField
      size="small"
      value={value}
      onKeyDown={(e) => {
        if (!e.shiftKey) {
          if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            if (onButtonClick) {
              onButtonClick(e, value);
            }
          }
        }
      }}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={tooltip || t("Search")}>
              <IconButtonWithLoading
                loading={loading}
                onClick={(e) => {
                  if (onButtonClick) {
                    onButtonClick(e, value);
                  }
                }}
                {...buttonProps}
              >
                {icon || <SearchOutlinedIcon {...iconProps} />}
              </IconButtonWithLoading>
            </Tooltip>
          </InputAdornment>
        ),
        ...(rest.InputProps || {}),
      }}
    />
  );
};
