import { postsAdapter } from "reducers/collections/collection";
import { collectionsAdapter } from "reducers/collections/collections";
import type { RootState } from "reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import { isContentIndexInRange, PostState } from "reducers/collections/post";
import { selectAllAuthorIds } from "./authors";
import type {
  Collection,
  PostAnalyticsDetail,
  PostConfig,
} from "client-server-shared/types/types";
import { noTemplateId } from "templates/client-templates/default-template-id";
import { supportedSEOLanguages } from "client-server-shared/constants";
import { defaultAnalytics } from "client-server-shared/utils/seo-report";

const collectionsSelectors = collectionsAdapter.getSelectors();

const collectionSelectors = postsAdapter.getSelectors();

const emptyArray: string[] = [];
const emptyObj = {};
const selectBaseCollectionState = (state: RootState) => state.collections;

export const selectIsCollectionReadyByCollectionId = (
  state: RootState,
  id: string
) => !!collectionsSelectors.selectById(state.collections, id);

export const selectCollectionStateByCollectionId = (
  state: RootState,
  id: string
) =>
  (state.collections &&
    state.collections.entities &&
    collectionsSelectors.selectById(state.collections, id)) ||
  null;

export const selectCollectionDataByCollectionId = createSelector(
  selectCollectionStateByCollectionId,
  (collectionState) => collectionState?.data
);

export const selectCollectionTemplateIdByCollectionId = createSelector(
  selectCollectionDataByCollectionId,
  (collection) => {
    return collection?.templateId;
  }
);

export const selectIsCollectionAutosaveEnabled = createSelector(
  selectCollectionDataByCollectionId,
  (collection) => !!collection?.autoSave
);

const selectCollectionPresentStateById = createSelector(
  selectCollectionStateByCollectionId,
  (state) => state?.current || null
);

const selectAllCollectionState = createSelector(
  selectBaseCollectionState,
  (baseCollectionState) => collectionsSelectors.selectAll(baseCollectionState)
);

export const selectAllCollectionDataWithPosts = createSelector(
  selectAllCollectionState,
  (allCollectionState) => {
    const allSavedCollectionDataWithPosts = allCollectionState.map(
      (collectionState) => {
        const collection = collectionState.data;

        const allPosts = collectionSelectors
          .selectAll(collectionState.current)
          .map((post) => post.data);
        return {
          ...collection,
          posts: allPosts,
        };
      }
    );
    return allSavedCollectionDataWithPosts;
  }
);

export const selectAllSavedPostsActiveContentIndexByCollectionIdAndPostId =
  createSelector(selectAllCollectionState, (allCollectionState) => {
    const indexByPostId: Record<string, number> = {};

    allCollectionState.forEach((collectionState) => {
      collectionSelectors.selectAll(collectionState.current).forEach((post) => {
        indexByPostId[post.data.clientId] = post.selectedContentIndex;
      });
    });
    return indexByPostId;
  });

export const selectAllSavedCollectionIds = createSelector(
  [selectAllCollectionState, (state: RootState, folderId?: string) => folderId],
  (allCollectionState, folderId) => {
    return allCollectionState
      .filter((collectionState) => {
        if (folderId === "no-folder") {
          return collectionState.data.id && !collectionState.data.folder_id;
        }
        if (folderId) {
          return (
            collectionState.data.id &&
            collectionState.data.folder_id === folderId
          );
        }
        return collectionState.data.id;
      })
      .map((collectionState) => {
        const collection = collectionState.data;
        return collection.clientId;
      });
  }
);

export const selectAllSavedCollectionDataWithPosts = createSelector(
  [selectAllCollectionState, (state: RootState, folderId?: string) => folderId],
  (allCollectionState, folderId) => {
    const allSavedCollectionDataWithPosts = allCollectionState
      .filter((collectionState) => {
        if (folderId === "no-folder") {
          return collectionState.data.id && !collectionState.data.folder_id;
        }

        if (folderId) {
          return (
            collectionState.data.id &&
            collectionState.data.folder_id === folderId
          );
        }

        return collectionState.data.id;
      })
      .map((collectionState) => {
        const collection = collectionState.data;

        const allPosts = collectionSelectors
          .selectAll(collectionState.current)
          .filter((postState) => postState.data.id)
          .map((post) => post.data);
        return {
          ...collection,
          posts: allPosts,
        };
      });
    return allSavedCollectionDataWithPosts;
  }
);

export const selectCollectionAllPostIdsByCollectionId = createSelector(
  selectCollectionPresentStateById,
  (presentState) =>
    !presentState
      ? emptyArray
      : (collectionSelectors.selectIds(presentState) as string[])
);

export const selectCollectionIsEmptyByCollectionId = createSelector(
  selectCollectionAllPostIdsByCollectionId,
  (ids) => ids.length === 0
);

export const selectCollectionTitleByCollectionId = createSelector(
  selectCollectionStateByCollectionId,
  (collection) => collection?.data?.title || ""
);

const selectPostStateByCollectionIdAndPostId = createSelector(
  [
    selectCollectionPresentStateById,
    (state: RootState, collectionId: string, postId: string) => postId,
  ],
  (presentState, postId) =>
    (presentState && collectionSelectors.selectById(presentState, postId)) ||
    null
);

export const selectPostByCollectionIdAndPostId = createSelector(
  selectPostStateByCollectionIdAndPostId,
  (postState) => postState?.data
);

export const selectPostTopicByCollectionIdAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => post?.postConfig?.topic
);

export const selectSecondaryKeywordsByCollectionIdAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => {
    return post?.tags;
  }
);

export const selectPostConfigByCollectionIdAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => post?.postConfig || (emptyObj as PostConfig)
);


export const selectRawAnalyticsByCollectionIdAndPostId = createSelector(
  selectPostConfigByCollectionIdAndPostId,
  (config) => {
    return config?.analytics;
  }
);
export const selectAnalyticsByCollectionIdAndPostId = createSelector(
  selectPostConfigByCollectionIdAndPostId,
  (config) => {
    return config?.analytics || defaultAnalytics;
  }
);

const calculateTargetKeywordCount = ({
  currentDensity,
  currentCount,
  competitorDensity,
}: {
  currentCount: number;
  currentDensity: number;
  competitorDensity: number;
}) => {
  const unit = currentDensity / currentCount;
  return Math.round(competitorDensity / unit);
};

export const selectKeywordDensityByCollectionIdAndPostId = createSelector(
  selectAnalyticsByCollectionIdAndPostId,
  (analytics) => {
    const ownDensityDefined =
      typeof analytics.keywordCount === "number" &&
      analytics.keywordCount > 0 &&
      typeof analytics.keywordDensity === "number" &&
      analytics.keywordDensity > 0;
    const competitorsDensityDefined =
      typeof analytics?.competitors?.keywordCount === "number" &&
      analytics?.competitors?.keywordCount > 0 &&
      typeof analytics?.competitors?.keywordDensity === "number" &&
      analytics?.competitors?.keywordDensity > 0;

    let competitorKeywordCount = analytics?.competitors?.keywordCount;
    if (ownDensityDefined && competitorsDensityDefined) {
      competitorKeywordCount = calculateTargetKeywordCount({
        currentCount: analytics?.keywordCount,
        currentDensity: analytics?.keywordDensity,
        competitorDensity: analytics?.competitors?.keywordDensity,
      });
    }
    return {
      keywordDensity: ownDensityDefined ? analytics?.keywordDensity : null,
      keywordCount: ownDensityDefined ? analytics?.keywordCount : null,
      competitor: {
        keywordDensity: competitorsDensityDefined
          ? analytics?.competitors?.keywordDensity
          : null,
        keywordCount: competitorsDensityDefined ? competitorKeywordCount : null,
      },
    };
  }
);

export const selectCompetitorsByCollectionIdAndPostId = createSelector(
  selectAnalyticsByCollectionIdAndPostId,
  (analytics) => {
    return analytics?.competitors;
  }
);

const emptyDetails: PostAnalyticsDetail[] = [];

export const selectSEOScoringLanguageSupported = createSelector(
  selectPostConfigByCollectionIdAndPostId,
  (config) => {
    if (!config.language) {
      return true;
    }
    return supportedSEOLanguages.includes(config.language);
  }
);

export const selectAnalyticsDetailsByCollectionIdAndPostId = createSelector(
  selectAnalyticsByCollectionIdAndPostId,
  (analytics) => {
    if (analytics?.details) {
      const badOnes = [];
      const goodOnes = [];
      const warnings = [];
      const errors = [];
      analytics.details
        .filter((item) => item.hasScore && item.text)
        .forEach((item) => {
          if (item.level === "bad") {
            badOnes.push(item);
          }
          if (item.level === "info") {
            warnings.push(item);
          }
          if (item.level === "good") {
            goodOnes.push(item);
          }
          if (!item.level) {
            errors.push(item);
          }
        });
      return [...badOnes, ...warnings, ...goodOnes, ...errors];
    }
    return emptyDetails;
  }
);

export const selectProminentWordsByCollectionIdAndPostId = createSelector(
  selectAnalyticsByCollectionIdAndPostId,
  (analytics) => {
    return analytics.prominentWords;
  }
);

export const selectTermsByCollectionIdAndPostId = createSelector(
  selectAnalyticsByCollectionIdAndPostId,
  (analytics) => {
    return analytics.terms;
  }
);

export const selectHasTermsByCollectionIdAndPostId = createSelector(
  selectTermsByCollectionIdAndPostId,
  (terms) => {
    return Array.isArray(terms);
  }
);

export const selectPostAuthorIdByCollectionIdAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  selectAllAuthorIds,
  (post, allAuthorIds) => {
    return post?.authorId && allAuthorIds.includes(post.authorId)
      ? post.authorId
      : null;
  }
);

const emptyPosts: PostState["data"][] = [];

export const selectAllPostsDataByCollectionId = createSelector(
  selectCollectionPresentStateById,
  (presentState) => {
    return !presentState
      ? emptyPosts
      : collectionSelectors.selectAll(presentState).map((post) => post.data);
  }
);



export const selectCollectionFolderIdByCollectionId = createSelector(
  selectCollectionDataByCollectionId,
  (collection) => {
    if (!collection) {
      return "";
    }
    return collection.folder_id;
  }
);

export const selectPostsByCollectionIdAndPostIds = createSelector(
  [
    selectAllPostsDataByCollectionId,
    (state: RootState, collectionId: string, postIds: string[]) => postIds,
  ],
  (allPosts, postIds) => {
    return allPosts.filter((post) => postIds.includes(post.clientId));
  }
);

export const selectPostsDataWithUnsavedChangesByCollectionId = createSelector(
  selectCollectionPresentStateById,
  (presentState) => {
    return !presentState
      ? emptyPosts
      : collectionSelectors
          .selectAll(presentState)
          .filter((postState) => postState.unsavedChanges)
          .map((post) => post.data);
  }
);

export const selectContentIndexByCollectionAndPostId = createSelector(
  selectPostStateByCollectionIdAndPostId,
  (postState) => {
    if (!postState) {
      return 0;
    }
    if (
      isContentIndexInRange(
        postState.data.content,
        postState.selectedContentIndex
      )
    ) {
      return postState.selectedContentIndex;
    }
    return 0;
  }
);

export const selectContentBlockCountByCollectionAndPostId = createSelector(
  selectPostStateByCollectionIdAndPostId,
  (postState) => {
    if (!postState) {
      return 1;
    }
    return postState.data?.content?.length || 1;
  }
);

export const selectActiveContentByCollectionAndPostId = createSelector(
  selectContentIndexByCollectionAndPostId,
  selectPostStateByCollectionIdAndPostId,
  (index, postState) => {
    if (!postState) {
      return "";
    }

    return postState.data?.content?.[index]?.value || "";
  }
);

export const selectCollectionMetadata = createSelector(
  selectCollectionDataByCollectionId,
  (data) => {
    return data?.metaData || (emptyObj as Collection["metaData"]);
  }
);

export const selectContentBlockByIndexByCollectionAndPostId = createSelector(
  [
    selectPostStateByCollectionIdAndPostId,
    (state, collectionId, postId, index) => index,
  ],
  (postState, index) => {
    if (!postState) {
      return "";
    }

    return postState.data?.content?.[index]?.value || "";
  }
);

export const selectHasCollectionItselfHasUnsavedChangesById = createSelector(
  selectCollectionStateByCollectionId,
  (collectionState) => {
    return !!collectionState?.unsavedChanges;
  }
);

export const selectAnyPostsInCollectionHaveUnsavedChangesById = createSelector(
  selectCollectionPresentStateById,
  (collectionState) => {
    return !!(
      collectionState &&
      collectionSelectors
        .selectAll(collectionState)
        .some((postState) => !!postState.unsavedChanges)
    );
  }
);

export const selectHasAnyHasUnsavedChangesById = createSelector(
  selectHasCollectionItselfHasUnsavedChangesById,
  selectAnyPostsInCollectionHaveUnsavedChangesById,
  (itselfHasUnsavedChanges, anyPostsHaveUnsavedChanges) =>
    itselfHasUnsavedChanges || anyPostsHaveUnsavedChanges
);

export const selectCollectionServerId = createSelector(
  selectCollectionStateByCollectionId,
  (collectionState) => {
    return (collectionState && collectionState?.data?.id) || "";
  }
);

export const selectIsCollectionEverSavedById = createSelector(
  selectCollectionServerId,
  (serverId) => {
    return !!serverId;
  }
);

export const selectIsPostEverSavedByCollectionAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => {
    return !!(post && post?.id);
  }
);

export const selectIsPostReadyByCollectionAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => !!(post && post?.clientId)
);

export const selectIsSidebarOpenByCollectionAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => {
    return !!(post && post?.generatorOpen);
  }
);

export const selectTemplateIdByCollectionAndPostId = createSelector(
  selectPostByCollectionIdAndPostId,
  (post) => {
    return (post && post?.templateId) || noTemplateId;
  }
);

export const selectHasMore = (state: RootState) =>
  state.collectionExtra.hasMore;
