import React from "react";
import MuiSelect, { SelectProps } from "@mui/material/Select";
import { getStyleProps, passProps } from "modules/themes/styleUtils";

const emptyObject = {};
export const Select = React.forwardRef((props: SelectProps, ref) => {
  const { children, ...rest } = props;
  const overrideStyle = getStyleProps(rest);
  const fieldsetStyle = overrideStyle["& fieldset"] || emptyObject;
  const styles = {
    fontSize: "12px",
    ...overrideStyle.sx,
    "& fieldset": {
      borderRadius: "10px",
      borderColor: "rgb(229 231 235)",
      ...fieldsetStyle,
    },
    "&:hover": {
      ...(overrideStyle["&:hover"] || emptyObject),
      "& fieldset": {
        borderColor: "rgb(180 182 187) !important",
        ...(fieldsetStyle["&:hover"] || emptyObject),
      },
    },
  };
  return (
    <MuiSelect ref={ref} {...passProps(rest)} sx={styles}>
      {children}
    </MuiSelect>
  );
});

Select.displayName = "Select";
