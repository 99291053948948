import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useCloseSelectedPopup, useOpenPopup } from "context/popup-context";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { PurplePinkButton } from "components/purple-button";
import { Section, SectionTitle } from "modules/selections/common";
import { Select } from "components/ui/select";
import { MenuItem } from "components/ui/menu";
import { textEllipsis } from "modules/themes/styleUtils";
import { useLoading } from "hooks/use-loading";
import { apiClient } from "utils/client-axios";
import { PrimaryInput } from "components/input";
import { CircularProgress } from "components/ui/loading";
import { IntegrationServer, Post } from "client-server-shared/types/types";
import { useSelector } from "hooks/use-selector";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { slugify } from "client-server-shared/utils/text-utils";
import { useNotifications } from "hooks/use-notification";
import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import { getH1 } from "utils/get-h1";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { getRequestErrorObject } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";
import { IntegrationLayout } from "../layout";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import SchedulePublish from "../schedule-publish";
import { SchedulePublishConfig } from "client-server-shared/types/workflow";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import {
  selectIntegrationsByType,
  selectUserInActiveProPlan,
} from "selectors/user";
import { ShopifyConnectModal } from "./shopify-connect-modal";
import protectedClientApi from "utils/protected-client-api";
import { useRefreshCollection } from "hooks/use-load-collection";
import { LimitReachModalPlain } from "components/limit-reached-modal/limit-reached-modal";

const ShopifyModal = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  const onClosePopup = useCloseSelectedPopup();
  const { selectedIntegration, onCustomEditPost } = useIntegrationContext();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const content = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );
  const { onRefreshCollection } = useRefreshCollection();
  const [blogs, setBlogs] = React.useState<{ title: string; id: string }[]>([]);
  const [authors, setAuthors] = React.useState<string[]>([]);

  const [errorMesssage, setErrorMessage] = React.useState<string>("");
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const [config, setConfig] = React.useState({
    status: "Published",
    title: post?.title,
  });

  const integrationIdRef = React.useRef<string | null>(null);

  React.useEffect(() => {
    if (selectedIntegration) {
      integrationIdRef.current = selectedIntegration.id;
    }
  }, [selectedIntegration?.id]);

  const openPopup = useOpenPopup();
  const [schedulePublishConfig, setSchedulePublishConfig] =
    React.useState<SchedulePublishConfig>({
      enabled: false,
      amount: 1,
    });
  const { onPropertyChanged, onPostMetadataChanged } =
    useEditPostPropertiesById(collectionId, postId);

  React.useEffect(() => {
    if (!post.slug) {
      const h1 = getH1(content);
      if (h1) {
        onPropertyChanged("slug", slugify(h1));
      }
    }
  }, []);

  const onConfigChange = React.useCallback((key, value) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);
  const gettingBlogs = useLoading();
  const publishing = useLoading();

  const { onUpgrade } = useClickUpgrade({
    source: SourceType.ShopifyIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();

  const onPublish = async () => {
    if (!selectedIntegration) {
      return;
    }
    if (publishing.isLoading) {
      return;
    }
    try {
      publishing.onLoading();
      const res = (
        await apiClient.post("/api/integrations/publish", {
          blogId: config.blog,
          author: config.author,
          schedulePublishConfig,
          integrationId: selectedIntegration.id,
          postId: post.clientId,
          status: config.status,
          type: IntegrationType.Shopify,
        })
      ).data.data;

      if (!schedulePublishConfig?.publishedDate) {
        const { id } = res;
        onPostMetadataChanged("shopify_id")(id);
        addSuccessNotification(`Published to Shopify successfully!`);
      } else {
        addSuccessNotification("Successfully scheduled to publish to Shopify!");
      }

      publishing.onIdle();
      onClosePopup();
      await onRefreshCollection(collectionId);
    } catch (e) {
      const errorJSON = getRequestErrorObject(e);
      let message = `There's an error publishing to Shopify. Please double check your Shopify credentials or blog, author and make sure the slug is unique. If the issue persists, try reconneting to shopify to see if it helps.`;
      if (errorJSON?.code === ErrorCode.InvalidShopifyURLError) {
        message = `Your Shopify URL is invalid, please double-check your Shopify store URL and try reconnecting to Shopify.`;
      } else if (errorJSON?.code === ErrorCode.InvalidShopifyCredentialsError) {
        message = `Your Shopify credentials is invalid, please double-check your Shopify access token and secret key and try reconnecting to Shopify.`;
      } else if (errorJSON?.code === ErrorCode.InvalidShopifyPermissionsError) {
        message = `Your Shopify credentials does not have the necessary permissions to access your Shopify data. Please double-check your Shopify permissions and try reconnecting to Shopify.`;
      }

      setErrorMessage(message);
      publishing.onIdle();
    }
  };

  const getBlogData = async () => {
    if (!selectedIntegration) {
      return;
    }

    const lastIntegrationId = integrationIdRef.current;
    setErrorMessage("");
    gettingBlogs.onLoading();
    await protectedClientApi
      .getShopifyCommonData(selectedIntegration.id)
      .then((res) => {
        if (lastIntegrationId !== integrationIdRef.current) {
          return;
        }
        const data = res;
        const allBlogs = data.blogs;
        const allAuthors = data.authors;
        setBlogs(allBlogs);
        setAuthors(allAuthors);
        if (allBlogs.length > 0) {
          onConfigChange("blog", allBlogs[0].id);
        }
        if (allAuthors.length > 0) {
          onConfigChange("author", allAuthors[0]);
        }
        gettingBlogs.onIdle();
      })
      .catch((e) => {
        if (lastIntegrationId !== integrationIdRef.current) {
          return;
        }
        console.log("error", e);
        const errorJSON = getRequestErrorObject(e);
        let message = `There's an error getting your Shopify data. Please double check your Shopify credentials. If the issue persists, try reconneting to shopify to see if it helps.`;
        if (errorJSON?.code === ErrorCode.InvalidShopifyURLError) {
          message = `Your Shopify URL is invalid, please double-check your Shopify store URL and try reconnecting to Shopify.`;
        } else if (
          errorJSON?.code === ErrorCode.InvalidShopifyCredentialsError
        ) {
          message = `Your Shopify credentials is invalid, please double-check your Shopify access token and secret key and try reconnecting to Shopify.`;
        } else if (
          errorJSON?.code === ErrorCode.InvalidShopifyPermissionsError
        ) {
          message = `Your Shopify credentials does not have the necessary permissions to access your Shopify data. Please double-check your Shopify permissions and try reconnecting to Shopify.`;
        }
        setErrorMessage(message);

        gettingBlogs.onIdle();
      });
  };

  React.useEffect(() => {
    getBlogData();
  }, [
    selectedIntegration?.id,
    selectedIntegration?.shopifyAccessToken,
    selectedIntegration?.shopifyStoreName,
    selectedIntegration?.shopifySecretKey,
  ]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={() => {
        onClosePopup();
      }}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                marginRight: "24px",
              }}
            >
              Publish To Shopify
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>
          <IconButton
            aria-label={"Close"}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          {" "}
          {gettingBlogs.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={32} />
            </Box>
          ) : errorMesssage ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Description>{errorMesssage}</Description>
              <OutlineGreyButton
                onClick={() => {
                  onCustomEditPost(selectedIntegration);
                  /*
                  openPopup("shopifyConnectModal", {
                    afterConnect: () => {
                      getBlogData();
                    },
                  });
                  */
                }}
              >
                Reconnect To Shopify
              </OutlineGreyButton>
            </Box>
          ) : (
            <>
              <Section>
                <SectionTitle
                  optional
                  info="This is only needed if you're updating the same article that has been published previously"
                >
                  Existing Shopify Article Id
                </SectionTitle>
                <PrimaryInput
                  fullWidth
                  size="small"
                  placeholder="You can find it in the URL of the article"
                  value={post?.postMetadata?.shopify_id || ""}
                  onChange={(e) => {
                    onPostMetadataChanged("shopify_id")(e.target.value);
                  }}
                />
              </Section>
              <Section>
                <SectionTitle>Select a Blog</SectionTitle>

                <Box
                  sx={{
                    "& .MuiInputBase-root": {
                      width: "100%",
                    },
                  }}
                >
                  <Select
                    size="small"
                    onChange={(e, newValue) => {
                      onConfigChange("blog", e.target.value);
                    }}
                    value={config.blog}
                  >
                    {blogs.map((blog) => {
                      return (
                        <MenuItem key={blog.id} value={blog.id}>
                          <Typography
                            variant="body2"
                            sx={{
                              ...textEllipsis,
                              maxWidth: "100px",
                              fontSize: "12px",
                            }}
                          >
                            {blog.title}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Section>
              <Section>
                <SectionTitle>Select An Author</SectionTitle>{" "}
                <Box
                  sx={{
                    "& .MuiInputBase-root": {
                      width: "100%",
                    },
                  }}
                >
                  <Select
                    size="small"
                    onChange={(e, newValue) => {
                      onConfigChange("author", e.target.value);
                    }}
                    value={config.author}
                  >
                    {authors.map((author) => {
                      return (
                        <MenuItem key={author} value={author}>
                          <Typography
                            variant="body2"
                            sx={{
                              ...textEllipsis,
                              maxWidth: "100px",
                              fontSize: "12px",
                            }}
                          >
                            {author}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Section>
              <Section>
                <SectionTitle
                  info={`Please make sure this slug is unique among the articles in your Shopify store.`}
                >
                  Slug
                </SectionTitle>
                <PrimaryInput
                  fullWidth
                  size="small"
                  placeholder="Enter a Slug"
                  value={post?.slug || ""}
                  onChange={(e) => {
                    onPropertyChanged("slug", e.target.value);
                  }}
                />
              </Section>
              <Section>
                <SectionTitle>Mode</SectionTitle>
                <Select
                  size="small"
                  onChange={(e, newValue) => {
                    onConfigChange("status", e.target.value);
                  }}
                  value={config.status}
                >
                  {["Draft", "Published"].map((status) => {
                    return (
                      <MenuItem key={status} value={status}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...textEllipsis,
                            maxWidth: "100px",
                            fontSize: "12px",
                          }}
                        >
                          {status}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Section>
              <SchedulePublish
                singleArticle
                disableIndexer
                schedulePublishConfig={schedulePublishConfig}
                onChange={setSchedulePublishConfig}
              />
              {errorMesssage ? null : (
                <PurplePinkButton
                  sx={{
                    margin: "auto",
                  }}
                  disabled={!hasIntegrationFeature || !config.blog}
                  loading={publishing.isLoading}
                  onClick={() => {
                    onPublish();
                  }}
                >
                  Publish
                </PurplePinkButton>
              )}
            </>
          )}
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};
const ShopifyConnectModalInnerContainer = ({ afterConnect, onClose }) => {
  const { selectedIntegration } = useIntegrationContext();

  return (
    <ShopifyConnectModal
      selectedIntegration={selectedIntegration}
      onClose={onClose}
      afterConnect={afterConnect}
    />
  );
};

const ShopifyModalContainer = ({ postId, collectionId }) => {
  const onClosePopup = useCloseSelectedPopup();
  const integrations = useSelector((state) =>
    selectIntegrationsByType(state, IntegrationType.Shopify)
  );
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<IntegrationServer | null>(null);
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  const userInProPlan = useSelector(selectUserInActiveProPlan);
  if (!userInProPlan) {
    return (
      <LimitReachModalPlain
        onClosePopup={onClosePopup}
        title="Upgrade To Unlock"
        description1="You need to be on a Premium plan to use this feature."
        description2="Ready to unlock more features and benefits with our paid plans?"
        actionText="Upgrade"
      />
    );
  }
  return (
    <>
      <IntegrationContextProvider
        inPublishMode
        onAddNewIntegration={() => {
          setShowConnectModal(true);
        }}
        onCustomEditPost={(integration) => {
          setSelectedIntegration(integration);
        }}
        type={IntegrationType.Shopify}
      >
        {showConnectModal && (
          <ShopifyConnectModal
            onClose={() => {
              setShowConnectModal(false);
            }}
            afterConnect={() => {
              setShowConnectModal(false);
            }}
          />
        )}
        {integrations.length === 0 ? (
          <ShopifyConnectModal
            onClose={() => {
              onClosePopup();
            }}
          />
        ) : (
          <ShopifyModal postId={postId} collectionId={collectionId} />
        )}
        {selectedIntegration && (
          <ShopifyConnectModal
            selectedIntegration={selectedIntegration}
            onClose={() => {
              setSelectedIntegration(null);
            }}
            afterConnect={() => {
              setSelectedIntegration(null);
            }}
          />
        )}
      </IntegrationContextProvider>
    </>
  );
};

export default ShopifyModalContainer;
