export enum SourceType {
  Dashboard = "dashboard",
  WebsiteIntegration = "website_integration",
  NavBar = "nav_bar",
  QuickAction = "quick_action",
  QuickGenerate = "quick_generate",
  BuyWorkFlows = "buy_workflows",
  CollectionPage = "collection_page",
  EditorTemplatePopup = "editor_template_popup",
  TemplatesPage = "templates_page",
  ChatPage = "chat_page",
  AIImage = "ai_image",
  EditorPage = "editor_page",
  EditorHowToPopup = "editor_how_to_popup",
  EditorTopbar = "editor_topbar",
  Workflow = "Workflow",
  ImageRestorer = "image_restorer",
  BillingPage = "billing_page",
  SettingsPage = "settings_page",
  PricingPage = "pricing_page",
  EditorPageSideTab = "editor_page_side_tab",
  EditorPageSideBar = "editor_page_side_bar",
  EditorAIDetector = "editor_ai_detector",
  EditorCitation = "editor_citation",
  StyleMatch = "style_match",
  LimitReachedPopup = "limit_reached_popup",
  UpgradePopup = "upgrade_popup",
  EditorInlineToolbar = "editor_inline_toolbar",
  ImproveSEOModal = "improve_seo_modal",
  SEOOptimizer = "seo_optimizer",
  DocumentsPage = "documents_page",
  WordPressIntegrationModal = "wordpress_integration_modal",
  WebflowIntegrationModal = "webflow_integration_modal",
  MediumIntegrationModal = "medium_integration_modal",
  ShopifyIntegrationModal = "shopify_integration_modal",
  RedditIntegrationModal = "reddit_integration_modal",
  Team = "team",
  NoTeamFeatureModal = "no_team_feature_modal",
}

export enum Components {
  EditorHowToUseVideo = "editor_how_to_use_video",
}
