import React from "react";

import { useDashboardTranslation } from "../translate";
import { useCloseSelectedPopup } from "context/popup-context";
import { useSelector } from "hooks/use-selector";
import { selectUserHasActiveSubscription } from "selectors/user";
import RatelimitModal from "./rate-limit-modal";

const RatelimitModalContainer = () => {
  const { t } = useDashboardTranslation();
  const onClosePopup = useCloseSelectedPopup();
  const userInPaidPlan = useSelector(selectUserHasActiveSubscription);
  let title = "Oops! 🚀 You're generating too fast.";

  let description =
    "You've hit our speed limit. Please slow down and try again in a few seconds for a smoother experience. Thanks for your understanding!";

  if (!userInPaidPlan) {
    description =
      "You've hit our speed limit. To increase your limit, consider upgrading your plan. Thanks for your understanding!";
  }

  return (
    <RatelimitModal
      title={title}
      onClosePopup={onClosePopup}
      description={description}
      buttonText={t("Ok")}
    />
  );
};

export default RatelimitModalContainer;
