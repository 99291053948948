import { Dialog } from "components/ui/dialog";
import React from "react";

import { useCloseSelectedPopup } from "context/popup-context";

const Popup = ({ children, PaperProps = {}, sx = {}, ...rest }) => {
  const onClosePopup = useCloseSelectedPopup();

  return (
    <Dialog
      {...rest}
      sx={{
        ...sx,
      }}
      PaperProps={PaperProps}
      fullWidth
      maxWidth="md"
      open
      onClose={onClosePopup}
    >
      {children}
    </Dialog>
  );
};

export default Popup;
