import { IntegrationType } from "client-server-shared/constants";
import {
  IntegrationClient,
  IntegrationServer,
} from "client-server-shared/types/types";
import { pick } from "client-server-shared/utils/lodash-methods";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useSelector } from "hooks/use-selector";
import React from "react";
import { integrationAdded, integrationModified } from "reducers/user";
import { selectIntegrationsByType } from "selectors/user";
import protectedClientApi from "utils/protected-client-api";
import isEqual from "lodash/isEqual";
interface IntegrationContextProps {
  activeIntegrationId: string;
  selectedIntegration?: IntegrationServer | null;
  integrations: IntegrationServer[];
  setActiveIntegrationId: (id: string) => void;
  updateIntegration: (
    params: Partial<IntegrationClient>
  ) => Promise<IntegrationServer>;
  inPublishMode?: boolean;
  onAddNewIntegration?: () => void;
  onEdit?: (id: string) => void;
  type: IntegrationType;
  disableAdd?: boolean;
  disableEdit?: boolean;
}

const IntegrationContext = React.createContext<IntegrationContextProps | null>(
  null
);

export const useIntegrationContext = () => {
  return React.useContext(IntegrationContext)!;
};

export const IntegrationContextProvider = ({
  children,
  type,
  inPublishMode,
  onAddNewIntegration,
  defaultIntegrationId,
  onEdit,
  onCustomEditPost,
  disableAdd,
  disableEdit,
}: {
  onCustomEditPost?: (integration: IntegrationServer) => void;
  onEdit?: (id: string) => void;
  defaultIntegrationId?: string;
  onAddNewIntegration?: () => void;
  inPublishMode?: boolean;
  children: React.ReactNode;
  type: IntegrationType;
  disableAdd?: boolean;
  disableEdit?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const integrations = useSelector((state) =>
    selectIntegrationsByType(state, type)
  );
  const [activeIntegrationId, setActiveIntegrationId] = React.useState(
    defaultIntegrationId &&
      integrations.find((i) => i.id === defaultIntegrationId)
      ? defaultIntegrationId
      : inPublishMode && integrations.length > 0
      ? integrations[0].id
      : ""
  );
  const selectedIntegration = React.useMemo(() => {
    return activeIntegrationId
      ? integrations.find((i) => i.id === activeIntegrationId)
      : null;
  }, [activeIntegrationId, integrations]);

  React.useEffect(() => {
    if (!activeIntegrationId && integrations.length > 0 && inPublishMode) {
      setActiveIntegrationId(integrations[0].id);
    }
  }, [inPublishMode, integrations.length, activeIntegrationId]);

  const updateIntegration = React.useCallback(
    async (params: Partial<IntegrationClient>) => {
      if (selectedIntegration) {
        const keys = Object.keys(params);
        const hasIntegrationChange = !isEqual(
          pick(selectedIntegration, keys),
          params
        );

        if (!hasIntegrationChange) {
          return selectedIntegration;
        }

        const updated = await protectedClientApi.updateIntegration(
          selectedIntegration.id,
          { ...params, type }
        );
        dispatch(integrationModified(updated));
        return updated;
      } else {
        const created = await protectedClientApi.saveIntegration({
          ...params,
          type,
        });
        dispatch(integrationAdded(created));
        return created;
      }
    },
    [dispatch, selectedIntegration, type]
  );

  return (
    <IntegrationContext.Provider
      value={{
        type,
        onEdit,
        onCustomEditPost,
        onAddNewIntegration,
        setActiveIntegrationId,
        selectedIntegration,
        activeIntegrationId,
        integrations,
        updateIntegration,
        inPublishMode,
        disableAdd,
        disableEdit,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};
