import {
  collectionSavedById,
  editPostPropertiesByCollectionIdAndPostId,
  isUserEditCollectionOrPostsAction,
  saveCollectionById,
  toggleCollectionAutoSave,
} from "reducers/collections/actions";
import {
  selectIsCollectionBeingSavedById,
  getCollectionSaveIdPrefix,
} from "selectors/processing-log";
import { Middleware } from "./listenerMiddleware";
import { processingLogUtils } from "hooks/use-loading";
import collectionClientApi from "utils/protected-client-api";
import {
  selectCollectionDataByCollectionId,
  selectHasAnyHasUnsavedChangesById,
  selectIsCollectionAutosaveEnabled,
  selectPostsDataWithUnsavedChangesByCollectionId,
} from "selectors/collections";
import {
  creatableCollectionFieldsInClient,
  creatablePostFieldsInClient,
  PostStatus,
  SavedCollection,
  updatableCollectionFieldsInClient,
  updatablePostFieldsInClient,
} from "client-server-shared/types/types";
import { batch } from "react-redux";
import { AppDispatch, AppThunk, RootState } from "reducers/types";
import { AnyAction } from "@reduxjs/toolkit";
import { isPlainObject, pick } from "client-server-shared/utils/lodash-methods";
import { captureException } from "utils/error-catching/lazy-sentry";
import { dayjs } from "client-server-shared/utils/dayjs";
import { defaultPostName } from "client-server-shared/constants";

const sanitizeValue = <T extends Record<string, any>>(value: T): T => {
  return value;
  // return omitBy(value, isNil) as T;
};

const onSaveCollections = async (
  action: AnyAction,
  dispatch: AppDispatch,
  state: RootState,
  force?: boolean
) => {
  const { collectionId } = action.payload;

  const hasUnsavedChanges = selectHasAnyHasUnsavedChangesById(
    state,
    collectionId
  );

  if (!hasUnsavedChanges && !force) {
    return;
  }

  const isAlreadyBeingSaved = selectIsCollectionBeingSavedById(
    state,
    collectionId
  );
  if (isAlreadyBeingSaved && !force) {
    return;
  }

  const collection = selectCollectionDataByCollectionId(state, collectionId);

  if (!collection) {
    return;
  }

  const { addLog, onSuccess, onError } = processingLogUtils(
    getCollectionSaveIdPrefix(collectionId)
  )(dispatch);

  try {
    const allPostsData = selectPostsDataWithUnsavedChangesByCollectionId(
      state,
      collectionId
    );

    const updatableCollection = sanitizeValue(
      collection.id
        ? pick(collection, updatableCollectionFieldsInClient)
        : pick(collection, creatableCollectionFieldsInClient)
    );
    const updatablePosts = allPostsData
      .map((post) => ({
        ...sanitizeValue(
          post.id
            ? pick(post, updatablePostFieldsInClient)
            : pick(post, creatablePostFieldsInClient)
        ),
        title: post.title || defaultPostName,
        updatedAt: dayjs().toISOString(),
      }))
      .filter(
        (post) =>
          post.status !== PostStatus.Idle && post.status !== PostStatus.Loading
      );

    addLog();

    let savedCollection: SavedCollection;

    if (collection.id) {
      savedCollection = await collectionClientApi.updateCollectionById(
        collection.id,
        {
          ...updatableCollection,
          updatedAt: dayjs().toISOString(),
          posts: updatablePosts,
        }
      );
    } else {
      savedCollection = await collectionClientApi.saveCollectionById({
        ...updatableCollection,
        posts: updatablePosts,
      });
    }

    batch(() => {
      dispatch(
        collectionSavedById({
          collectionId: collection.clientId,
          collection: savedCollection,
        })
      );
      onSuccess();
    });
  } catch (e) {
    captureException(e);
    onError();
  }
};

let timer: NodeJS.Timeout | undefined = undefined;

export const autoSaveCollectionMiddleware = (): Middleware => {
  return {
    predicate: (action, currentState, previousState) => {
      return true;
    },
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();
      const shouldRun =
        isUserEditCollectionOrPostsAction(action) ||
        toggleCollectionAutoSave.match(action);
      if (!isPlainObject(action.payload) || !action.payload.collectionId) {
        return;
      }
      if (
        !selectIsCollectionAutosaveEnabled(state, action.payload.collectionId)
      ) {
        return;
      }

      if (!shouldRun) {
        return;
      }

      clearTimeout(timer);
      if (isPlainObject(action.payload) && action.payload.collectionId) {
        try {
          timer = setTimeout(async () => {
            try {
              listenerApi.dispatch(
                saveCollectionById({
                  collectionId: action.payload.collectionId,
                })
              );
            } catch (e) {
              captureException(e);
            }
          }, 2000);
        } catch (e) {
          captureException(e);
        }
      }
    },
  };
};

export const saveCollectionMiddleware = (): Middleware => {
  return {
    type: saveCollectionById.type,
    effect: async (action, listenerApi) => {
      let canceled = true;
      if (isPlainObject(action.payload) && action.payload.force) {
        canceled = false;
      }
      if (canceled) {
        listenerApi.cancelActiveListeners();
      }
      const state = listenerApi.getState();
      await onSaveCollections(action, listenerApi.dispatch, state);
    },
  };
};

export const saveCollectionByIdThunk = (
  collectionId: string,
  functions?: {
    beforeSuccess?: () => void;
    afterSuccess?: () => void;
    onError?: () => void;
  }
): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const action = saveCollectionById({
      collectionId,
      force: true,
    });
    if (functions?.beforeSuccess) {
      functions.beforeSuccess();
    }
    await onSaveCollections(action, dispatch, state, true).catch((e) => {
      if (functions?.onError) {
        functions.onError();
      }
      throw e;
    });
    if (functions?.afterSuccess) {
      functions.afterSuccess();
    }
  };
};
