import { getStyleProps, passProps } from "modules/themes/styleUtils";
import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import React from "react";

export const FormControlLabel = React.forwardRef(
  (props: FormControlLabelProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiFormControlLabel
        {...passProps(rest)}
        {...getStyleProps(rest)}
        ref={ref}
      >
        {children}
      </MuiFormControlLabel>
    );
  }
);
