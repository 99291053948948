import collectionClientApi from "utils/protected-client-api";
import React from "react";
import { useAppDispatch } from "./use-app-dispatch";
import { captureException } from "utils/error-catching/lazy-sentry";
import { setPrompts, setSourceFiles, setStyles } from "reducers/settings";
import dynamic from "next/dynamic";
import { useLoading } from "./use-loading";

import { TooManyRequestListener } from "components/too-many-request";
import { setIntegrations } from "reducers/user";
import { ModalPopupListener } from "components/modal-popup-listerner";
import NoTeamFeatureModal from "components/no-team-feature";

const Preloader = dynamic(() => import("modules/lazy/dynamic-loader"), {
  ssr: false,
});

export const useLoadIntegrations = () => {
  const dispatch = useAppDispatch();

  const getIntegrations = async () => {
    try {
      const data = await collectionClientApi.getIntegrations();
      dispatch(setIntegrations(data));
    } catch (e) {
      captureException(e);
    }
  };
  return { getIntegrations };
};

const useDeferrableInitialData = () => {
  const dispatch = useAppDispatch();
  const { isSuccess, onSuccess } = useLoading();
  const { getIntegrations } = useLoadIntegrations();
  const getCommonData = async () => {
    try {
      const data = await collectionClientApi.getCommonData();
      const { styles, files, prompts } = data;
      dispatch(setStyles(styles));
      dispatch(setPrompts(prompts));
      dispatch(setSourceFiles(files));
    } catch (e) {
      captureException(e);
    }
  };

  const getInitialData = async () => {
    await Promise.all([getCommonData(), getIntegrations()]);
    onSuccess();
  };

  React.useEffect(() => {
    getInitialData();
  }, []);
  return { isSuccess };
};

export const DeferrableInitialDataFetcher = () => {
  const { isSuccess } = useDeferrableInitialData();

  return isSuccess ? (
    <>
      <TooManyRequestListener />
      <ModalPopupListener />
      <Preloader />
      <NoTeamFeatureModal />
    </>
  ) : (
    <>
      <TooManyRequestListener />
      <ModalPopupListener />
      <NoTeamFeatureModal />
    </>
  );
};
