import {
  oneTimeCreditPurchase,
  oneTimeWorkflowPurchase,
  subscriptionPriceIds,
} from "client-server-shared/pricing/v2";
import {
  buildFromParams,
  priceIdParams,
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import { useRouter } from "next/router";
import React from "react";
import useSearchParam from "external/react-use/useSearchParam";
import getStripe from "utils/get-stripe";
import {
  selectSession,
  selectUserAppSumoInvoiceId,
  selectUserHasActiveSubscription,
  selectUserInAppSumoPlan,
  selectUserIsTeamOwner,
} from "selectors/user";
import { getCurrentFullPath } from "client-server-shared/utils/get-url";
import protectedClientApi from "utils/protected-client-api";
import { useAppDispatch } from "./use-app-dispatch";
import { useSelector } from "./use-selector";
import { useLoading } from "./use-loading";
import { useMounted } from "./use-mounted";
import { useNotifications } from "./use-notification";
import { captureException } from "utils/error-catching/lazy-sentry";
import { trackOpenCustomerPortal } from "utils/tracking/payment";
import { getMobileActions, inMobileApp } from "utils/is-in-mobile-app";
import { onEmitModalPopup } from "utils/modal-popup-emitter";

function getClientReferenceId() {
  return (
    ((window as any).Rewardful && (window as any).Rewardful.referral) ||
    "checkout_" + new Date().getTime()
  );
}

export const useRedirectUserToAppSumoManagePlan = () => {
  const isUserInAppSumoPlan = useSelector(selectUserInAppSumoPlan);
  const invoiceId = useSelector(selectUserAppSumoInvoiceId);
  const getRedirectUrl = React.useCallback(() => {
    if (isUserInAppSumoPlan) {
      if (invoiceId) {
        return `https://appsumo.com/account/redemption/${invoiceId}#change-plan`;
      }
    }
    return `https://appsumo.com/account/products/`;
  }, [isUserInAppSumoPlan, invoiceId]);
  return { getRedirectUrl };
};

export const useStripPortal = () => {
  const user = useSelector(selectSession)?.user;
  const { addFailureNotification } = useNotifications();
  const userIsTeamOwner = useSelector(selectUserIsTeamOwner);
  const { isLoading, onLoading, onSuccess, onError } = useLoading();
  const { safeRun } = useMounted();

  const redirectUserToStripeCustomerProtal = async () => {
    trackOpenCustomerPortal();
    if (!user) {
      return;
    }
    if (isLoading) {
      return;
    }
    if (!userIsTeamOwner) {
      onEmitModalPopup("noBillingRightModal");
      return;
    }
    try {
      onLoading();
      const { url } = await protectedClientApi.redirectToCustomerProtal(
        getCurrentFullPath()
      );
      safeRun(() => {
        onSuccess();
      });
      if (inMobileApp()) {
        const mobileActions = getMobileActions();
        if (mobileActions) {
          mobileActions.openBrowserUrl(url);
          return;
        }
      }
      window.location.assign(url);
    } catch (e) {
      console.log("error", e);
      onError();
      captureException(e);
      addFailureNotification(
        "There's an error with Junia, please the support team"
      );
    }
  };
  return {
    isLoading,
    redirectUserToStripeCustomerProtal,
  };
};

export const useCheckout = () => {
  const router = useRouter();
  const user = useSelector(selectSession)?.user;
  const dispatch = useAppDispatch();
  const userHasActivePlan = useSelector(selectUserHasActiveSubscription);
  const { addFailureNotification } = useNotifications();
  const { isLoading, onLoading, onSuccess, onError } = useLoading();
  const { safeRun } = useMounted();
  const userIsTeamOwner = useSelector(selectUserIsTeamOwner);

  const { redirectUserToStripeCustomerProtal } = useStripPortal();

  const handleCheckout = async (
    priceId: string,
    options = {
      paidTrial: false,
    }
  ) => {
    if (!user) {
      router.push(
        `${serverRoutes.login}?${buildFromParams(
          dashboardRouteConfig.dashboard
        )}?${priceIdParams}=${priceId}`
      );
      return;
    }

    if (isLoading) {
      return;
    }

    if (!userIsTeamOwner) {
      onEmitModalPopup("noBillingRightModal");
      return;
    }

    onLoading();

    try {
      /*
      if (userHasActivePlan) {
        await redirectUserToStripeCustomerProtal();
        safeRun(() => {
          onSuccess();
        });
        return;
      }
      */

      const { sessionId, url } = await protectedClientApi.stripeCheckout({
        priceId,
        quantity: 1,
        freeTrial: options.paidTrial,
        clientReferenceId: getClientReferenceId(),
      });
      if (inMobileApp()) {
        const mobileActions = getMobileActions();
        if (mobileActions) {
          mobileActions.openBrowserUrl(url);
          safeRun(() => {
            onSuccess();
          });
          return;
        }
      }
      const stripe = await getStripe();
      stripe?.redirectToCheckout({
        sessionId,
      });
      safeRun(() => {
        onSuccess();
      });
    } catch (error) {
      onError();
      console.log("error", error);
      captureException(error);
      addFailureNotification(
        "There's an error with Junia, please try again later"
      );
    }
  };

  const handleStartingFreeTrial = async () => {
    router.push(
      `${serverRoutes.login}?${buildFromParams(dashboardRouteConfig.dashboard)}`
    );
  };

  return {
    handleCheckout,
    isLoading,
    handleStartingFreeTrial,
  };
};

export const useOnetimeCheckout = () => {
  const router = useRouter();
  const user = useSelector(selectSession)?.user;
  const { safeRun } = useMounted();

  const { addFailureNotification } = useNotifications();
  const { isLoading, onLoading, onSuccess, onError } = useLoading();
  const handleOnetimeCheckout = async (amount: number, type = "credits") => {
    if (!user) {
      router.push(serverRoutes.login);
      return;
    }
    if (isLoading) {
      return;
    }

    try {
      onLoading();
      const { sessionId } = await protectedClientApi.stripeCheckout({
        priceId:
          type === "credits"
            ? oneTimeCreditPurchase.priceId
            : oneTimeWorkflowPurchase.priceId,
        quantity: amount,
      });
      const stripe = await getStripe();

      stripe?.redirectToCheckout({
        sessionId,
      });
      safeRun(() => {
        onSuccess();
      });
    } catch (error) {
      onError();
      captureException(error);
      addFailureNotification(
        "There's an error with Junia, please try again later"
      );
    }
  };
  return { isLoading, handleOnetimeCheckout };
};

export const removePriceIdParamsFromUrl = () => {
  if (typeof window !== "undefined") {
    const url = new URL(location.href);
    url.searchParams.delete(priceIdParams);
    window.history.replaceState({}, "", url);
  }
};

export const useRedirectUserToCheckoutByUrlParams = () => {
  const { handleCheckout } = useCheckout();
  const priceId = useSearchParam(priceIdParams);
  const userHasActivePlan = useSelector(selectUserHasActiveSubscription);

  React.useEffect(() => {
    if (
      !userHasActivePlan &&
      priceId &&
      typeof priceId === "string" &&
      subscriptionPriceIds.includes(priceId)
    ) {
      if (typeof window !== "undefined") {
        let savedPriceId = priceId;
        removePriceIdParamsFromUrl();
        handleCheckout(savedPriceId);
      }
    }
  }, []);
};
