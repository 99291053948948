import { useAppDispatch } from "hooks/use-app-dispatch";
import { setSubscription, setUserDetails } from "reducers/user";
import { useProcessingLog } from "./use-loading";
import useSWR from "swr";

import { swrFechter } from "utils/swrFechter";
import { useUser } from "./use-user";
import React from "react";
import { setUserId } from "modules/collection-by-id/editor/modules/const";

export const userDetailsLoadingLogId = "user-details-loading-status";

export const useFetchUserDetailsDataOnlyOnce = () => {
  const { initiated } = useProcessingLog(userDetailsLoadingLogId);
  return useUserDetailsData(!initiated);
};

export const useUserDetailsData = (canFetch: boolean = true) => {
  const userId = useUser()?.id;
  const dispatch = useAppDispatch();
  const { onSuccess } = useProcessingLog(userDetailsLoadingLogId);

  React.useEffect(() => {
    setUserId(userId);
  }, [userId]);
  const { data } = useSWR(
    () => (canFetch && userId ? `/api/users/current-user` : null),
    swrFechter
  );

  if (data) {
    const { activePlan, ...userDetails } = data;
    dispatch(setSubscription(activePlan));
    dispatch(setUserDetails(userDetails));
    onSuccess();
  }
};
