import useCopyToClipboard from "external/react-use/useCopyToClipboard";
import { useNotifications } from "./use-notification";

export const useOnCopyToClipboard = () => {
  const [s, copyToClipboard] = useCopyToClipboard();
  const { addSuccessNotification } = useNotifications();
  const onCopy = (text: string, format?: string) => {
    try {
      copyToClipboard(text, format);

      addSuccessNotification("Copied to clipboard");
    } catch (e) {}
  };
  return {
    onCopy,
  };
};
