import { Box, BoxProps } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import React from "react";

import { useUser } from "hooks/use-user";
import { Interval, PlanType, PlanName } from "client-server-shared/pricing/v2";
import { useCheckout } from "hooks/use-checkout";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import {
  ButtonWithLoading,
  OutlineGreyButton,
} from "components/ui/button/button-with-loading";
import { SourceType } from "utils/tracking/type";
import { trackClickOnPricing } from "utils/tracking/payment";
import { Tooltip } from "components/ui/tooltip";
import { useRouter } from "next/router";
import { useTranslation } from "components/translate";
import { InlineSpan } from "components/as-inline-span";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconComponent } from "components/icon-component";
import { Divider } from "components/ui/divider";
import Slider from "components/ui/form/slider";
import { useShortId } from "hooks/use-short-id";
import { usePricingContext } from "./pricing";
import { colors } from "src/constants";

interface PricingContextValue {
  selectedInterval: Interval;
  onIntervalChange: (interval: Interval) => void;
}

const marks = [
  {
    value: 0,
  },
  {
    value: 35,
  },
  {
    value: 70,
  },
  {
    value: 100,
  },
];

const PricingContext = React.createContext<PricingContextValue | null>(null);

const BlueCheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="9"
      style={{
        minWidth: "11px",
        minHeight: "9px",
      }}
      viewBox="0 0 11 9"
      fill="none"
    >
      <path
        d="M1.80762 5.13796C2.68945 5.13796 5.49705 10.4007 5.90351 6.94581C6.19125 4.50008 7.66067 1.98321 9.94291 1.07031"
        stroke={colors.red1}
        strokeWidth="1.65687"
        strokeLinecap="round"
      />
    </svg>
  );
};
const PurpleCheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      style={{
        minWidth: "12px",
        minHeight: "10px",
      }}
      viewBox="0 0 12 10"
      fill="none"
    >
      <path
        d="M1.82227 5.19296C2.75328 5.19296 5.71748 10.7493 6.14661 7.10164C6.4504 4.5195 8.00177 1.86226 10.4113 0.898438"
        stroke="#B85FD7"
        strokeWidth="1.74928"
        strokeLinecap="round"
      />
    </svg>
  );
};

interface FeatureListItemProps {
  description: string;
  disabled?: boolean;
  learnMoreQuery?: string;
  info?: string;
  fontRatio?: number;
}

export const FeatureListItem = ({
  description,
  disabled,
  info,
  learnMoreQuery,
  isFreeTiralCard,
  isPro,
  isAdvancedPlan,
  fontRatio = 1,
}: FeatureListItemProps) => {
  const { t } = useTranslation("pricing");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "24px",
      }}
    >
      <BlueCheckMark />
      {
        <Box
          sx={{
            width: "100%",
            display: "flex",
            columnGap: "2px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={info ? t(info) : ""}>
            <Typography
              color={colors.black1}
              variant="body2"
              sx={{
                fontSize: `${14 * fontRatio}px`,
                fontWeight: 400,
              }}
              dangerouslySetInnerHTML={{ __html: t(description) }}
            />
          </Tooltip>
          {info && (
            <Tooltip title={info ? t(info) : ""}>
              <IconComponent
                icon={InfoOutlinedIcon}
                sx={{
                  height: "14px",
                  width: "14px",
                }}
              />
            </Tooltip>
          )}
        </Box>
      }
    </Box>
  );
};

export const PricingCard = ({
  planName,
  description,
  priceIds,
  currency = "USD",
  unit_amount,
  features,
  page,
  label,
  info,
  sx = {},
}: PlanType & {
  sx?: BoxProps;
  page:
    | SourceType.BillingPage
    | SourceType.PricingPage
    | SourceType.UpgradePopup;
}) => {
  const user = useUser();
  const isFreeTiralCard = planName === PlanName.free;
  const isPro = planName === PlanName.creator;
  const isAdvancedPlan = planName === PlanName.pro;
  const { handleCheckout, isLoading, handleStartingFreeTrial } = useCheckout();
  const { t } = useTranslation("pricing");
  const priceString = new Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits: 0,
  }).format((unit_amount || 0) / 100);
  const router = useRouter();

  const { customValue, setCustomValue } = usePricingContext();

  const currencyLabel = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  })
    .format(0)
    .replace(/[0-9.,\s]/g, "");
  const inUpgradePopup = page === SourceType.UpgradePopup;
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);
  const usePaidTrail = userCanHavePaidTrial && planName === PlanName.creator;
  const fontRatio = inUpgradePopup ? 0.8 : 1;
  const sliderTitleId = useShortId();
  const sliderId = useShortId();
  if (isFreeTiralCard && user) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexBasis: {
          xs: "100%",
          sm: "100%",
          md: "40%",
          lg: "30%",
          xl: "30%",
        },
        borderRadius: "12px",
        position: "relative",
        background: "#FFFFFF",
        border: `${planName === PlanName.pro ? 2 : 1}px solid ${
          planName === PlanName.pro ? colors.red1 : colors.gray2
        }`,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px 12px 24px",
        }}
      >
        {planName === PlanName.pro && (
          <Box
            sx={{
              position: "absolute",
              background: colors.red1,
              padding: "3px 12px",
              top: "16px",
              right: "16px",
              borderRadius: "4px",
              marginLeft: "10px",
            }}
          >
            <Typography
              fontSize={12 * fontRatio}
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
              }}
              variant="body2"
              color={colors.textWhite1}
            >
              {t("Popular")}
            </Typography>
          </Box>
        )}
        <Box padding={10}>
          <Box
            sameLine
            alignCenter
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                fontSize: `${24 * fontRatio}px`,
              }}
              color={colors.black1}
            >
              {t(label) || t(planName)}
            </Typography>
          </Box>
          <Typography
            variant="caption2"
            color={colors.gray1}
            sx={{
              display: "flex",
              fontSize: `${14 * (inUpgradePopup ? 0.9 : 1)}px`,
              maxWidth: "330px",
              textAlign: "center",
              marginTop: "12px",
              justifyContent: "center",
            }}
          >
            {description ? t(description) : ""}
          </Typography>

          <Typography
            fontWeight={400}
            variant="h4"
            textAlign={"center"}
            color="text.white"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "6px 0",
            }}
          >
            <InlineSpan
              sx={{
                fontSize: `${48 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: colors.black1,
              }}
            >
              {currencyLabel}
            </InlineSpan>
            <InlineSpan
              sx={{
                fontSize: `${48 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: colors.black1,
              }}
            >
              {priceString}
            </InlineSpan>
            <InlineSpan
              sx={{
                color: colors.gray1,
                fontSize: `${16 * fontRatio}px`,
                marginLeft: "6px",
              }}
            >
              {t("/month")}
            </InlineSpan>
          </Typography>
          {/*
        {!isFreeTiralCard && (
          <Typography
            fontWeight={400}
            variant="body2"
            textAlign={"center"}
            color="text.black"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            margin="10px 10px"
          >
            {wordsPerMonth} words/month
          </Typography>
        )}
            */}
          <ButtonWithLoading
            variant="contained"
            onClick={() => {
              trackClickOnPricing({
                source: page,
                register: !!user,
              });
              if (isFreeTiralCard) {
                handleStartingFreeTrial();
              } else {
                handleCheckout(priceIds[0], {
                  paidTrial: usePaidTrail,
                });
              }
            }}
            loading={isLoading}
            capitalize
            sx={{
              width: "100%",
              display: "flex",
              margin: "auto",
              borderRadius: "10px",
              boxShadow: "none",
              background: colors.red1,
              color: colors.textWhite1,
              "&:hover": {
                background: colors.red1,
                opacity: 0.8,
              },
            }}
          >
            {user && !isFreeTiralCard
              ? usePaidTrail
                ? t("Start for $2,99")
                : t("Upgrade Now")
              : t("Get started")}
          </ButtonWithLoading>

          <Box
            sx={{
              marginTop: "36px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              marginBottom={14}
              color={"text.black"}
              variant="body2"
              sx={{
                fontWeight: 600,
              }}
            >
              {isFreeTiralCard
                ? t("What's included?")
                : isPro
                ? !!user
                  ? t("What's included?")
                  : t("Everything in Free, plus:")
                : t("Everything in Standard, plus:")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              {features
                .filter((feature) => {
                  if (feature.loginedOnly && !user) {
                    return false;
                  }
                  return true;
                })
                .map((feature) => {
                  return (
                    <FeatureListItem
                      fontRatio={inUpgradePopup ? 0.9 : 1}
                      key={feature.description}
                      description={feature.description}
                      info={feature.info}
                      isFreeTiralCard={isFreeTiralCard}
                      isPro={isPro}
                      isAdvancedPlan={isAdvancedPlan}
                      disabled={feature.disabled}
                      learnMoreQuery={feature.learnMoreQuery}
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
      {false && (
        <Box
          sx={{
            padding: "0 24px 12px 24px",

            "& .MuiSlider-thumb": {
              height: "16px !important",
              width: "16px !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "rgba(184, 95, 215, 1)",
            },
          }}
        >
          <Divider
            sx={{
              marginBottom: "12px",
            }}
          />
          <Typography
            id={sliderTitleId}
            marginBottom={14}
            color={colors.gray1}
            variant="body2"
            sx={{
              fontWeight: 600,
              marginBottom: "12px",
            }}
          >
            CUSTOMIZE YOUR PLAN
          </Typography>
          <Slider
            id={sliderId}
            aria-labelledby={sliderTitleId}
            step={null}
            marks={marks}
            value={customValue}
            onChange={(event, newValue) => {
              setCustomValue(newValue as number);
            }}
            sx={{
              color: "rgba(0, 0, 0, 0.3)",
              height: 4,
            }}
            valueLabelDisplay="off"
            aria-label="Customize plan slider"
          />
        </Box>
      )}
    </Box>
  );
};
