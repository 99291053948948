import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiSwitch, { SwitchProps } from "@mui/material/Switch";

import React from "react";
import { colors } from "src/constants";

const Switch = (props: SwitchProps) => {
  const sx = getStyleProps(props).sx;
  return (
    <MuiSwitch
      {...passProps(props)}
      sx={{
        ...sx,
        color: `${colors.red1}!important`,
        "& .Mui-checked": {
          color: `${colors.red1}!important`,
        },
        "& .MuiSwitch-track": {
          backgroundColor: `${colors.red1}!important`,
        },
      }}
    />
  );
};

export default Switch;
