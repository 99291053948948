import { PostConfig } from "../types/types";

export const defaultAnalytics: PostConfig["analytics"] = {
  words: 3000,
  headings: 20,
  paragraphs: 20,
  images: 3,
  score: 10,
};
export const findBestCompetitors = (items: any[]) => {
  const found = items.reduce((max, item) =>
    max.wordsCount > item.wordsCount ? max : item
  );
  return found;
};
