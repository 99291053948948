import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import MuiMenuList from "@mui/material/MenuList";
import React from "react";
import MuiMenu, { MenuProps } from "@mui/material/Menu";

export { MenuProps };
export const Menu = React.forwardRef((props: MenuProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiMenu ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiMenu>
  );
});

Menu.displayName = "Menu";

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiMenuList ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiMenuList>
  );
});

MenuList.displayName = "MenuList";

export const MenuItem = React.forwardRef((props: MenuItemProps, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiMenuItem ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiMenuItem>
  );
});

MenuItem.displayName = "MenuItem";
