import { useSelector } from "hooks/use-selector";
import {
  selectIsUserAdmin,
  selectUserHasActiveSubscription,
  selectUserId,
  selectUserInActiveProPlan,
  selectUserInActiveUnlimitedPlan,
  selectUserInAppSumoPlan,
  selectUserInAppSumoPlanTierFour,
  selectUserInAppSumoPlanTierOne,
  selectUserInAppSumoPlanTierThree,
  selectUserInAppSumoPlanTierTwo,
} from "selectors/user";
import { useUserFromShopify } from "./use-user";
import { withSpecialAccessToBulkGenerator } from "client-server-shared/constants";

export const useUserHasFeatures = () => {
  const userInAppSumoTier1 = useSelector(selectUserInAppSumoPlanTierOne);
  const userInAppSumoTier2 = useSelector(selectUserInAppSumoPlanTierTwo);

  const userInAppSumoPlan = useSelector(selectUserInAppSumoPlan);
  const userInPaidPlan = useSelector(selectUserHasActiveSubscription);
  const userInProPlan = useSelector(selectUserInActiveProPlan);
  const userInUnlimitedPlan = useSelector(selectUserInActiveUnlimitedPlan);
  const hasAutoLinkingFeature = userInPaidPlan && !userInAppSumoTier1;
  const userId = useSelector(selectUserId);
  const hasIntegrationFeature = userInPaidPlan && !userInAppSumoTier1;
  const userInAppSumoPlanTierThree = useSelector(
    selectUserInAppSumoPlanTierThree
  );
  const userInAppSumoPlanTierFour = useSelector(
    selectUserInAppSumoPlanTierFour
  );
  const userFromShopify = useUserFromShopify();
  const userAdmin = useSelector(selectIsUserAdmin);
  const hasSecheduledPublishFeature = userInAppSumoPlan
    ? userInAppSumoPlanTierThree && userInAppSumoPlanTierFour
    : userInPaidPlan
    ? true
    : false;

  const doesNotHasAutoInsertCompetitorKeywords =
    userInAppSumoTier1 || userInAppSumoTier2;

  const hasTeamFeature =
    userInProPlan ||
    userInUnlimitedPlan ||
    userInAppSumoPlanTierThree ||
    userInAppSumoPlanTierFour;
  const canSeeBulkGenerator =
    (!userFromShopify && !userInAppSumoPlan) ||
    (userInAppSumoPlanTierFour && withSpecialAccessToBulkGenerator(userId));

  const canUseBulkGenerator = true;
  const canUseArticleImprover = userAdmin
    ? true
    : userId &&
      [
        "ce393a9d-4516-40f4-a9df-80961fb9f915",
        "d34c59db-b625-404a-af21-b3b9ff935587",
        "578431f6-08fe-42ba-bed0-ab15c95f1b11",
      ].includes(userId)
    ? true
    : false;
  return {
    hasTeamFeature,
    hasAutoLinkingFeature,
    hasIntegrationFeature,
    hasSecheduledPublishFeature,
    doesNotHasAutoInsertCompetitorKeywords,
    canSeeBulkGenerator,
    canUseBulkGenerator,
    canUseArticleImprover,
  };
};
