import { useLoading } from "hooks/use-loading";
import { useNotifications } from "hooks/use-notification";
import { useSelector } from "hooks/use-selector";
import React from "react";
import { selectUserDetails, selectUserMediumToken } from "selectors/user";
import { useIntegrationContext } from "../context";

export const usePublishToMedium = () => {
  const loading = useLoading();
  const {
    selectedIntegration,
    integrations,
    activeIntegrationId,
    updateIntegration,
  } = useIntegrationContext();

  const [config, setConfig] = React.useState<{
    name: string;
    token: string;
  }>({
    name: selectedIntegration?.name || "",
    token: selectedIntegration?.medium_access_token || "",
  });

  const onConfigChange = (key: string, value: string) => {
    setConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  React.useEffect(() => {
    if (!activeIntegrationId) {
      setConfig({
        name: "",
        token: "",
      });
    } else {
      const newIntegration = integrations.find(
        (i) => i.id === activeIntegrationId
      );
      if (newIntegration) {
        setConfig({
          name: newIntegration.name,
          token: newIntegration.medium_access_token,
        });
      }
    }
  }, [integrations, activeIntegrationId]);

  const userMediumToken = useSelector(selectUserMediumToken);
  const userDetails = useSelector(selectUserDetails);
  const { addSuccessNotification, addFailureNotification } = useNotifications();

  const onSaveMediumToken = React.useCallback(async () => {
    if (!userDetails) {
      return;
    }
    if (loading.isLoading) {
      return;
    }
    try {
      loading.onLoading();
      await updateIntegration({
        name: config.name,
        medium_access_token: config.token,
      });

      loading.onSuccess();
      addSuccessNotification("Token saved!");
    } catch (e) {
      addFailureNotification(
        `There's an unknown error saving your Medium Access Token, please try again!`
      );
    }
  }, [updateIntegration, loading.isLoading, config]);

  return {
    config,
    onConfigChange,
    onSaveMediumToken,
    userMediumToken,
    loading,
  };
};
