import { LimitReachedType } from "client-server-shared/types/limit";
import EventEmitter from "eventemitter3";

export const otherLimitEventEmitter = new EventEmitter();

export const otherLimitEventId = "other-limit-reached";

export type OtherLimitReachedConfig = {
  description1?: string;
  description2?: string;
};

export const onOtherLimitReached = (
  type: LimitReachedType,
  config?: OtherLimitReachedConfig
) => {
  otherLimitEventEmitter.emit(otherLimitEventId, type, config);
};
