import React from "react";

import { Description, Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";

const DateTimeDisplay = ({ value, type, isDanger, inFrontPage }) => {
  return (
    <Box
      sx={{
        color: isDanger ? "#ff0000" : "initial",
      }}
    >
      <Typography
        sx={{
          background: "white",
          borderRadius: "4px",
          padding: "3px 6px",
          width: "36px",
          display: "flex",
          fontWeight: 500,
          justifyContent: "center",
        }}
      >
        {value}
      </Typography>
      <Description
        sx={{
          color: inFrontPage ? "text.black" : "text.white",
          fontWeight: 400,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {type}
      </Description>
    </Box>
  );
};

export default DateTimeDisplay;
