import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { usePublishToMedium } from "./shared";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { useSelector } from "hooks/use-selector";
import protectedClientApi from "utils/protected-client-api";
import { textEllipsis } from "modules/themes/styleUtils";
import { MenuItem } from "components/ui/menu";
import { Select } from "components/ui/select";
import { useMounted } from "hooks/use-mounted";
import { useLoading } from "hooks/use-loading";
import { useNotifications } from "hooks/use-notification";
import { selectUserDetails } from "selectors/user";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useCloseSelectedPopup } from "context/popup-context";
import { secondaryScrollbar } from "modules/themes/sytle-constants";
import { Tooltip } from "components/ui/tooltip";
import { Chip } from "components/ui/chip";
import truncate from "lodash/truncate";
import { SearchbarWithButton } from "components/search-bar";
import { InlineSpan } from "components/as-inline-span";
import { MetaDataField } from "client-server-shared/config/fieldMapping";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress } from "components/ui/loading";
import {
  getContextText,
  strippedHtml,
} from "client-server-shared/utils/text-utils";
import { useActivePaidPlanPrompt } from "hooks/use-active-paid-plan-prompt";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationLayout } from "../layout";
import { PurplePinkButton } from "components/purple-button";
import SchedulePublish from "../schedule-publish";
import pluralize from "pluralize";

const emptyArr = [];

const MediumPublishModal = ({
  onSave,
  initialTags,
  initialMode,
  initialSchedulePublishConfig = {},
}) => {
  const onClose = useCloseSelectedPopup();

  const [tagValue, setTagValue] = React.useState("");

  const { integrations, selectedIntegration, activeIntegrationId } =
    useIntegrationContext();

  const { userInActivePaidPlan } = useActivePaidPlanPrompt();

  const [publishConfig, setConfig] = React.useState({
    status: initialMode || "public",
    tags: initialTags || emptyArr,
  });

  const [schedulePublishConfig, setSchedulePublishConfig] = React.useState({
    enabled: false,
    amount: 5,
    ...initialSchedulePublishConfig,
  });

  const onPublishConfigChange = React.useCallback((key, value) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);

  React.useEffect(() => {
    if (!activeIntegrationId) {
      setConfig({
        status: "draft",
        tags: emptyArr,
      });
    }
  }, [activeIntegrationId]);

  return (
    <Dialog
      fullWidth
      maxWidth={integrations.length === 0 ? "sm" : "lg"}
      open
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginRight: "24px",
            }}
          >
            {`Publish to Medium In Bulk${
              userInActivePaidPlan ? "" : "(Paid Plan Required)"
            }`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            maxWidth: "350px",
          }}
        >
          You can connect to multiple medium accounts and choose which medium
          blog to publish to. Note: Medium has a limit of 15 articles per day.
        </Description>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          <Section>
            <SectionTitle required>
              <InlineSpan inherit>Tags</InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  color: (theme) =>
                    publishConfig.tags.length > 3
                      ? theme.palette.error.main
                      : "inherit",
                }}
              >
                ({publishConfig.tags.length}/3)
              </InlineSpan>
            </SectionTitle>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "6px",
                rowGap: "6px",
                maxHeight: "200px",
                paddingBottom: "12px",
                overflowY: "auto",
                ...secondaryScrollbar,
              }}
            >
              {(publishConfig.tags || []).map((tag, index) => {
                return (
                  <Tooltip
                    placement="left-start"
                    key={index}
                    title={tag.length > 50 ? tag : ""}
                  >
                    <Box>
                      <Chip
                        sx={{
                          background: "#E6E6FA",
                          userSelect: "auto",
                          cursor: "text",
                          "& svg": {
                            fill: "#9B4E97",
                          },
                        }}
                        onDelete={() => {
                          onPublishConfigChange(
                            "tags",
                            publishConfig.tags?.filter(
                              (currentTag) => tag !== currentTag
                            )
                          );
                        }}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "12px",
                            }}
                          >
                            <InlineSpan
                              sx={{
                                color: "#9B4E97",
                                fontSize: "12px",
                              }}
                            >
                              {truncate(tag, {
                                length: 50,
                                separator: "...",
                              })}
                            </InlineSpan>
                          </Box>
                        }
                        size="small"
                      />
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
            <PrimaryInput
              size="small"
              onChange={(e) => {
                setTagValue(e.target.value);
              }}
              value={tagValue}
              onBlur={() => {
                if (tagValue && tagValue.trim()) {
                  onPublishConfigChange("tags", [
                    tagValue,
                    ...(publishConfig.tags || []),
                  ]);
                  setTagValue("");
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (tagValue && tagValue.trim()) {
                    onPublishConfigChange("tags", [
                      tagValue,
                      ...(publishConfig.tags || []),
                    ]);
                    setTagValue("");
                  }
                }
              }}
              placeholder={`Eg: AI. Press Enter to add.`}
              sx={{
                width: "100%",
                "& textarea": {
                  ...secondaryScrollbar,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "12px",
                  "&:hover fieldset": {
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  },
                },
              }}
            />
          </Section>
          <Section>
            <SectionTitle>Mode</SectionTitle>
            <Select
              size="small"
              onChange={(e, newValue) => {
                onPublishConfigChange("status", e.target.value);
              }}
              value={publishConfig.status}
            >
              {["public", "draft"].map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Typography
                      variant="body2"
                      sx={{
                        ...textEllipsis,
                        maxWidth: "100px",
                        fontSize: "12px",
                      }}
                    >
                      {status}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Section>
          <SchedulePublish
            schedulePublishConfig={schedulePublishConfig}
            onChange={setSchedulePublishConfig}
          />
          {!selectedIntegration ? null : (
            <Description
              sx={{
                marginBottom: "12px",
              }}
            >
              {schedulePublishConfig.enabled
                ? `This will schedule publish the articles to ${
                    selectedIntegration.name
                  } ${schedulePublishConfig.amount} ${pluralize(
                    "article",
                    schedulePublishConfig.amount
                  )} per day after the generation. We will send you an email when your articles are published.`
                : `This will publish the articles to ${selectedIntegration.name} immediately after the generation.`}
            </Description>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PurplePinkButton
              disabled={Object.keys(publishConfig).some(
                (key) => publishConfig[key].length === 0
              )}
              onClick={async () => {
                if (!selectedIntegration) {
                  return;
                }
                onSave({
                  mode: publishConfig.status,
                  tags: publishConfig.tags,
                  integrationId: selectedIntegration.id,
                  schedulePublishConfig,
                });
                onClose();
              }}
            >
              Save
            </PurplePinkButton>
          </Box>
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};

const MediumPublishModalContainer = ({
  onSave,
  defaultIntegrationId,
  initialTags,
  initialMode,
  schedulePublishConfig = {},
}) => {
  return (
    <IntegrationContextProvider
      disableAdd
      disableEdit
      defaultIntegrationId={defaultIntegrationId}
      inPublishMode
      type={IntegrationType.Medium}
    >
      <MediumPublishModal
        onSave={onSave}
        initialSchedulePublishConfig={schedulePublishConfig}
        initialTags={initialTags}
        initialMode={initialMode}
      />
    </IntegrationContextProvider>
  );
};

export default MediumPublishModalContainer;
