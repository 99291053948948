import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { useNotifications } from "hooks/use-notification";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useLoading } from "hooks/use-loading";
import { useSelector } from "hooks/use-selector";
import protectedClientApi from "utils/protected-client-api";
import { selectIntegrationsByType, selectUserDetails } from "selectors/user";
import {
  integrationAdded,
  integrationModified,
  setUserDetails,
} from "reducers/user";
import { useCloseSelectedPopup } from "context/popup-context";
import { HighlightedSpan } from "components/as-inline-span";
import { getRequestErrorObject } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";

import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InlineSpan } from "components/as-inline-span";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationServer } from "client-server-shared/types/types";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationLayout } from "../layout";

const ShopifyConnectModalInnerContainer = ({ afterConnect, onClose }) => {
  const { selectedIntegration } = useIntegrationContext();

  return (
    <ShopifyConnectModal
      selectedIntegration={selectedIntegration}
      onClose={onClose}
      afterConnect={afterConnect}
    />
  );
};

export const ShopifyConnectModal = ({
  onClose,
  afterConnect,
  selectedIntegration,
}: {
  selectedIntegration?: IntegrationServer;
  onClose: () => void;
  afterConnect?: () => void;
}) => {
  const [config, setConfig] = React.useState({
    storeName: selectedIntegration?.shopifyStoreName || "",
    accessToken: selectedIntegration?.shopifyAccessToken || "",
    secretKey: selectedIntegration?.shopifySecretKey || "",
  });
  const loading = useLoading();
  const dispatch = useAppDispatch();
  const userDetails = useSelector(selectUserDetails);
  const { addSuccessNotification, addFailureNotification } = useNotifications();

  const onSave = React.useCallback(async () => {
    if (!userDetails) {
      return;
    }
    if (loading.isLoading) {
      return;
    }
    if (
      !config.accessToken.trim().length ||
      !config.storeName.trim().length ||
      !config.secretKey.trim().length
    ) {
      return;
    }
    try {
      loading.onLoading();
      const toApiConfig = {
        name: config.storeName.trim(),
        shopifyStoreName: config.storeName.trim(),
        shopifyAccessToken: config.accessToken.trim(),
        shopifySecretKey: config.secretKey.trim(),
        type: IntegrationType.Shopify,
      };
      await protectedClientApi.validateShopifyCredentials({
        shopify_url: toApiConfig.shopifyStoreName,
        shopify_access_token: toApiConfig.shopifyAccessToken,
        shopify_secret_key: toApiConfig.shopifySecretKey,
      });

      if (selectedIntegration?.id) {
        const updated = await protectedClientApi.updateIntegration(
          selectedIntegration.id,
          toApiConfig
        );
        dispatch(integrationModified(updated));
      } else {
        const created = await protectedClientApi.saveIntegration(toApiConfig);
        dispatch(integrationAdded(created));
      }

      loading.onSuccess();
      addSuccessNotification("Shopify credentials saved!");
      //  onClose();

      if (afterConnect) {
        afterConnect();
      }
    } catch (e) {
      const errorJSON = getRequestErrorObject(e);
      let message = `There's an unknown error saving your Shopify credentials, please try again!`;
      if (errorJSON?.code === ErrorCode.InvalidShopifyURLError) {
        message = `Invalid Shopify URL, please double-check your Shopify store URL.`;
      } else if (errorJSON?.code === ErrorCode.InvalidShopifyCredentialsError) {
        message = `Invalid Shopify credentials, please double-check your Shopify access token and secret key.`;
      } else if (errorJSON?.code === ErrorCode.InvalidShopifyPermissionsError) {
        message = `Invalid Shopify permissions, please double-check your Shopify permissions and try reconnecting to Shopify.`;
      }

      addFailureNotification(message);
      console.log(e);
      loading.onIdle();
    }
  }, [userDetails, loading.isLoading, config]);
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.ShopifyIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();
  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
        <DialogTitle
          sx={{
            padding: "24 24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginRight: "24px",
                  }}
                >
                  Connect To Shopify
                </Typography>
                {hasIntegrationFeature ? null : (
                  <Description
                    sx={{
                      marginTop: "6px",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "4px",
                      fontWeight: 600,
                      color: "#F44335",
                    }}
                  >
                    <IconComponent
                      icon={LockOutlinedIcon}
                      sx={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    <InlineSpan inherit>
                      <InlineSpan
                        inherit
                        onClick={() => {
                          onUpgrade();
                        }}
                        sx={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Please upgrade
                      </InlineSpan>{" "}
                      to a higher plan to use this feature.
                    </InlineSpan>
                  </Description>
                )}
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                onClose();
              }}
              sx={{
                marginLeft: "auto",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "6px",
            }}
          >
            <Description
              sx={{
                marginTop: "6px",
                maxWidth: "400px",
              }}
            >
              You can connect to multiple Shopify stores and choose which store
              to publish to. Learn how to connect to Shopify{" "}
              <HighlightedSpan inherit>
                <a
                  href="https://writingtools.ai/docs/shopify-integration"
                  target="_blank"
                >
                  here
                </a>
              </HighlightedSpan>
            </Description>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: "36px",
          }}
        >
          <Section>
            <SectionTitle>Shopify store name</SectionTitle>
            <PrimaryInput
              value={config.storeName}
              autoFocus
              placeholder="example-store.myshopify.com"
              fullWidth
              size="small"
              onChange={(e) => {
                setConfig((previous) => ({
                  ...previous,
                  storeName: e.target.value,
                }));
              }}
            />
          </Section>
          <Section>
            <SectionTitle>Shopify Access Token</SectionTitle>
            <PrimaryInput
              value={config.accessToken}
              autoFocus
              placeholder=""
              fullWidth
              size="small"
              type={"password"}
              onChange={(e) => {
                setConfig((previous) => ({
                  ...config,
                  accessToken: e.target.value,
                }));
              }}
            />
          </Section>
          <Section>
            <SectionTitle>Shopify Secret Key</SectionTitle>
            <PrimaryInput
              value={config.secretKey}
              autoFocus
              placeholder=""
              fullWidth
              size="small"
              type={"password"}
              onChange={(e) => {
                setConfig((previous) => ({
                  ...config,
                  secretKey: e.target.value,
                }));
              }}
            />
          </Section>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OutlineGreyButton
              loading={loading.isLoading}
              disabled={
                !hasIntegrationFeature ||
                Object.keys(config).some(
                  (key) => config[key].trim().length === 0
                )
              }
              onClick={() => {
                onSave();
              }}
            >
              Submit
            </OutlineGreyButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ShopifyConnect = ({ onClose }) => {
  const {
    integrations,
    activeIntegrationId,
    setActiveIntegrationId,
    selectedIntegration,
  } = useIntegrationContext();
  const integration = selectedIntegration;
  const dispatch = useAppDispatch();
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.ShopifyIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();
  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
        <DialogTitle
          sx={{
            padding: "24 24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginRight: "24px",
                  }}
                >
                  Connect To Shopify
                </Typography>
                {hasIntegrationFeature ? null : (
                  <Description
                    sx={{
                      marginTop: "6px",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "4px",
                      fontWeight: 600,
                      color: "#F44335",
                    }}
                  >
                    <IconComponent
                      icon={LockOutlinedIcon}
                      sx={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    <InlineSpan inherit>
                      <InlineSpan
                        inherit
                        onClick={() => {
                          onUpgrade();
                        }}
                        sx={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Please upgrade
                      </InlineSpan>{" "}
                      to a higher plan to use this feature.
                    </InlineSpan>
                  </Description>
                )}
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                onClose();
              }}
              sx={{
                marginLeft: "auto",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "6px",
            }}
          >
            <Description
              sx={{
                marginTop: "6px",
                maxWidth: "400px",
              }}
            >
              You can connect to multiple Shopify stores and choose which store
              to publish to. Learn how to connect to Shopify{" "}
              <HighlightedSpan inherit>
                <a
                  href="https://writingtools.ai/docs/shopify-integration"
                  target="_blank"
                >
                  here
                </a>
              </HighlightedSpan>
            </Description>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: "36px",
          }}
        >
          <IntegrationLayout></IntegrationLayout>
        </DialogContent>
      </Dialog>
      {selectedIntegration ? (
        <ShopifyConnectModalInnerContainer
          onClose={() => {
            setActiveIntegrationId(null);
          }}
          afterConnect={() => {
            setActiveIntegrationId(null);
          }}
        />
      ) : null}
    </>
  );
};

const ShopifyConnectModalContainer = () => {
  const onClosePopup = useCloseSelectedPopup();
  const integrations = useSelector((state) =>
    selectIntegrationsByType(state, IntegrationType.Shopify)
  );
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  return (
    <>
      <IntegrationContextProvider
        onAddNewIntegration={() => {
          setShowConnectModal(true);
        }}
        type={IntegrationType.Shopify}
      >
        {showConnectModal && (
          <ShopifyConnectModal
            onClose={() => {
              setShowConnectModal(false);
            }}
            afterConnect={() => {
              setShowConnectModal(false);
            }}
          />
        )}
        {integrations.length === 0 ? (
          <ShopifyConnectModal
            onClose={() => {
              onClosePopup();
            }}
          />
        ) : (
          <ShopifyConnect onClose={onClosePopup} />
        )}
      </IntegrationContextProvider>
    </>
  );
};

export default ShopifyConnectModalContainer;
