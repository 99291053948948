import { LogoIconOnly } from "components/logo";
import { absoluteCenter } from "modules/themes/styleUtils";
import { Box, BoxProps } from "../box";
import { CircularProgress } from ".";

export const TypingLoading = ({
  boxProps = {},
  dotSize = 10,
}: {
  boxProps: BoxProps;
}) => {
  return (
    <Box
      {...boxProps}
      sx={{
        ...(boxProps.sx || {}),
      }}
    >
      <CircularProgress size={dotSize} />
    </Box>
  );
};

export const TypingLoadingAbsoluteCenter = (props: BoxProps = {}) => {
  return (
    <TypingLoading
      {...props}
      boxProps={{
        sx: absoluteCenter,
        ...(props.sx || {}),
      }}
      dotSize={10}
    />
  );
};

export const BrandLoading = () => {
  return (
    <Box sx={{ ...absoluteCenter, zIndex: 99999 }}>
      <CircularProgress />
    </Box>
  );
};

export const BrandLoadingOverlay = ({ onOverlayClick = () => {} }) => {
  return (
    <>
      <Box
        onClick={onOverlayClick}
        sx={{
          top: "0px",
          left: "0px",
          zIndex: 9999,
          width: "100%",
          height: "100%",
          position: "fixed",
          backgroundColor: "rgba(22, 28, 36, 0.64)",
        }}
      />
      <BrandLoading />
    </>
  );
};
