import { addNotification } from "reducers/notifications";
import { AppDispatch } from "reducers/types";
import { useAppDispatch } from "./use-app-dispatch";

export const useNotifications = () => {
  const dispatch = useAppDispatch();
  return notificationUtils(dispatch);
};

export type NotificationApi = ReturnType<typeof useNotifications>;

interface Options {
  actionText?: string;
  action?: () => void;
  hideOnClickaway?: boolean;
}

export const notificationUtils = (dispatch: AppDispatch) => {
  const addSuccessNotification = (
    message: string,
    { actionText, action, hideOnClickaway }: Options = {}
  ) => {
    dispatch(
      addNotification({
        message,
        type: "success",
        actionText,
        action,
        hideOnClickaway,
      })
    );
  };
  const addFailureNotification = (message: string) => {
    dispatch(addNotification({ message, type: "error" }));
  };
  const addInfoNotification = (message: string) => {
    dispatch(addNotification({ message, type: "info" }));
  };
  return {
    addSuccessNotification,
    addFailureNotification,
    addInfoNotification,
  };
};
