import React from "react";
import { useDashboardTranslation } from "../translate";
import { useSelector } from "hooks/use-selector";
import {
  selectUserHasActiveSubscription,
  selectUserInActiveCreatorPlan,
  selectUserInActiveUnlimitedPlan,
  selectUserInActiveProPlan,
} from "selectors/user";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { LimitReachedType } from "client-server-shared/types/limit";
import LimitReachModal from "./limit-reached-modal";

const LimitReachModalContainer = ({
  type = LimitReachedType.wordsLimitReached,
  action,
  title,
  actionText,
  ...rest
}: {
  description1?: string;
  description2?: string;
  action?: () => void | null;
  type?:
    | LimitReachedType.wordsLimitReached
    | LimitReachedType.imagesLimitReached
    | LimitReachedType.requiredPaidPlan;
}) => {
  const { t } = useDashboardTranslation();
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.LimitReachedPopup,
  });
  const isPaidPlan = useSelector(selectUserHasActiveSubscription);
  const inCreatorPlan = useSelector(selectUserInActiveCreatorPlan);
  const inUnlimitedPlan = useSelector(selectUserInActiveUnlimitedPlan);
  const inProPlan = useSelector(selectUserInActiveProPlan);

  let description1 = t("You don't have enough words to use this feature.");
  let description2 = t(
    "Ready to unlock more features and benefits with our paid plans?"
  );

  if (isPaidPlan && type === LimitReachedType.wordsLimitReached) {
    if (inCreatorPlan) {
      description2 = t("Ready to unlock benefits with our Pro plan?");
    } else {
      description2 = t(
        "Would you like to purchase some more words to use for this month?"
      );
    }
  }
  if (type === LimitReachedType.imagesLimitReached) {
    description1 = t(
      "You've used up all your AI images for this month. Great work!"
    );
    if (inCreatorPlan) {
      description2 = t("Ready to unlock more AI images with our Pro plan?");
    }
    if (inUnlimitedPlan || inProPlan) {
      description2 = t(
        "We appreciate your support! A custom plan with even more AI images is coming just for you - stay tuned!"
      );
    }
  } else if (type === LimitReachedType.requiredPaidPlan) {
    description1 = t(
      "To access this feature, you'll need to be on a paid plan."
    );
  }
  if (rest.description1) {
    description1 = rest.description1;
  }
  if (rest.description2) {
    description2 = rest.description2;
  }

  return (
    <LimitReachModal
      title={title}
      actionText={actionText}
      action={() => {
        action ? action() : onUpgrade(true);
      }}
      description1={description1}
      description2={description2}
    />
  );
};

export default LimitReachModalContainer;
