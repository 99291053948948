import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiSlider, { SliderValueLabelProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import React from "react";
import { colors } from "src/constants";
export { SliderValueLabelProps };
const StyledSlider = styled(MuiSlider)(() => {
  return {
    color: colors.red1,
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
      backgroundColor: colors.red1,
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: colors.red1,
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  };
});

const Slider = React.forwardRef((props: SliderProps, ref) => {
  return <StyledSlider ref={ref} {...props} {...getStyleProps(props)} />;
});

export default Slider;
