import React from "react";
import { Box } from "components/ui/box";
import DateTimeDisplay from "./DateTimeDisplay";
import useCountDown from "./hooks/useCountDown";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, inFrontPage }) => {
  return (
    <Box
      sx={{
        padding: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "12px",
        }}
      >
        <DateTimeDisplay
          inFrontPage={inFrontPage}
          isDanger={days <= 3}
          type="Days"
          value={days}
        />
        <DateTimeDisplay
          inFrontPage={inFrontPage}
          isDanger={true}
          type="Hours"
          value={hours}
        />
        <DateTimeDisplay
          inFrontPage={inFrontPage}
          isDanger={true}
          type="Mins"
          value={minutes}
        />
        <DateTimeDisplay
          inFrontPage={inFrontPage}
          isDanger={true}
          type="Secs"
          value={seconds}
        />
      </Box>
    </Box>
  );
};

const CountDownTimer = ({ targetDate, inFrontPage }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return null;
  } else {
    return (
      <ShowCounter
        inFrontPage={inFrontPage}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDownTimer;
