import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import {
  selectActiveContentByCollectionAndPostId,
  selectCollectionServerId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { useSelector } from "hooks/use-selector";
import protectedClientApi from "utils/protected-client-api";
import { useCloseSelectedPopup, useOpenPopup } from "context/popup-context";
import { languageOptions } from "client-server-shared/constants";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "components/ui/form/checkbox";
import { FormControlLabel } from "components/ui/form/form-control-label";
import { List, ListItem } from "components/ui/list/List";
import { Link } from "react-router-dom";
import { postByIdRoute } from "client-server-shared/config/routes";
import { PurplePinkButton } from "components/purple-button";
import { useNotifications } from "hooks/use-notification";
import { useLoading } from "hooks/use-loading";
import { getH1 } from "utils/get-h1";
import { getRequestErrorObject } from "client-server-shared/utils/error-parsing";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";
import { ErrorCode } from "server/errors/utils";
import { countWords } from "client-server-shared/utils/countWords";
import {
  trackClickOnRewriteLanguage,
  trackOpenReWriteLanguagesModel,
} from "utils/tracking/common";

export const ResultsScreen = ({
  results,
  collectionId,
}: {
  collectionId: string;
  results: { title: string; id: string }[];
}) => {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: "14px",
          marginBottom: "12px",
        }}
      >
        You article is being rewritten.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: "14px",
          marginBottom: "12px",
        }}
      >
        They will appear in the following documents once they are ready:
      </Typography>
      <List
        sx={{
          rowGap: "12px",
          padding: "0 0 12px 0",
        }}
      >
        {results.map((item) => {
          return (
            <ListItem
              sx={{
                padding: "0",
                fontSize: "14px",
              }}
              key={item.id}
            >
              <a
                target="_blank"
                className="markdown-link-highlight"
                href={postByIdRoute(collectionId, item.id)}
              >
                {item.title}
              </a>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export const LanguagesFormGroup = React.memo(
  ({
    selectedLanguages,
    setSelectedLanguages,
  }: {
    selectedLanguages: string[];
    setSelectedLanguages: (selectedLanguages: string[]) => void;
  }) => {
    return (
      <FormGroup
        sx={{
          flexDirection: "row",
        }}
      >
        {languageOptions.map((language, index) => {
          const selected = selectedLanguages.includes(language.value);
          return (
            <FormControlLabel
              key={index}
              slotProps={{
                typography: {
                  variant: "body2",
                },
              }}
              control={
                <Checkbox
                  size="small"
                  checked={selectedLanguages.includes(language.value)}
                  onChange={() => {
                    setSelectedLanguages(
                      selected
                        ? selectedLanguages.filter(
                            (selectedLanguage) =>
                              selectedLanguage !== language.value
                          )
                        : [...selectedLanguages, language.value]
                    );
                  }}
                  name={language.label}
                />
              }
              label={language.label}
            />
          );
        })}
      </FormGroup>
    );
  }
);

const RepurposeModal = ({ postId, collectionId }) => {
  const onClose = useCloseSelectedPopup();
  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );
  const activeContent = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );
  const openPopup = useOpenPopup();
  const { addFailureNotification } = useNotifications();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const collectionSavedId = useSelector((state) =>
    selectCollectionServerId(state, collectionId)
  );
  const wordsCount = countWords(activeContent || "");

  const cost = Math.round((wordsCount * selectedLanguages.length) / 3);

  const [results, setResults] = React.useState<{ title: string; id: string }[]>(
    []
  );
  const loading = useLoading();

  React.useEffect(() => {
    trackOpenReWriteLanguagesModel();
  }, []);

  const onRepurpose = async () => {
    if (loading.isLoading) {
      return;
    }
    try {
      loading.onLoading();
      const response =
        await protectedClientApi.scheduleRewriteToDifferentLanguages({
          languages: selectedLanguages,
          postTitle: getH1(activeContent) || post?.title,
          html: activeContent,
          collectionId: collectionSavedId,
          metaTitle: post?.metaTitle,
          metaDescription: post?.metaDescription,
          slug: post?.slug,
          faqs: post?.postConfig?.faqs,
        });
      setResults(response.data);
      loading.onIdle();
    } catch (e) {
      const errorJson = getRequestErrorObject(e);
      if (
        isPlainObject(errorJson) &&
        errorJson?.code === ErrorCode.NotEnoughCredits
      ) {
        loading.onIdle();
        openPopup("creditLimitReached");
      } else {
        loading.onIdle();

        addFailureNotification(
          `There's an unknown error submitting your request.`
        );
      }
    }
  };
  return (
    <Dialog fullWidth maxWidth="xs" open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              Bulk Translate
            </Typography>
            <Description>
              Translate the entire article, up to 6000 words at once. Dominate
              SERPs with ease and enjoy lower competition.
            </Description>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {results.length > 0 ? (
          <ResultsScreen
            collectionId={collectionId}
            results={results}
            onClose={onClose}
          />
        ) : (
          <>
            <Section>
              <SectionTitle>To different languages all at once</SectionTitle>
              <LanguagesFormGroup
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
              />
            </Section>
            {!!cost ? (
              <Section>
                <Typography
                  variant="body2"
                  sx={{
                    paddingTop: "12px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  This generation will estimate a cost of {cost} words.
                </Typography>
              </Section>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                columnGap: "12px",
              }}
            >
              <OutlineGreyButton
                onClick={() => {
                  onClose();
                }}
              >
                CANCEL
              </OutlineGreyButton>
              <PurplePinkButton
                disabled={!activeContent || selectedLanguages.length === 0}
                loading={loading.isLoading}
                onClick={() => {
                  onRepurpose();
                  trackClickOnRewriteLanguage();
                }}
              >
                CONFIRM
              </PurplePinkButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RepurposeModal;
